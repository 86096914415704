import { toEthiopian } from "./dateConvertor";
import dateFormatter from "./dateFormatter";

const byOrderNumber = (ordersList, searchText) => {
  if (!ordersList || ordersList?.length === 0 || !searchText) return [];
  return ordersList.filter(
    (suggestion) =>
      suggestion.requestNo
        .toString()
        .toLowerCase()
        .indexOf(searchText.toLowerCase()) > -1
  );
};

const byDate = (ordersList, searchText, language) => {
  if (!ordersList || ordersList?.length === 0 || !searchText) return [];
  return ordersList.filter((suggestion) => {
    const tobeCompared =
      language === "am"
        ? toEthiopian(suggestion.createdDate)
        : dateFormatter.yearMonthDate(suggestion.createdDate);
    return (
      tobeCompared.toString().toLowerCase().indexOf(searchText.toLowerCase()) >
      -1
    );
  });
};

export default { byOrderNumber, byDate };

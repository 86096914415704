/* eslint-disable import/no-duplicates */
import styled from "styled-components";
import drakModeAndLanguagePNG from "resources/landingPage/drakModeAndLanguage.png";
import drakModeAndLanguageAVIF from "resources/landingPage/drakModeAndLanguage.png";
import { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";

export default function DarkModeAndLanguage() {
  const elementRef = useRef(null);
  const [showElement, setShowElement] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setShowElement(true);
        }
      },
      {
        root: null,
        rootMargin: "20%",
        threshold: 0.4,
      }
    );
    if (elementRef.current) {
      observer.observe(elementRef.current);
    }
    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <Wrapper ref={elementRef}>
      {showElement && (
        <>
          <motion.picture
            initial={{ opacity: 0, x: -100 }}
            animate={{ opacity: 1, x: 0, delay: 0.5 }}
            transition={{
              duration: 1.2,
              type: "tween",
              delay: 0.5,
            }}
          >
            <source
              type="image/avif"
              srcSet={drakModeAndLanguageAVIF}
              className="drakModeAndLanguage-image"
            />
            <img
              loading="lazy"
              alt="Devices"
              src={drakModeAndLanguagePNG}
              className="drakModeAndLanguage-image"
            />
          </motion.picture>
          <div className="content">
            <motion.h1
              initial={{ opacity: 0, scale: 0.7, y: -70 }}
              animate={{ opacity: 1, scale: 1, y: 0 }}
              transition={{ duration: 1 }}
            >
              Multiple Languages, Dark Mode, Offline Mode
            </motion.h1>
            <motion.div
              className="separator"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1, delay: 0.5 }}
            />
            <motion.p
              initial={{ opacity: 0, scale: 0.7, y: 70 }}
              animate={{ opacity: 1, scale: 1, y: 0 }}
              transition={{ duration: 1, delay: 0.5 }}
            >
              Use our multiple language to use the system in different
              languages, Install PWA and use it offline.
            </motion.p>
          </div>
        </>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 700px;
  color: #702459;
  .drakModeAndLanguage-image {
    height: 600px;
  }
  h1 {
    font-size: 40px;
    font-family: "Fredoka One", cursive;
    max-width: 900px;
    letter-spacing: 1px;
    text-align: center;
    margin: 0px;

    -webkit-text-fill-color: transparent;
    -webkit-box-decoration-break: clone;
    background: linear-gradient(-70deg, #6d1d55, #d68080);
    -webkit-background-clip: text;
  }
  .separator {
    width: 100px;
    height: 8px;
    background-color: #702459;
    margin: 10px 0px 15px 0px;
    border-radius: 5px;
  }
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 500px;
  }
  p {
    font-size: 22px;
    text-align: center;
    margin: 0px;
    letter-spacing: 1px;
  }
`;

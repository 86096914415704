import { appActions } from "app.state";
import OrderDate from "components/OrderDate";
import { AnimateSharedLayout, motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { ShimmerEffect } from "SharedComponents/ShimmerEffect";
import styled from "styled-components";

export default function RequestsList({ requests }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <Wrapper>
      <AnimateSharedLayout type="crossfade">
        {requests.map((request) => (
          <motion.li
            key={request.id}
            initial="hidden"
            animate="visible"
            variants={{ hidden: { opacity: 0 }, visible: { opacity: 1 } }}
            transition={{ duration: 0.5, delay: 0 }}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.8 }}
            onClick={() => {
              dispatch(
                appActions.showRequestDetailModal({
                  selectedRequest: request.id,
                  selectedRequestData: request,
                })
              );
            }}
            className="card-list-item"
          >
            <OrderDate unformattedDate={request.modifiedDate} />
            <section>
              <p className="list-label">{t("Order Number")}</p>{" "}
              <p className="list-content">{request.requestNo}</p>
            </section>
            <section>
              <p className="list-label">{t("Items")}</p>{" "}
              <p className="list-content">{request.numberOfRequestedItems}</p>
            </section>
            <section>
              <p className="list-label">{t("Mode")}</p>{" "}
              <p className="list-content">{request.mode}</p>
            </section>
            <section>
              <p className="list-label">{t("Payment Type")}</p>{" "}
              <p className="list-content">{request.paymentType}</p>
            </section>
          </motion.li>
        ))}
      </AnimateSharedLayout>
    </Wrapper>
  );
}

export const RequestListLoading = () => {
  const listItem = (
    <motion.li
      initial="hidden"
      animate="visible"
      variants={{
        hidden: { opacity: 0, scale: 0.7 },
        visible: { opacity: 1, scale: 1 },
      }}
      transition={{ duration: 0.5 }}
      className="card-list-item"
    >
      <section>
        <ShimmerEffect width="100%" height="18px" borderRadius="5px" />
      </section>
      <section>
        <ShimmerEffect width="80%" height="16px" borderRadius="5px" />
        <ShimmerEffect
          width="35%"
          height="14px"
          borderRadius="5px"
          marginTop="5px"
        />
      </section>
      <section>
        <ShimmerEffect width="80%" height="16px" borderRadius="5px" />
        <ShimmerEffect
          width="35%"
          height="14px"
          borderRadius="5px"
          marginTop="5px"
        />
      </section>
      <section>
        <ShimmerEffect width="80%" height="16px" borderRadius="5px" />
        <ShimmerEffect
          width="35%"
          height="14px"
          borderRadius="5px"
          marginTop="5px"
        />
      </section>
      <section>
        <ShimmerEffect width="80%" height="16px" borderRadius="5px" />
        <ShimmerEffect
          width="35%"
          height="14px"
          borderRadius="5px"
          marginTop="5px"
        />
      </section>
    </motion.li>
  );
  return (
    <Wrapper>
      {listItem}
      {listItem}
      {listItem}
      {listItem}
      {listItem}
      {listItem}
      {listItem}
      {listItem}
      {listItem}
      {listItem}
      {listItem}
      {listItem}
      {listItem}
      {listItem}
      {listItem}
    </Wrapper>
  );
};

const Wrapper = styled.ul`
  width: 100%;
  padding: 0;
  margin: 0 auto;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .pop-up {
    position: fixed;
    width: 50%;
    height: 400px;
    background: #ffffff;
    z-index: 9999;
    margin-top: 10%;
  }
  .card-list-item {
    margin: 20px;
    background-image: ${({ theme }) => theme.RequestsList.card.backgroundImage};
    border: ${({ theme }) => theme.RequestsList.card.border};
    box-shadow: ${({ theme }) => theme.RequestsList.card.boxShadow};
    width: 223px;
    border-radius: 4px;
    color: ${({ theme }) => theme.COLORS.primary};
    padding: 10px 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    section {
      width: 100%;
      margin: 7px 0px;
    }
    .date {
      font-family: "Fredoka One", cursive;
      font-size: 20px;
      letter-spacing: 1px;
      color: ${({ theme }) => theme.COLORS.primary100};
    }
    p {
      margin: 0;
    }
    .list-label {
      font-size: 14px;
      color: ${({ theme }) => theme.COLORS.primary10096};
    }
    .list-content {
      letter-spacing: 1px;
      font-size: 16px;
      font-weight: bold;
    }
    &:hover {
      box-shadow: ${({ theme }) => theme.RequestsList.card.hover.boxShadow};
      border: ${({ theme }) => theme.RequestsList.card.hover.border};
    }
  }

  @media screen and (max-width: 524px) {
    .card-list-item {
      width: 94%;
      margin: 10px 0px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      height: auto;
      .date {
        width: 100%;
      }
      section {
        width: 150px;
      }
    }
  }
`;

import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import styled from "styled-components";
import Logo from "resources/logo.png";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import getDecodedToken from "utils/getDecodedToken";
import DisplaySettings from "components/DisplaySettings";
import { useDispatch } from "react-redux";
import { appActions } from "app.state";
import LanguageSettings from "components/LanguageSettings";
import { useTranslation } from "react-i18next";
import Icons from "components/Icons";
import RouteTitle from "../RouteTitle";

export default function AdminTopNavMobile() {
  const [showNavigationDrawer, setShowNavigationDrawer] = useState(false);
  const [showThemeSelection, setShowThemeSelection] = useState(false);
  const [showLanguageSelection, setShowLanguageSelection] = useState(false);

  return (
    <Wrapper>
      <Icons.Menu
        onClick={() => {
          setShowNavigationDrawer(true);
        }}
      />
      <RouteTitle />
      <NavigationDrawer
        showNavigationDrawer={showNavigationDrawer}
        setShowNavigationDrawer={setShowNavigationDrawer}
        setShowThemeSelection={setShowThemeSelection}
        setShowLanguageSelection={setShowLanguageSelection}
      />
      <ThemeSelectionMobile
        showThemeSelection={showThemeSelection}
        setShowThemeSelection={setShowThemeSelection}
      />
      <LanguageSelectionMobile
        showLanguageSelection={showLanguageSelection}
        setShowLanguageSelection={setShowLanguageSelection}
      />
    </Wrapper>
  );
}

const NavigationDrawer = ({
  showNavigationDrawer,
  setShowNavigationDrawer,
  setShowThemeSelection,
  setShowLanguageSelection
}) => {
  const [userInfo, setUserInfo] = useState(null);
  useEffect(() => {
    setUserInfo(getDecodedToken());
  }, []);
  return (
    <>
      {showNavigationDrawer && (
        <motion.div
          className="drawer-wrapper"
          initial="hidden"
          animate="visible"
          variants={{
            hidden: { opacity: 0 },
            visible: { opacity: 1 },
          }}
          transition={{ ease: "easeOut", duration: 0.5 }}
          onClick={() => setShowNavigationDrawer(false)}
        />
      )}
      <AnimatePresence>
        {showNavigationDrawer && (
          <motion.div
            className="drawer"
            key="drawer"
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={{
              hidden: { x: -350 },
              visible: { x: 0, opacity: 1 },
              exit: { x: -350, opacity: 1 },
            }}
            transition={{ ease: "easeOut", duration: 0.5 }}
          >
            <Icons.Close
              className="close-icon"
              onClick={() => setShowNavigationDrawer(false)}
            />
            <TopSection setShowNavigationDrawer={setShowNavigationDrawer} />
            <p className="user-name">{userInfo?.FullName || ""}</p>
            <DrawerList
              setShowNavigationDrawer={setShowNavigationDrawer}
              setShowThemeSelection={setShowThemeSelection}
              setShowLanguageSelection={setShowLanguageSelection}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

const TopSection = ({ setShowNavigationDrawer }) => {
  const { t } = useTranslation();
  return (
    <div className="top-section">
      <section className="logo-and-title">
        <NavLink
          to="/"
          onClick={() => {
            setShowNavigationDrawer(false);
          }}
        >
          <div className="circle-container">
            <img src={Logo} alt="Logo" />
          </div>
        </NavLink>
        <NavLink
          to="/"
          onClick={() => {
            setShowNavigationDrawer(false);
          }}
        >
          <p>{t("EPSS")}</p>
        </NavLink>
      </section>
      <p className="top-section-content">
        {t("Ethiopian Pharmaceutical Supply Service")}
      </p>
    </div>
  );
};

const DrawerList = ({
  setShowNavigationDrawer,
  setShowThemeSelection,
  setShowLanguageSelection
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const handleLogOut = () => {
    // remove username and token from redux store
    dispatch(appActions.removeToken());
    // remove user data from storage
    localStorage.removeItem("userInfo");
    // remove user role from storage
    localStorage.removeItem("user_role");
    // redirect user to login
    history.push("/admin");
  };

  return (
    <ul
      className="drawer-list"
      onClick={() => {
        setShowNavigationDrawer(false);
      }}
    >
      <NavLink to="/status-report"
              isActive={() => ['/status-report', '/'].includes(pathname)}>
          <div className="icon-and-text">
            <Icons.Plus />
            <p>{t("Status Report")}</p>
          </div>
        </NavLink>
        <NavLink to="/facility-report">
          <div className="icon-and-text">
            <Icons.Drafted />
            <p>{t("Facility Report")}</p>
          </div>
        </NavLink>
        {/* <NavLink to="/user-management">
          <div className="icon-and-text">
            <Icons.Drafted />
            <p>{t("User Management")}</p>
          </div>
        </NavLink> */}

      <hr />

      <button
        type="button"
        onClick={() => {
          setShowThemeSelection(true);
        }}
      >
        <div className="icon-and-text">
          <Icons.SunAndMoon />
          <p>{t("Display Settings")}</p>
        </div>
      </button>
      <button
        type="button"
        onClick={() => {
          setShowLanguageSelection(true);
        }}
      >
        <div className="icon-and-text">
          <Icons.Language />
          <p>Language / ቋንቋ</p>
        </div>
      </button>
      <button type="button">
        <div className="icon-and-text">
          <Icons.About />
          <p>{t("About")}</p>
        </div>
      </button>
      <button
        type="button"
        onClick={() => {
          handleLogOut();
        }}
      >
        <div className="icon-and-text">
          <Icons.SignOut />
          <p>{t("Sign Out")}</p>
        </div>
      </button>
    </ul>
  );
};

const ThemeSelectionMobile = ({
  showThemeSelection,
  setShowThemeSelection,
}) => {
  return (
    <>
      <AnimatePresence>
        {showThemeSelection && (
          <motion.div
            className="drawer-wrapper"
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={{
              hidden: { opacity: 0 },
              visible: { opacity: 1 },
              exit: { opacity: 0 },
            }}
            transition={{ ease: "easeOut", duration: 0.5, delay: 0.5 }}
            onClick={() => setShowThemeSelection(false)}
          />
        )}
      </AnimatePresence>
      <AnimatePresence>
        {showThemeSelection && (
          <motion.div
            className="theme-selection"
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={{
              hidden: { opacity: 0, y: 200 },
              visible: { opacity: 1, y: 0 },
              exit: { opacity: 0, y: 200 },
            }}
            transition={{
              duration: 0.5,
              delay: 0.5,
            }}
          >
            <h4 className="title">Select Theme</h4>
            <Icons.Close
              className="theme-selection-close-icon"
              onClick={() => setShowThemeSelection(false)}
            />
            <DisplaySettings />
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

const LanguageSelectionMobile = ({
  showLanguageSelection,
  setShowLanguageSelection,
}) => {
  return (
    <>
      <AnimatePresence>
        {showLanguageSelection && (
          <motion.div
            className="drawer-wrapper"
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={{
              hidden: { opacity: 0 },
              visible: { opacity: 1 },
              exit: { opacity: 0 },
            }}
            transition={{ ease: "easeOut", duration: 0.5, delay: 0.5 }}
            onClick={() => setShowLanguageSelection(false)}
          />
        )}
      </AnimatePresence>
      <AnimatePresence>
        {showLanguageSelection && (
          <motion.div
            className="theme-selection"
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={{
              hidden: { opacity: 0, y: 200 },
              visible: { opacity: 1, y: 0 },
              exit: { opacity: 0, y: 200 },
            }}
            transition={{ duration: 0.5, delay: 0.5 }}
          >
            <h4 className="title">Select Language</h4>
            <Icons.Close
              className="theme-selection-close-icon"
              onClick={() => setShowLanguageSelection(false)}
            />
            <LanguageSettings />
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

const Wrapper = styled.div`
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 54px;
  width: 100%;
  background-image: ${({ theme }) => theme.TopNav.backgroundImage};
  box-shadow: ${({ theme }) => theme.TopNav.boxShadow};
  border: ${({ theme }) => theme.TopNav.border};
  padding: 0px 15px;
  box-sizing: border-box;
  color: ${({ theme }) => theme.COLORS.primary100};

  .notification-and-username {
    display: flex;
  }
  svg {
    height: 24px;
    cursor: pointer;
  }
  .humburder-menu {
    fill: ${({ theme }) => theme.COLORS.primary100};
  }
  .drawer-wrapper {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    background-color: ${({ theme }) => theme.modal.background};
    z-index: 9999;
  }
  .drawer {
    position: fixed;
    width: 80%;
    max-width: 350px;
    height: 100%;
    z-index: 99999;
    background-color: ${({ theme }) => theme.drawer.backgroundColor};
    backdrop-filter: blur(15px);
    bottom: 0;
    left: 0;
    box-shadow: ${({ theme }) => theme.drawer.boxShadow};
    overflow-y: auto;
    .close-icon {
      height: 31px;
      fill: ${({ theme }) => theme.COLORS.primary10040};
      position: absolute;
      top: 5px;
      right: 10px;
    }
    .top-section {
      color: ${({ theme }) => theme.COLORS.primary};
      /* background-image: linear-gradient(to top right, #5a1c47, #70243b); */
      padding: 30px 20px;
      border-bottom: 1px dashed ${({ theme }) => theme.COLORS.primary10020};
    }
    .logo-and-title {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      a {
        text-decoration: none;
        color: ${({ theme }) => theme.COLORS.primary};
      }
      p {
        margin: 0px;
        padding: 0px;
        margin-left: 15px;
        font-size: 36px;
        font-family: "Fredoka One", cursive;
      }
    }

    .circle-container {
      width: 80px !important;
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 9999%;
      background: #ffffff;
      border: 2px solid #ffffff9c;
      box-shadow: 0px 2px 4px #7a276133;
    }

    img {
      width: 48px;
      height: 45px;
    }
  }

  hr {
    margin-top: 10px;
    border: none;
    border-top: 1px dashed ${({ theme }) => theme.COLORS.primary10020};
    width: 100%;
    height: 1px;
    stroke-width: 1;
  }

  .drawer-list {
    font-size: 14px;
    list-style-type: none;
    padding: 0;
    padding-top: 10px;
    margin: 0;
    display: flex;
    flex-direction: column;
    position: relative;
    button {
      background: transparent;
      border: none;
      box-shadow: none;
      outline: none;
    }
    a,
    button {
      height: 48px;
      width: 210px;
      color: ${({ theme }) => theme.COLORS.primary100};
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0px 12px 0px 12px;
      margin: 0px 15px 0px 15px;
      border-radius: 8px;
      transition: 0.3s all ease;
      z-index: 10;
      box-sizing: border-box;

      p {
        padding: none;
        margin: none;
        margin-left: 10px;
        font-size: 16px;
      }
      svg {
        width: 24px;
        fill: ${({ theme }) => theme.COLORS.primary10040};
      }
      &:hover {
        background-color: #70245920;
      }
      .icon-and-text {
        display: flex;
        align-items: center;
      }
      .count {
        margin-left: 10px;
        color: ${({ theme }) => theme.COLORS.primary10060};
        text-align: right;
        float: right;
      }

      &.active {
        background-color: ${({ theme }) => theme.COLORS.primary10020};
        .count {
          color: ${({ theme }) => theme.COLORS.primary100};
        }
        svg {
          fill: ${({ theme }) => theme.COLORS.primary100};
        }
      }
    }
  }

  .theme-selection {
    width: 100% !important;
    position: fixed;
    bottom: 0;
    left: 0;
    box-shadow: ${({ theme }) => theme.modal.itemDetailMobileBoxShadow};
    z-index: 9999;
    padding: 20px;
    box-sizing: border-box;
    height: auto;
    background-color: ${({ theme }) => theme.drawer.backgroundColor} !important;
    backdrop-filter: blur(15px);
    .title {
      font-family: "Fredoka One", cursive;
      font-size: 20px;
      letter-spacing: 1px;
      color: ${({ theme }) => theme.COLORS.primary200};
      margin: 0px;
    }
    .theme-selection-close-icon {
      position: absolute;
      top: 20px;
      right: 20px;
      fill: ${({ theme }) => theme.COLORS.primary200};
      stroke: ${({ theme }) => theme.COLORS.primary200};
      cursor: pointer;
      transition: 0.5s all ease;
      height: 30px;
      &:hover {
        fill: ${({ theme }) => theme.COLORS.primary100};
      }
    }
  }

  .user-name {
    padding: 0px 12px;
    font-family: "Fredoka One", cursive;
    font-size: 20px;
    margin: 10px 2px 0px 0px;
    letter-spacing: 1px;
  }

  @media screen and (max-height: 670px) {
    .drawer {
      .top-section {
        padding: 15px;
        .top-section-content {
          padding: 0;
          margin: 5px 0px 0px 0px;
        }
      }
    }
  }
`;

/* eslint-disable jsx-a11y/control-has-associated-label */
import { motion } from "framer-motion";
import styled from "styled-components";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Input from "SharedComponents/Input";
import { useTranslation } from "react-i18next";
import Icons from "components/Icons";
import { useMutation } from "react-query";
import requests from "utils/requests";
import { useDispatch } from "react-redux";
import { appActions } from "app.state";
import { useEffect } from "react";
import useMedia from "use-media";

export default function ResetPassword({ setShowResetPasswordModal }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isLargeScreen = useMedia({ minWidth: "950px" });
  let variants = {
    hidden: { opacity: 0, y: 200 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: 200 },
  };

  let transition = {
    duration: 0.5,
    delay: 0.5,
  };

  if (isLargeScreen) {
    variants = {
      hidden: { opacity: 0, y: -50 },
      visible: { opacity: 1, y: 0 },
      exit: { opacity: 0, y: 200 },
    };
    transition = {
      duration: 0.7,
    };
  }

  const [resetPasswordMutation, resetPasswordRequestStatus] = useMutation(
    requests.changePassword,
    {
      onSuccess: () => {
        setShowResetPasswordModal(false);
        dispatch(
          appActions.showNotification({
            type: "SUCCESS",
            message: "Password Reseted",
          })
        );
      },
    }
  );

  useEffect(() => {
    if (resetPasswordRequestStatus.isError) {
      const errorMessage =
        resetPasswordRequestStatus?.error?.response?.data?.errors[0] ||
        "Reset Password Error";
      dispatch(
        appActions.showNotification({
          type: "ERROR",
          message: errorMessage,
        })
      );
    }
  }, [resetPasswordRequestStatus.isError]);

  const onResetPasswordSubmit = async ({ oldPassword, newPassword }) => {
    await resetPasswordMutation({
      currentPassword: oldPassword,
      newPassword,
    });
  };

  return (
    <Wrapper>
      <div
        className="modal-background"
        role="button"
        tabIndex={0}
        onClick={() => {
          setShowResetPasswordModal(false);
        }}
      />
      <motion.div
        className="pop-up"
        initial="hidden"
        animate="visible"
        variants={variants}
        transition={transition}
      >
        <Icons.Close
          id="close-icon"
          onClick={() => {
            setShowResetPasswordModal(false);
          }}
        />
        <h1 className="title horizontal-spacing">{t("Reset Password")}</h1>
        <Formik
          initialValues={{
            oldPassword: "",
            newPassword: "",
            confirmPassword: "confirmPassword",
          }}
          validationSchema={Yup.object({
            oldPassword: Yup.string().required("Required"),
            newPassword: Yup.string()
              .required("Required")
              .min(8, "Password should be 8 chars minimum."),
            confirmPassword: Yup.string()
              .required("Required")
              .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
          })}
          onSubmit={(values) => {
            onResetPasswordSubmit(values);
          }}
        >
          <Form>
            <div className="from-element-width spacing">
              <Input
                label={t("Old Password")}
                name="oldPassword"
                type="password"
                autoComplete="off"
              />
            </div>

            <div className="from-element-width spacing">
              <Input
                label={t("New Password")}
                name="newPassword"
                type="password"
                autoComplete="off"
              />
            </div>

            <div className="from-element-width spacing">
              <Input
                label={t("Confirm Password")}
                name="confirmPassword"
                type="password"
                autoComplete="off"
              />
            </div>
            <div className="spacing full-width horizontal-spacing">
              <div>
                <button type="submit" className="blue-button">
                  {resetPasswordRequestStatus.isLoading
                    ? t("Just a moment...")
                    : t("Reset")}
                </button>
              </div>
            </div>
          </Form>
        </Formik>
      </motion.div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: fixed;
  background: transparent;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 9999;
  top: 0;
  left: 0;
  color: ${({ theme }) => theme.COLORS.primary};

  .modal-background {
    position: fixed;
    background: ${({ theme }) => theme.modal.background};
    width: 100%;
    height: 100%;
    display: flex;
    outline: none;
  }

  .pop-up {
    width: 100%;
    max-width: 500px;
    height: auto;
    overflow-y: auto;
    z-index: 9999;
    padding: 40px 20px;
    margin-top: 10% !important;
    background-color: ${({ theme }) => theme.drawer.backgroundColor} !important;
    backdrop-filter: blur(15px);
    border: ${({ theme }) => theme.modal.border};
    box-shadow: ${({ theme }) => theme.modal.boxShadow};
    border-radius: 4px;
    box-sizing: border-box;
  }

  .spacing {
    margin: 10px 0px;
  }

  .horizontal-spacing {
    margin: 0px 20px;
  }

  form {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .from-element-width {
    width: 100%;
  }

  #close-icon {
    height: 20px !important;
    position: absolute;
    right: 10px;
    top: 10px;
    fill: ${({ theme }) => theme.COLORS.primary200};
    stroke: ${({ theme }) => theme.COLORS.primary200};
    cursor: pointer;
    transition: 0.5s all ease;
    &:hover {
      fill: ${({ theme }) => theme.COLORS.primary100};
    }
  }

  .title {
    font-size: 22px;
  }
  @media screen and (max-width: 950px) {
    .pop-up {
      width: 100% !important;
      max-width: 100%;
      position: fixed;
      bottom: 0;
      left: 0;
      box-shadow: ${({ theme }) => theme.modal.itemDetailMobileBoxShadow};
      z-index: 9999;
      padding: 20px;
      box-sizing: border-box;
      height: auto;
    }
  }
`;

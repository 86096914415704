import styled from "styled-components";
import Logo from "resources/logo.png";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectors } from "configureStore";

export default function HeroMessage() {
  const { t } = useTranslation();
  const app = useSelector(selectors.app);

  return (
    <Wrapper language={app.language}>
      <div className="circle-container">
        <img src={Logo} alt="Logo" />
      </div>
      <section>
        <h1>{t("Order Online.")}</h1>
        <p>{t("Ethiopian Pharmaceutical Supply Service")}</p>
      </section>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  color: ${({ theme }) => theme.COLORS.primary100};
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .circle-container {
    width: 140px !important;
    height: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 9999%;
    background-image: ${({ theme }) => theme.LANDING.logoBackgroundImage};
    border: 2px solid #ffffff9c;
    box-shadow: ${({ theme }) => theme.LANDING.boxShadow};
  }
  img {
    width: 85px;
    height: 81px;
  }
  section {
    margin-left: 15px;
  }
  h1 {
    font-size: ${({ language }) => (language === "am" ? 55 / 1.5 : 55)}px;
    font-family: "Fredoka One", cursive;
  }
  p {
    font-size: 20px;
    margin: 0;
    text-align: left !important;
  }
  @media screen and (max-width: 625px) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    flex-wrap: nowrap;
    .circle-container {
      margin-bottom: 0px;
      width: auto !important;
      height: auto !important;
      border-radius: 999999%;
      margin-left: 10px;
    }
    img {
      width: 35px;
      height: 34px;
      padding: 15px;
    }

    h1 {
      font-size: ${({ language }) => (language === "am" ? 35 / 1.5 : 35)}px;
      margin: 0px;
      margin-top: 30px;
    }

    p {
      font-size: 14px;
      margin: 0;
      width: auto;
      text-align: left;
    }
  }
`;

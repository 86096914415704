/* eslint-disable eqeqeq */
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import EmptyState from "components/EmptyState";
import { fetchAllRequests } from "utils/requests";
import filterRequestsByType from "utils/filterRequestsByType";
import { useDispatch } from "react-redux";
import { appActions } from "app.state";
import RequestsList, { RequestListLoading } from "./RequestsList";

export default function OrdersList({ setSelectedOrderType }) {
  const { type, id } = useParams();
  const [filteredRequestsList, setFilteredRequestsList] = useState([]);
  const dispatch = useDispatch();
  const allRequestsReq = useQuery("All requests 123", fetchAllRequests, {
    onSuccess: (data) => {
      if (data.length > 0) {
        setFilteredRequestsList(filterRequestsByType(type, data));

        if (id) {
          const selectedRequestRedirect = data.filter(
            (el) => el.requestNo == id
          )[0];
          if (selectedRequestRedirect) {
            dispatch(
              appActions.showRequestDetailModal({
                selectedRequest: selectedRequestRedirect.id,
                selectedRequestData: selectedRequestRedirect,
              })
            );
          }
        }
      }
    },
  });

  useEffect(() => {
    setSelectedOrderType(type);
    if (allRequestsReq.data) {
      setFilteredRequestsList(filterRequestsByType(type, allRequestsReq.data));
    }
  }, [type]);

  return (
    <Wrapper>
      {allRequestsReq.isLoading && <RequestListLoading />}
      {allRequestsReq.isError && <div>Error</div>}
      {allRequestsReq.isSuccess && filteredRequestsList?.length === 0 ? (
        <EmptyState message={`You have no ${type} orders`} />
      ) : (
        <RequestsList requests={filteredRequestsList} />
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 100px !important;
`;

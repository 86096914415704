import { appActions } from "app.state";
import Icons from "components/Icons";
import LoadingState from "components/LoadingState";
import { motion } from "framer-motion";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { deleteOrder } from "utils/requests";

export default function EditOrDelete({ selectedRequestData }) {
  const { t } = useTranslation();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const dispatch = useDispatch();

  const [deleteOrderMutation, deleteOrderReq] = useMutation(deleteOrder, {
    onSuccess: () => {
      dispatch(
        appActions.showNotification({
          type: "SUCCESS",
          message: "Order Deleted!",
        })
      );
      dispatch(appActions.hideRequestDetailModal());
    },
    onError: () => {
      dispatch(
        appActions.showNotification({
          type: "ERROR",
          message: "Cound't remove order",
        })
      );
    },
  });

  const onDeleteDraft = async () => {
    await deleteOrderMutation(selectedRequestData.id);
  };

  return (
    <Wrapper>
      {!showDeleteConfirmation && (
        <motion.div
          className="edit-and-delete"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.7 }}
        >
          <NavLink to={`/add-order/${selectedRequestData.id}`}>
            <motion.button
              type="button"
              className="edit-button"
              whileHover={{ scale: 1.03 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => {
                dispatch(appActions.hideRequestDetailModal());
              }}
            >
              <Icons.Edit />
              <span>{t("Edit")}</span>
            </motion.button>
          </NavLink>
          <motion.button
            type="button"
            className="delete-button"
            whileHover={{ scale: 1.03 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => {
              setShowDeleteConfirmation(true);
            }}
          >
            <Icons.Delete />
            <span>{t("Delete")}</span>
          </motion.button>
        </motion.div>
      )}

      {showDeleteConfirmation && !deleteOrderReq.isLoading && (
        <motion.div
          className="red-warning"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.7 }}
        >
          <p className="warning-message">
            Are you sure you want to delete this draft request?
          </p>
          <div className="edit-or-delete">
            <motion.button
              type="button"
              className="edit-button"
              whileHover={{ scale: 1.03 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => {
                setShowDeleteConfirmation(false);
              }}
            >
              <span>{t("Cancel")}</span>
            </motion.button>
            <motion.button
              type="button"
              className="delete-button"
              whileHover={{ scale: 1.03 }}
              whileTap={{ scale: 0.95 }}
              onClick={onDeleteDraft}
            >
              <Icons.Delete />
              <span>{t("Delete")}</span>
            </motion.button>
          </div>
        </motion.div>
      )}

      {deleteOrderReq.isLoading && (
        <motion.div
          className="deleting-message"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.7 }}
        >
          <LoadingState message="Deleting Order" />
        </motion.div>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 10px;
  a {
    text-decoration: none;
  }
  button {
    margin: 10px;
    cursor: pointer;
    transition: 0.5s all ease;
    outline: none;
    border: none;
    padding: 15px 20px;
    font-weight: 600;
    border-radius: 5px;
    font-size: 16px;
    min-width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    svg {
      height: 20px;
      margin-right: 5px;
    }
    &:hover {
      box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.04);
    }
  }
  .edit-button {
    background-color: ${({ theme }) => theme.COLORS.primary10020};
    color: ${({ theme }) => theme.COLORS.primary100};
    svg {
      fill: ${({ theme }) => theme.COLORS.primary100};
    }
  }
  .delete-button {
    background: transparent;
    color: ${({ theme }) => theme.COLORS.red};
    border: 1px solid ${({ theme }) => theme.COLORS.red40};
    svg {
      fill: ${({ theme }) => theme.COLORS.red};
    }
    &:hover {
      background: ${({ theme }) => theme.COLORS.red10};
    }
  }

  @media screen and (max-width: 490px) {
    button {
      min-width: auto;
      padding: 12px 20px;
    }
  }
`;

import { Formik, Form, Field } from "formik";
import { useEffect, useState } from "react";
import styled from "styled-components";
import * as Yup from "yup";
import { useMutation, useQuery } from "react-query";
import { 
  loginRequest,
  fetchHubsList,
  adminLoginRequest, 
} from "utils/requests";
import { useDispatch } from "react-redux";
import { appActions } from "app.state";
import { useHistory, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { http } from "utils/http";
import Icons from "components/Icons";
import notificationButton from "styles/notificationButton";
import Constants from "utils/constants";
import { formElementWrapper } from "./LoginAndSignup.styles";

export default function Login({ signInInfo, userRole }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [hubsList, setHubsList] = useState([]);

  const [loginMutation, loginMutationStatus] = useMutation((userRole===Constants.buyer)?loginRequest:adminLoginRequest, {
    onSuccess: (data) => {
      // save user data returned to local storage
      localStorage.setItem(
        "userInfo",
        JSON.stringify({
          ...data,
        })
      );

      // set user role to local storage
      localStorage.setItem(
        "user_role",
        userRole
      );

      // set the authentication bearer token
      http.setHeader("Authorization", `Bearer ${data?.token}`);

      // set user info to the store state
      dispatch(appActions.setToken(data?.token));

      // redirect the user to home page
      history.push("/");
    },
  });

  useQuery("Hub List", fetchHubsList,
  {
    onSuccess: (data) => {
      setHubsList(data.data);
    }
  });

  /**
   * run this effect(login) when component is rendered
   * when the user navigates to the /login page and if the user is
   * already authenticated redirect the user to login.
   */
  useEffect(() => {
    const authData = localStorage.getItem("userInfo");
    const userInfo = JSON.parse(authData);
    if (userInfo) history.push("/");
  }, []);

  useEffect(() => {
    if (loginMutationStatus.isError) {
      dispatch(
        appActions.showNotification({
          type: "ERROR",
          message:
            loginMutationStatus?.error?.response?.data?.errors?.join(",") ||
            "Login Error",
        })
      );
    }
  }, [loginMutationStatus.isError]);
  const onLoginSubmit = async ({ supplierHubId, userName, password }) => {
      (userRole===Constants.buyer)? 
      await loginMutation({
        supplierHubId: parseInt(supplierHubId, 10),
        userName,
        password,
      }):
      await loginMutation({
        userName,
        password,
      });
    };


  return (
    <Wrapper>
      <Formik
        initialValues={{
          userName: "",
          supplierHubId: "",
        }}
        validationSchema=
        
        {
          userRole===Constants.buyer?
          Yup.object({
          userName: Yup.string().required("Required"),
          supplierHubId: Yup.string().required("Required"),
          password: Yup.string().required("Required"),
        }):
        Yup.object({
          userName: Yup.string().required("Required"),
          password: Yup.string().required("Required"),
        })
      
      }
        onSubmit={(values) => {
          onLoginSubmit(values);
        }}
      >
        {(props) => (
          <Form onSubmit={props.handleSubmit}>
            {(userRole===Constants.buyer) && (<div className="form-element-wrapper spacing">
              <label htmlFor="password">{t("Select Hub")}</label>
              <Field name="supplierHubId" as="select">
                <option value="" className="empty-option">
                  {" "}
                </option>
                {hubsList.map((el) => (
                  <option value={parseInt(el.id, 10)} key={el.id} type="button">
                    {el.name}
                  </option>
                ))}
              </Field>
              {props.errors.supplierHubId && props.touched.supplierHubId && (
                <div className="error">{props.errors.supplierHubId}</div>
              )}
            </div>)}

            <div className="form-element-wrapper spacing">
              <label htmlFor="userName">{t("Username")}</label>
              <input
                type="text"
                onChange={props.handleChange}
                onBlur={(e) => {
                  props.handleBlur(e);
                }}
                name="userName"
                value={props.values.userName}
              />
              {props.errors.userName && props.touched.userName && (
                <div className="error">{props.errors.userName}</div>
              )}
            </div>

            <div className="form-element-wrapper spacing">
              <label htmlFor="password">{t("Password")}</label>
              <input
                name="password"
                onChange={props.handleChange}
                onBlur={(e) => {
                  props.handleBlur(e);
                }}
                type={showPassword ? "text" : "password"}
                autoComplete="off"
                value={props.values.password}
              />
              {signInInfo?.phoneNumber && (
                <span>
                  Use the verification code sent to your number(
                  {signInInfo?.phoneNumber}) as password.
                </span>
              )}
              <button
                type="button"
                className="notification-button"
                onClick={() => {
                  setShowPassword((prev) => !prev);
                }}
                onBlur={() => {}}
              >
                {showPassword ? (
                  <Icons.PasswordHidden />
                ) : (
                  <Icons.PasswordShown />
                )}
              </button>
              {props.errors.password && props.touched.password && (
                <div className="error">{props.errors.password}</div>
                )}
            </div>
            {(userRole===Constants.buyer) && (<NavLink to="/forgot-password" className="forgot-password">{t("Forgot Password?")}</NavLink>)}

            <div className="spacing full-width">
              <div>
                <button type="submit" className="blue-button">
                  {loginMutationStatus.isLoading
                    ? t("Just a moment...")
                    : t("Log in")}
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  height: auto;
  background-image: ${({ theme }) => theme.LANDING.card.backgroundImage};
  box-shadow: ${({ theme }) => theme.LANDING.boxShadow};
  border: 2px solid ${({ theme }) => theme.LANDING.card.border};
  border-radius: 30px;
  padding: 5%;
  box-sizing: border-box;
  .spacing {
    margin: 50px 0px !important;
  }
  .spacing-small {
    margin: -10px 0px !important;
  }
  .full-width {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p {
      text-align: right;
      color: ${({ theme }) => theme.COLORS.primary100};
      margin-bottom: 0;
    }
  }
  .form-element-wrapper {
    ${formElementWrapper}
    .notification-button {
      ${notificationButton};
      position: absolute;
      right: 20px;
    }
  }

  .loading-hubs-container {
    height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    label {
      position: relative;
    }
  }

  .hub-selection {
    padding: 0;
    margin: 0;
    list-style-type: none;
    padding-top: 24px;
  }

  .institution-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      height: 29px;
      margin-right: 10px;
    }
  }

  .forgot-password{
    text-decoration: none;
    color: ${({ theme }) => theme.COLORS.primary100};
    width: 100%;
    text-align: right;
    float: right;
    margin: -30px 10px 50px 10px !important;
    transition: 0.5s all ease;
    &:hover{
      text-decoration: underline;
    }
  }

  @media screen and (max-width: 540px) {
    padding: 0px;
    border-radius: 8px;
    .spacing {
      margin: 35px 0px !important;
    }
    .form-element-wrapper {
      label {
        font-size: 14px;
      }
    }
  }
`;

// const HubSelectionListItem = styled.button`
//   border: 1px solid ${({theme}) => theme.COLORS.primary10029};
//   margin: 7px 0px;
//   padding: 10px 10px;
//   text-align: center;
//   border-radius: 5px;
//   transition: 0.3s all ease;
//   cursor: pointer;
//   width: 100%;
//   color: ${({theme}) => theme.COLORS.primary100};
//   background: ${({ isSelected }) => (isSelected ? "${({theme}) => theme.COLORS.primary10029}" : "transparent")};
//   &:hover {
//     background: #70245910;
//   }
//   &:focus {
//     background: ${({theme}) => theme.COLORS.primary10029};
//     outline: none;
//     border: 1px solid ${({theme}) => theme.COLORS.primary100};
//   }
// `;

import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { fetchRequestCountByStatus } from "utils/requests";
import { motion } from "framer-motion";
import styled from "styled-components";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import Icons from "components/Icons";

export default function SideNav({ selectedOrderType }) {
  const [tabLineVerticalPosition, setTabLineVerticalPosition] = useState(null);
  const { t } = useTranslation();
  const countReq = useQuery("Count", fetchRequestCountByStatus);

  useEffect(() => {
    const gap = 48;
    switch (selectedOrderType) {
      case "add-order":
        setTabLineVerticalPosition(0);
        break;
      case "draft":
        setTabLineVerticalPosition(gap + 10);
        break;
      case "pending":
        setTabLineVerticalPosition(gap * 2 + 10);
        break;
      case "submitted":
        setTabLineVerticalPosition(gap * 3 + 10);
        break;
      case "approved":
        setTabLineVerticalPosition(gap * 4 + 10);
        break;
      case "picklisted":
        setTabLineVerticalPosition(gap * 5 + 10);
        break;
      case "rejected":
        setTabLineVerticalPosition(gap * 6 + 10);
        break;
      case "stockedouts":
        setTabLineVerticalPosition(gap * 7 + 10);
        break;
      default:
        setTabLineVerticalPosition(null);
        break;
    }
  }, [selectedOrderType]);
  return (
    <Wrapper>
      <ul>
        <TabLine
          tabLineVerticalPosition={tabLineVerticalPosition}
          animate={{ y: tabLineVerticalPosition }}
          transition={{ duration: 0.5, ease: "easeOut" }}
        />

        <NavLink to="/add-order" className="add-button">
          <div className="icon-and-text">
            <Icons.Plus />
            <p>{t("New Order")}</p>
          </div>
        </NavLink>
        <NavLink to="/orders/draft">
          <div className="icon-and-text">
            <Icons.Drafted />
            <p>{t("Draft")}</p>
          </div>
          <span className="count">{countReq?.data?.draft}</span>
        </NavLink>
        <NavLink to="/orders/pending">
          <div className="icon-and-text">
            <Icons.Pending />
            <p>{t("Pending")}</p>
          </div>
          <span className="count">{countReq?.data?.pending}</span>
        </NavLink>
        <NavLink to="/orders/submitted">
          <div className="icon-and-text">
            <Icons.Submitted />
            <p>{t("Submitted")}</p>
          </div>
          <span className="count">{countReq?.data?.submitted}</span>
        </NavLink>
        <NavLink to="/orders/approved">
          <div className="icon-and-text">
            <Icons.Approved />
            <p>{t("Approved")}</p>
          </div>
          <span className="count">{countReq?.data?.approved}</span>
        </NavLink>
        <NavLink to="/orders/picklisted">
          <div className="icon-and-text">
            <Icons.Picklist />
            <p>{t("Picklisted")}</p>
          </div>
          <span className="count">{countReq?.data?.pickListed}</span>
        </NavLink>
        <NavLink to="/orders/rejected">
          <div className="icon-and-text">
            <Icons.Rejected />
            <p>{t("Rejected")}</p>
          </div>
          <span className="count">{countReq?.data?.rejected}</span>
        </NavLink>
        <NavLink to="/stockedouts">
          <div className="icon-and-text">
            <Icons.Stockout />
            <p>{t("Stockout")}</p>
          </div>
        </NavLink>
      </ul>
    </Wrapper>
  );
}

const TabLine = styled(motion.div)`
  z-index: 1;
  height: 48px;
  width: 190px;
  border-radius: ${({ tabLineVerticalPosition }) =>
    tabLineVerticalPosition === 0 ? "24px" : "8px"};
  background-color: ${({ tabLineVerticalPosition, theme }) =>
    tabLineVerticalPosition !== null ? theme.COLORS.primary100 : null};
  position: absolute;
  top: 50px;
  left: 15px;
`;

const Wrapper = styled.div`
  z-index: 5;
  width: auto;
  height: 100vh;
  background-image: ${({ theme }) => theme.SideNav.backgroundImage};
  box-shadow: ${({ theme }) => theme.SideNav.boxShadow};
  border-right: ${({ theme }) => theme.SideNav.borderRight};
  ul {
    font-size: 14px;
    list-style-type: none;
    padding: 0;
    padding-top: 50px;
    margin: 0;
    display: flex;
    flex-direction: column;
    position: relative;
    .add-button {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 24px;
      border: 2px solid ${({ theme }) => theme.COLORS.primary100};
      box-shadow: 0px 0px 0px 3px ${({ theme }) => theme.COLORS.primary10033};
      margin-bottom: 10px;
      font-family: "Fredoka One", cursive;
      color: ${({ theme }) => theme.COLORS.grey300};
      svg {
        fill: ${({ theme }) => theme.COLORS.grey300};
      }
      &.active {
        border: none;
        box-shadow: none;
      }
    }
    a {
      height: 48px;
      width: 190px;
      color: ${({ theme }) => theme.COLORS.grey300};
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0px 12px 0px 12px;
      margin: 0px 15px 0px 15px;
      border-radius: 8px;
      transition: 0.3s all ease;
      z-index: 10;
      box-sizing: border-box;
      p {
        padding: none;
        margin: none;
        margin-left: 10px;
        font-size: 16px;
      }
      svg {
        width: 24px;
        fill: #9799a3;
      }
      &:hover {
        background-color: ${({ theme }) => theme.COLORS.primary10020};
      }
      .icon-and-text {
        display: flex;
        align-items: center;
      }
      .count {
        margin-left: 10px;
        color: ${({ theme }) => theme.COLORS.grey10060};
        text-align: right;
        float: right;
      }
      &.active {
        color: ${({ theme }) => theme.COLORS.white};
        .count {
          color: ${({ theme }) => theme.COLORS.white};
        }
        svg {
          fill: ${({ theme }) => theme.COLORS.white};
        }
      }
    }
  }
`;

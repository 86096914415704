/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
import { useField } from "formik";
import styled from "styled-components";

export default function Input({ label, noLabels, ...props }) {
  const [field, meta] = useField(props);
  return (
    <Wrapper>
      {!noLabels && <label htmlFor={props.id || props.name}>{label}</label>}
      <input className="text-input" {...field} {...props} />
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.COLORS.primary100};
  padding: 0px 20px;
  font-size: 16px;
  font-weight: 400;
  margin: 10px 0px;
  position: relative;
  label {
    font-size: 18px;
  }
  input {
    border: none;
    border-bottom: 1px solid ${({ theme }) => theme.COLORS.primary10029};
    outline: none;
    color: ${(p) => p.theme.COLORS.black};
    transition: 0.5s all ease;
    background-color: transparent;
    &:focus {
      border-bottom: 1px solid #d53f8c;
    }
  }
  .error {
    margin-top: 7px;
    font-size: 14px;
    color: #d53f8c;
    position: absolute;
    bottom: -20px;
  }
  @media screen and (max-width: 540px) {
    label {
      font-size: 14px;
    }
  }
`;

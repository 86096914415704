import { useEffect, useState } from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import Icons from "components/Icons";

export default function BottomNav({ selectedOrderType }) {
  const [tabLineHorizontalPosition, setTabLineHorizontalPosition] = useState(
    null
  );
  const [tabLineVerticalPosition, setTabLineVerticalPosition] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    const gap = 75;
    switch (selectedOrderType) {
      case "draft":
        setTabLineHorizontalPosition(0);
        setTabLineVerticalPosition(0);
        break;
      case "submitted":
        setTabLineHorizontalPosition(75);
        setTabLineVerticalPosition(0);
        break;
      case "approved":
        setTabLineHorizontalPosition(gap * 2 + 32);
        setTabLineVerticalPosition(0);
        break;
      case "picklisted":
        setTabLineHorizontalPosition(gap * 3 + 32);
        setTabLineVerticalPosition(0);
        break;
      case "add-order":
        setTabLineHorizontalPosition(gap * 2 - 12);
        setTabLineVerticalPosition(-36);
        break;
      default:
        setTabLineHorizontalPosition(null);
        setTabLineVerticalPosition(0);
        break;
    }
  }, [selectedOrderType]);

  return (
    <Wrapper>
      <ul>
        <TabLine
          tabLineHorizontalPosition={tabLineHorizontalPosition}
          tabLineVerticalPosition={tabLineVerticalPosition}
          animate={{ x: tabLineHorizontalPosition, y: tabLineVerticalPosition }}
          transition={{ duration: 0.5, ease: "easeOut" }}
        />

        <NavLink to="/orders/draft">
          <div className="icon-and-text">
            <Icons.Drafted />
            <p>{t("Draft")}</p>
          </div>
        </NavLink>

        <NavLink to="/orders/submitted" className="right-margin">
          <div className="icon-and-text">
            <Icons.Submitted />
            <p>{t("Submitted")}</p>
          </div>
        </NavLink>

        <NavLink to="/orders/approved" className="left-margin">
          <div className="icon-and-text">
            <Icons.Approved />
            <p>{t("Approved")}</p>
          </div>
        </NavLink>

        <NavLink className="add-button" to="/add-order">
          <Icons.Plus className="icon-plus" />
        </NavLink>

        {/* <NavLink to="/orders/rejected">
          <div className="icon-and-text">
            <Rejected />
            <p>{t("Rejected")}</p>
          </div>
        </NavLink> */}

        <NavLink to="/orders/picklisted">
          <div className="icon-and-text">
            <Icons.Picklist />
            <p>{t("Picklisted")}</p>
          </div>
        </NavLink>
      </ul>
    </Wrapper>
  );
}

const TabLine = styled(motion.div)`
  z-index: 1;
  height: ${({ tabLineVerticalPosition }) =>
    tabLineVerticalPosition ? "59px" : "49px"};
  width: ${({ tabLineVerticalPosition }) =>
    tabLineVerticalPosition ? "59px" : "76px"};
  border-radius: ${({ tabLineVerticalPosition }) =>
    tabLineVerticalPosition ? "9999%" : "8px"};
  background-color: ${({ tabLineHorizontalPosition, theme }) =>
    tabLineHorizontalPosition !== null ? theme.COLORS.primary100 : null};
  position: absolute;
  top: 0px;
  left: 0px;
`;

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ff0000;
  height: 62px;
  background-image: ${({ theme }) => theme.bottomNav.backgroundImage};
  box-shadow: ${({ theme }) => theme.bottomNav.boxShadow};
  border-top: ${({ theme }) => theme.bottomNav.borderTop};
  z-index: 900;
  ul {
    display: flex;
    padding: 0;
    margin: 0;
    position: relative;
    justify-content: center;
    align-items: center;
  }
  a {
    width: 76px;
    margin: 0px;
    height: 49px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    color: ${({ theme }) => theme.COLORS.grey300};
    text-decoration: none;
    transition: 0.3s all ease;
    z-index: 10;

    .icon-plus {
      fill: #ffffff;
    }

    svg {
      height: 20px;
      fill: #9799a3;
    }

    p {
      padding: 0;
      margin: 0;
      font-size: 12px;
    }

    &.active {
      color: ${({ theme }) => theme.COLORS.white};
      .count {
        color: #ffffff;
      }
      svg {
        fill: ${({ theme }) => theme.COLORS.white};
      }
    }
  }

  .icon-and-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 76px;
  }

  .left-margin {
    margin-left: 15px;
  }

  .right-margin {
    margin-right: 15px;
  }

  .add-button {
    background-color: #357fd5;
    border: none;
    position: absolute;
    z-index: 20;
    width: 59px;
    height: 59px;
    border-radius: 9999%;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -38px;
    border: 2px solid #ffffff;
    box-shadow: 0px 3px 6px #357fd560;
    outline: none;
    transition: 1.3s all ease;
    svg {
      height: 22px;
    }
    &.active {
      background-color: ${({ theme }) => theme.COLORS.primary100};
      border: 2px solid ${({ theme }) => theme.COLORS.primary100};
    }
  }
`;

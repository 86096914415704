/* eslint-disable no-unused-vars */
import { appActions } from "app.state";
import LoadingState from "components/LoadingState";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import addRemoveItems from "utils/addRemoveItems";
import getDecodedToken from "utils/getDecodedToken";
import getItemTypes from "utils/getItemTypes";
import populateWithDataFromStorage from "utils/populateWithDataFromStorage";
import requesets, {
  fetchAllItems,
  fetchFullRequestDetails,
  fetchPaymentTypes,
  searchInstitutions,
} from "utils/requests";
import ItemSelection from "./ItemsSelection";
import QuantityForm from "./QuantityForm";

export default function AddOrders({ setSelectedOrderType }) {
  const cardsVariants = {
    hidden: { opacity: 0, scale: 0.97 },
    visible: { opacity: 1, scale: 1 },
  };

  const itemSelectionCardVariants = {
    hidden: { opacity: 0.5, y: 1500 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0.5, y: 1500 },
  };

  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [userInfo, setUserInfo] = useState(null);

  const [itemTypesList, setItemTypesList] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [paymentTypeId, setPaymentTypeId] = useState(1);
  const [paymentTypesFiltered, setPaymentTypesFiltered] = useState([]);
  const [letterNo, setLetterNo] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [itemsListForSelection, setItemsListForSelection] = useState([]);
  const [current, setCurrent] = useState([]);
  const [showItemSelection, setShowItemSelection] = useState(false);
  const itemsReq = useQuery(["items"], fetchAllItems, {
    refetchOnWindowFocus: false,
    cacheTime: 0,
    onSuccess: (data) => {
      setItemsListForSelection(
        populateWithDataFromStorage(data, selectedItems)
      );
      setCurrent(populateWithDataFromStorage(data, selectedItems).slice(0, 10))
    },    
  });
  const paymentTypesReq = useQuery("Payment Types", fetchPaymentTypes,
  {
    onSuccess: (data) => {
      userInfo.FacilityType === "Private" ? setPaymentTypesFiltered(data.splice(data.indexOf("Credit"))) : setPaymentTypesFiltered(data);
      userInfo.FacilityType === "Private" ? setPaymentTypeId(2) : setPaymentTypeId(1);
    }
  });

  const [
    perviousOrderFromLocalStorage,
    setPreviousOrderFromLocalStorage,
  ] = useState(null);
  const [isLargeScreen, setIsLargeScreen] = useState(
    !(document.documentElement.clientWidth < 950)
  );

  const fullItemDetailReqVal = useQuery(
    ["Full Order Detail", id],
    fetchFullRequestDetails,
    {
      onSuccess: (data) => {
        if (id) {
          setPaymentTypeId(data.paymentTypeId);
          setSelectedItems(data.requestDetails);
          setLetterNo(data.letterNo);
        }
      },
      refetchOnWindowFocus: false,
    }
  );

  const evaluateSelectedItems = (newItemState) => {
    setSelectedItems(addRemoveItems(newItemState, selectedItems));
  };

  const resizeHandler = () => {
    const width = document.documentElement.clientWidth;
    if (width < 950) {
      setIsLargeScreen(false);
    } else {
      setIsLargeScreen(true);
    }
  };

  const populateWithDataFromLocalStorage = () => {
    const lastOrder = localStorage.getItem("lastOrder");
    const lastOrderParsed = JSON.parse(lastOrder);
    setSelectedItems(lastOrderParsed.requestDetails);
    setPaymentTypeId(lastOrderParsed.paymentTypeId);
    setLetterNo(lastOrderParsed.letterNo);
    setItemsListForSelection(
      populateWithDataFromStorage(itemsReq.data, lastOrderParsed.requestDetails)
    );
  };

  const clearAllItems = () => {
    setSelectedItems([]);
    setItemsListForSelection(populateWithDataFromStorage(itemsReq.data, []));
  };

  useEffect(() => {
    setUserInfo(getDecodedToken());
    setSelectedOrderType("add-order");
    window.addEventListener("resize", resizeHandler, true);
    return () => {
      window.removeEventListener("resize", resizeHandler, true);
    };
  }, []);

  useEffect(() => {
    const lastOrder = localStorage.getItem("lastOrder");
    const lastOrderParsed = JSON.parse(lastOrder);
    if (lastOrderParsed) {
      setPreviousOrderFromLocalStorage(lastOrderParsed);
    }
  }, []);

  useEffect(() => {
    if (
      itemsReq?.data?.length > 0 &&
      fullItemDetailReqVal?.data?.requestDetails?.length > 0
    ) {
      setItemsListForSelection(
        populateWithDataFromStorage(
          itemsReq.data,
          fullItemDetailReqVal.data.requestDetails,
          true
        )
      );
    }

    if (itemsReq?.data?.length > 0) {
      setItemTypesList(getItemTypes(itemsReq.data));
    }
  }, [itemsReq.data, fullItemDetailReqVal.data]);

  return (
    <Wrapper>
      {(paymentTypesReq.isLoading) && (
        <LoadingState message={t("Loading Items...")} />
      )}

      {paymentTypesReq.data && (
        <div className="left-and-right-container">
          <AnimatePresence>
            {!isLargeScreen && showItemSelection && (
              <motion.div
                initial="hidden"
                animate="visible"
                exit="exit"
                variants={{
                  hidden: { opacity: 0 },
                  visible: { opacity: 1 },
                  exit: { opacity: 0 },
                }}
                transition={{ duration: 1 }}
                className="drawer-background"
                onClick={() => {
                  setShowItemSelection(false);
                }}
              />
            )}
          </AnimatePresence>
          <AnimatePresence>
            {(isLargeScreen || showItemSelection) && (
              <motion.section
                className="left-side"
                initial="hidden"
                animate="visible"
                exit="exit"
                variants={
                  isLargeScreen ? cardsVariants : itemSelectionCardVariants
                }
                transition={{ duration: 0.7 }}
              >
                <ItemSelection
                  paymentTypesReqData={paymentTypesFiltered}
                  itemTypesList={itemTypesList}
                  itemsReq={itemsReq}
                  evaluateSelectedItems={evaluateSelectedItems}
                  paymentTypeId={paymentTypeId}
                  setPaymentTypeId={setPaymentTypeId}
                  formSubmitted={formSubmitted}
                  itemsListForSelection={itemsListForSelection}
                  setItemsListForSelection={setItemsListForSelection}
                  current={current}
                  setCurrent={setCurrent}
                  isLargeScreen={isLargeScreen}
                  setShowItemSelection={setShowItemSelection}
                  selectedItems={selectedItems}
                />
              </motion.section>
            )}
          </AnimatePresence>
          <motion.section
            className="right-side"
            initial="hidden"
            animate="visible"
            variants={cardsVariants}
            transition={{ duration: 0.7, delay: 0.5 }}
          >
            <QuantityForm
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
              paymentTypesReqData={paymentTypesReq.data}
              formSubmitted={formSubmitted}
              setFormSubmitted={setFormSubmitted}
              paymentTypeId={paymentTypeId}
              itemsListForSelection={itemsListForSelection}
              setItemsListForSelection={setItemsListForSelection}
              isLargeScreen={isLargeScreen}
              setShowItemSelection={setShowItemSelection}
              perviousOrderFromLocalStorage={perviousOrderFromLocalStorage}
              populateWithDataFromLocalStorage={
                populateWithDataFromLocalStorage
              }
              fullItemDetailReqData={fullItemDetailReqVal.data}
              clearAllItems={clearAllItems}
              letterNo={letterNo}
              setLetterNo={setLetterNo}
            />
          </motion.section>
        </div>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  .left-and-right-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 93.5vh;
    .left-side,
    .right-side {
      background-image: ${({ theme }) => theme.addOrders.backgroundImage};
      box-shadow: ${({ theme }) => theme.addOrders.boxShadow};
      border: ${({ theme }) => theme.addOrders.border};
      border-radius: 8px;
      padding: 15px;
      box-sizing: border-box;
      margin: 0px 1%;
      overflow-y: auto;
    }
    .left-side {
      width: 30%;
      height: 94%;
      margin-right: 0.5%;
      overflow-y: auto;

      .error {
        margin-top: 7px;
        font-size: 14px;
        color: #d53f8c;
        position: absolute;
        bottom: -10px;
        right: 0px;
      }
    }
    .right-side {
      width: 67%;
      height: 94%;
      margin-left: 0.5%;
    }

    p {
      margin: 0;
    }

    .form-element-wrapper {
      display: flex;
      flex-direction: column;
      color: ${({ theme }) => theme.COLORS.primary100};
      padding: 0px;
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 20px;
      position: relative;
      label {
        font-size: 14px;
        position: absolute;
      }
    }

    input {
      border: 1px solid ${({ theme }) => theme.COLORS.primary10029};
      outline: none;
      color: #5a1c47;
      transition: 0.5s all ease;
      background-color: #fbf6f9;
      padding: 12px;
      border-radius: 5px;
      box-sizing: border-box;
      margin-right: 1px;
      &:focus {
        border: 1px solid #d53f8c;
      }
      &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 30px #f9f5f7 inset !important;
      }
      ::placeholder {
        color: ${({ theme }) => theme.COLORS.primary10060};
      }
    }

    .search-input {
      padding: 12px 60px 12px 10px;
      background-color: ${({ theme }) =>
        theme.addOrders.searchInput.backgroundColor};
      color: ${({ theme }) => theme.COLORS.primary};
    }

    select {
      border: 1px solid ${({ theme }) => theme.COLORS.primary10029};
      margin: 24px 0px 7px 0px;
      padding: 10px 10px;
      text-align: center;
      border-radius: 5px;
      transition: 0.3s all ease;
      cursor: pointer;
      width: 100%;
      color: ${({ theme }) => theme.COLORS.primary100};
      background: transparent;
      &:hover {
        background: ${({ theme }) => theme.COLORS.primary10010};
      }
      &:focus {
        background: ${({ theme }) => theme.COLORS.primary10029};
        outline: none;
        border: 1px solid ${({ theme }) => theme.COLORS.primary100};
      }
      .empty-option {
        background-color: transparent;
        display: none;
      }
      option {
        color: #000000;
      }
    }

    .search-icon {
      height: 24px;
      position: absolute;
      top: 10px;
      right: 10px;
      fill: ${({ theme }) => theme.COLORS.primary100};
    }

    .close-icon {
      height: 20px;
      position: absolute;
      top: 12px;
      right: 38px;
      fill: #d53f8c;
      cursor: pointer;
    }

    .items-list {
      padding: 0;
      margin: 0;
      list-style-type: none;
      position: relative;
      li {
        font-size: 14px;
        padding: 12px 7px;
        border-top: 1px dashed ${({ theme }) => theme.COLORS.primary10020};
        display: flex;
        align-items: center;
        border-radius: 4px;
        margin: 2px 0px 0px 0px;
        cursor: pointer;
        height: auto;
        min-height: 40px;
        p {
          padding-left: 30px;
        }
      }
    }

    .search-container {
      position: sticky;
      top: 0;
      z-index: 100;
    }
  }

  .finish-selection-button {
    position: fixed;
    margin: 0 auto;
    width: 90%;
    bottom: 10px;
  }

  .input-and-delete-wrapper {
    display: flex;
    justify-content: space-between;
    width: 150px;
  }

  .drawer-background {
    width: 100vw;
    height: 100vh;
    background-color: ${({ theme }) => theme.modal.background};
    position: fixed;
    top: 0;
  }

  .finish-selection-button {
    position: fixed;
    bottom: 88px;
    z-index: 9999;
  }

  @media screen and (max-width: 1280px) {
    .left-and-right-container {
      .left-side {
        width: 39%;
        margin: 0 0.5%;
      }
      .right-side {
        width: 59%;
        margin: 0 0.5%;
      }
    }
  }

  @media screen and (max-width: 950px) {
    .left-and-right-container {
      overflow-y: auto;
      height: auto;
      margin-top: 10px;
      .right-side {
        width: 94%;
        margin: 0;
        height: 84vh;
        padding: 5px 0px 10vh 0px;
      }
      .left-side {
        position: fixed;
        width: 100%;
        z-index: 100;
        bottom: 0;
        margin: 0;
        background: ${({ theme }) =>
          theme.addOrders.itemSelectionMobile.backgroundColor};
        height: 85vh;
        padding-bottom: 70px;
        box-shadow: ${({ theme }) =>
          theme.addOrders.itemSelectionMobile.boxShadow};
      }
    }
  }
`;

import EmptyState from "components/EmptyState";
import { ShimmerEffect } from "SharedComponents/ShimmerEffect";
import { useState } from "react";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import styled from "styled-components";
import { fetchTopRequests } from "utils/requests";

const TopRequestedItems = () => {
  const { t } = useTranslation();
  const [requestedItems, setRequestedItems] = useState([]);

  const topRequestsReq = useQuery("Top requests", fetchTopRequests, {
    onSuccess: (data) => {
      setRequestedItems(data.data);
    },
  });

  return (
    <Wrapper>
        <div className="requests-and-title-wrapper">
        <h3 className="header-text top-requested-text">{t("Top 10 requested items")}</h3>

        <div className="requests-container">
            {topRequestsReq.isLoading && <LoadingOrdersShimmer />}
            {!topRequestsReq.isLoading && requestedItems?.length === 0 && (
            <EmptyState
                message="No Requested items"
                mode="small"
                flexDirection="row"
            />
            )}
            {!topRequestsReq.isLoading &&
            requestedItems?.length > 0 &&
            requestedItems.map((order) => (
                <motion.div
                key={order.id}
                className="card-list-item"
                >
                <section>
                    <p className="list-label">{t("Item Name")}</p>{" "}
                    <p className="list-content">{order.fullItemName}</p>
                </section>
                <section>
                    <p className="list-label">{t("Serial Number")}</p>{" "}
                    <p className="list-content">{order.itemSN}</p>
                </section>
                <section>
                    <p className="list-label">{t("Frequency")}</p>{" "}
                    <p className="list-content">{order.frequency}</p>
                </section>
                </motion.div>
            ))}
        </div>
      </div>
    </Wrapper>
  );
};

const LoadingOrdersShimmer = () => {
  const CardShimmer = () => (
    <div className="card-list-item">
      <ShimmerEffect
        width="200px"
        height="24px"
        marginTop="10px"
        borderRadius="5px"
      />
      <ShimmerEffect
        width="100px"
        height="12px"
        marginTop="15px"
        borderRadius="5px"
      />
      <ShimmerEffect
        width="40px"
        height="16px"
        marginTop="9px"
        borderRadius="5px"
      />
    </div>
  );
  return (
    <>
      <CardShimmer />
      <CardShimmer />
      <CardShimmer />
      <CardShimmer />
    </>
  );
};

const Wrapper = styled.div`
  padding: 0;
  margin: 0;
  list-style-type: none;
  margin: 0 40px;
  box-sizing: border-box;
  color: ${({ theme }) => theme.COLORS.primary};
  .requests-and-title-wrapper {
    width: 100%;
    margin-bottom: 20px;
  }
  .top-requested-text {
    border: none !important;
  }
  .requests-container {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    height: 280px;
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    ::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }

    ::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.COLORS.primary10020};
      width: 8px;
      border-radius: 5px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: ${({ theme }) => theme.COLORS.primary10020};
      width: 8px;
    }
  }
  .card-list-item {
    float: left;
    margin: 10px 20px 10px 3px;
    background-image: ${({ theme }) => theme.RequestsList.card.backgroundImage};
    border: ${({ theme }) => theme.RequestsList.card.border};
    box-shadow: ${({ theme }) => theme.RequestsList.card.boxShadow};
    min-width: 200px;
    width: fit-content;
    border-radius: 4px;
    color: ${({ theme }) => theme.COLORS.primary};
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    section {
      width: 100%;
      margin: 7px 0px;
    }
    p {
      margin: 0;
    }
    .list-label {
      font-size: 14px;
      color: ${({ theme }) => theme.COLORS.primary10096};
    }
    .list-content {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      letter-spacing: 1px;
      font-size: 16px;
      font-weight: bold;
      &:hover {
        
      }
    }
    &:hover {
      /* box-shadow: ${({ theme }) => theme.RequestsList.card.hover.boxShadow};
      border: ${({ theme }) => theme.RequestsList.card.hover.border}; */
      .list-content{
        overflow: visible;
        -webkit-line-clamp: unset;
      }
    }
  }

  @media screen and (max-width: 949px) {
    margin: 20px 0px 0px 0px;
    padding-bottom: 100px;
    .card-list-item {
      margin: 5px 20px 10px 2%;
    }
    .top-requested-text {
      margin-left: 4% !important;
    }
    .top-requested-text {
      margin-left: 2%;
    }
  }
`;

export default TopRequestedItems;
import { appActions } from "app.state";
import EmptyState from "components/EmptyState";
import LoadingState from "components/LoadingState";
import { motion } from "framer-motion";
import { useMutation } from "react-query";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { addOrderRequest, updateOrderRequest } from "utils/requests";
import { toggleItemOff } from "utils/addRemoveItems";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import Icons from "components/Icons";

export default function QuantityForm({
  selectedItems,
  setSelectedItems,
  formSubmitted,
  setFormSubmitted,
  paymentTypeId,
  itemsListForSelection,
  setItemsListForSelection,
  isLargeScreen,
  setShowItemSelection,
  perviousOrderFromLocalStorage,
  populateWithDataFromLocalStorage,
  fullItemDetailReqData,
  clearAllItems,
  letterNo,
  setLetterNo
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [submitType, setSubmitType] = useState("");
  const history = useHistory();
  const { id } = useParams();
  const handleSuccessError = (type, orderId, orderStatus) => {
    if (type === "ERROR") {
      dispatch(
        appActions.showNotification({
          type: "ERROR",
          message: "Order registration failed",
        })
      );
    } else {
      dispatch(
        appActions.showNotification({
          type: "SUCCESS",
          message:
            orderStatus === "Draft"
              ? "Your order is saved successfully"
              : "Your order is submitted successfully",
        })
      );

      history.push(`/${orderId}`);
    }
  };

  const [addOrderMutation, addOrderRequestStatus] = useMutation(
    id ? updateOrderRequest : addOrderRequest,
    {
      onSuccess: (data) => {
        if (data) {
          handleSuccessError(
            "SUCCESS",
            data?.data?.requestNo,
            data?.data?.requestStatus
          );
        } else {
          handleSuccessError("ERROR");
        }
      },
      onerror: () => {
        handleSuccessError("ERROR");
      },
    }
  );

  const quantityFormAnimationVariants = {
    hidden: { opacity: 0, y: 5 },
    visible: { opacity: 1, y: 0 },
  };

  const updateQuantity = (value, index) => {
    const newSelectedItems = [...selectedItems];
    newSelectedItems[index].quantity = value;
    setSelectedItems(newSelectedItems);
  };

  const storeRequestToLocalStorage = (requestData) => {
    localStorage.setItem("lastOrder", JSON.stringify(requestData));
  };

  const onFormSubmit = async (requestStatus) => {
    setFormSubmitted(true);
    let formIsValid = true;
    if (!paymentTypeId || (paymentTypeId === 1 && !letterNo)) {
      formIsValid = false;
    } else if (requestStatus === "Pending") {
      selectedItems.forEach((item) => {
        if (!item.quantity || item.quantity <= 0) {
          formIsValid = false;
        }
      });
    }
    if (formIsValid) {
      setSubmitType(requestStatus);
      const requestData = id
        ? {
            ...fullItemDetailReqData,
            paymentTypeId: parseInt(paymentTypeId, 10),
            requestStatus,
            requestDetails: selectedItems.map((item) => {
              const formattedItem = {
                itemUnitId: item.itemUnitId ? item.itemUnitId : item.id,
                itemSN: item.itemSN,
                unitSN: item.unitSN,
                fullItemName: item.fullItemName,
              };

              if (item.itemUnitId) {
                formattedItem.id = item.id;
              }
              if (item.quantity) {
                formattedItem.quantity = parseInt(item.quantity, 10);
              }
              return formattedItem;
            }),
          }
        : {
            paymentTypeId: parseInt(paymentTypeId, 10),
            requestStatus,
            requestDetails: selectedItems.map((item) => {
              const requestDataRegistration = {
                itemUnitId: item.id,
              };
              if (item.quantity) {
                requestDataRegistration.quantity = parseInt(item.quantity, 10);
              }
              return requestDataRegistration;
            }),
          };

      if (paymentTypeId === 1) {
        requestData.letterNo = letterNo;
      }

      await addOrderMutation(requestData);
      storeRequestToLocalStorage({
        ...requestData,
        requestDetails: selectedItems,
      });
    }
  };

  const removeSelectedItem = (item, index) => {
    const newItems = [...selectedItems];
    newItems.splice(index, 1);
    setSelectedItems(newItems);
    setItemsListForSelection(toggleItemOff(item, itemsListForSelection));
  };

  const parseItemName = (itemName) => {
    const parsedObj = {
      itemName: itemName.substring(0, itemName.lastIndexOf('-')),
      unit: itemName.substring(itemName.lastIndexOf('-') + 1),
    }
    return parsedObj;
  }

  return (
    <Wrapper>
      {selectedItems?.length === 0 && (
        <div className="empty-state-container">
          <EmptyState message={t("Select items for order")} />
          {perviousOrderFromLocalStorage && (
            <motion.button
              whileHover={{ scale: 1.01 }}
              whileTap={{ scale: 0.97 }}
              type="button"
              onClick={() => {
                populateWithDataFromLocalStorage(true);
              }}
              className="blue-button button-spacing red-button"
            >
              {t("Get items from last request")}
            </motion.button>
          )}
          {!isLargeScreen && (
            <motion.button
              whileHover={{ scale: 1.01 }}
              whileTap={{ scale: 0.97 }}
              type="button"
              onClick={() => {
                setShowItemSelection(true);
              }}
              className="blue-button button-spacing"
            >
              {t("Select Items")}
            </motion.button>
          )}
        </div>
      )}
      {addOrderRequestStatus.isLoading && (
        <LoadingState
          message={submitType === "Draft" ? "Saving Order" : "Submitting Order"}
        />
      )}
      {selectedItems?.length > 0 && !addOrderRequestStatus.isLoading && (
        <motion.div
          initial="hidden"
          animate="visible"
          variants={quantityFormAnimationVariants}
          transition={{ duration: 0.5 }}
        >
          <ul>
          
            {!isLargeScreen && (
              <styledList className="add-items">
                <motion.button
                  whileHover={{ scale: 1.01 }}
                  whileTap={{ scale: 0.97 }}
                  type="button"
                  onClick={() => {
                    setShowItemSelection(true);
                  }}
                  className="add-items-button"
                >
                  {t("Add Items")}
                </motion.button>
                </styledList>
              )}
            
            <li className="title-list-item">
                <h3 className="item-Text">{t("Item")}</h3>
                
                <h3 className="unit-Text">{t("Unit")}</h3>
              <div className="quantity-and-clear-all">
                <h3 className="quantity-Text">{t("Quantity")}</h3>
                {selectedItems?.length > 2 && (
                  <motion.button
                    whileHover={{ scale: 1.01 }}
                    whileTap={{ scale: 0.97 }}
                    type="button"
                    onClick={clearAllItems}
                    className="clear-all-button"
                  >
                    {t("Clear All")}
                  </motion.button>
                )}
              </div>
            </li>
            {selectedItems.map((el, index) => (
              <li key={el.id}>
                <section className="item-name">{parseItemName(el.fullItemName).itemName}</section>
                <section className="unit">{parseItemName(el.fullItemName).unit}</section>
                <section className="input-and-delete-wrapper">
                  <input
                    type="number"
                    value={el.quantity}
                    onChange={(e) => {
                      updateQuantity(e.target.value, index);
                    }}
                  />
                  {formSubmitted && (!el.quantity || el.quantity <= 0) && (
                    <div className="error">{t("Invalid")}</div>
                  )}
                  <motion.button
                    className="delete-button"
                    type="button"
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.5 }}
                    onClick={() => {
                      removeSelectedItem(el, index);
                    }}
                  >
                    <Icons.Delete />
                  </motion.button>
                </section>
              </li>
            ))}
          </ul>
          {paymentTypeId === 1 && (
            <div className="letter-number-wrapper">
              <label className="item-name">
                Letter Number
                <input
                  type="text"
                  name="letterNumbur"
                  value={letterNo}
                  onChange={(e) => {
                    setLetterNo(e.target.value);
                  }}
                />
              </label>
              {!letterNo && formSubmitted && (
                <div className="error">{t("Required")}</div>
              )}
            </div>
          )}

          <section className="submit-buttons">
            <div className="spacing full-width">
              <div>
                <motion.button
                  whileHover={{ scale: 1.01 }}
                  whileTap={{ scale: 0.97 }}
                  type="button"
                  onClick={() => {
                    onFormSubmit("Draft");
                  }}
                  className="blue-button red-button button-spacing"
                >
                  {t("Save As Draft")}
                </motion.button>
              </div>
            </div>

            <div className="spacing full-width button-spacing">
              <div>
                <motion.button
                  whileHover={{ scale: 1.01 }}
                  whileTap={{ scale: 0.97 }}
                  type="button"
                  onClick={() => {
                    onFormSubmit("Pending");
                  }}
                  className="blue-button"
                >
                  {t("Submit Order")}
                </motion.button>
              </div>
            </div>
          </section>
        </motion.div>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 0px 20px;
  ul {
    width: 100%;
    padding: 0;
    margin-right: 20px;
    margin: 0;
    li {
      font-size: 14px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      width: auto;
      border-bottom: 1px dashed ${({ theme }) => theme.COLORS.primary10040};
      padding: 20px 0px;
      color: ${({ theme }) => theme.COLORS.primary100};
      position: relative;
      .item-name {
        width: 48%;
        margin-right: 15px;
        padding: 5px 2px;
      }
      .unit {
        width: 10%;
        margin-right: 15px;
        padding: 5px 2px;
      }
      .input-and-delete-wrapper{
        width: 25%;
        margin-right: 15px;
        padding: 5px 2px;
      }
      input {
        width: 60%;
        padding: 0px;
        background-color: ${({ theme }) =>
          theme.addOrders.searchInput.backgroundColor} !important;
        color: ${({ theme }) => theme.COLORS.primary} !important;
      }
      .error {
        margin-top: 7px;
        font-size: 14px;
        color: #d53f8c;
        position: absolute;
        bottom: 3px;
        right: 13%;
      }
      .item-Text {
        width: 48%;
      }
      .unit-Text {
        width: 10%;
      }
      .quantity-Text {
        margin-right: 15%;
      }
    }
    styledList{    
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      width: auto;
      color: ${({ theme }) => theme.COLORS.primary100};
      position: relative;
    }
    .title-list-item {
      padding: 5px 0px;
    }
    .add-items {
      justify-content: center;
      border-buttom: 0px;
    }
  }
  .payment-type-selection {
    width: 300px;
    margin: 30px 0px;
  }
  .red-button {
    background: ${({ theme }) => theme.COLORS.primary100};
    box-shadow: 0 5px 8px ${({ theme }) => theme.COLORS.primary10040};
    color: ${({ theme }) => theme.COLORS.white};
    &:hover {
      background: #852a6a;
    }
  }
  .button-spacing {
    margin: 20px 20px 0px 0px;
  }
  .submit-buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    max-width: 520px;
  }
  .delete-button {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    width: 38px;
    height: 38px;
    border-radius: 9999%;
    border: none;
    background-color: ${({ theme }) => theme.COLORS.primary10020};
    cursor: pointer;
    transition: 0.5s all ease;
    outline: none;
    box-shadow: 0px 0px 0px 2px ${({ theme }) => theme.COLORS.primary10020};
    svg {
      height: 16px;
      fill: ${({ theme }) => theme.COLORS.primary100};
      /* padding: 10px; */
    }
  }

  .empty-state-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .add-items-button {
    background-color: ${({ theme }) => theme.COLORS.blue20};
    cursor: pointer;
    transition: 0.5s all ease;
    outline: none;
    color: ${({ theme }) => theme.COLORS.blue};
    border: none;
    padding: 15px 20px;
    font-weight: 600;
    border-radius: 5px;
    font-size: 16px;
    min-width: 200px;
    margin-bottom: 10px;
  }

  .clear-all-button {
    justify-content: center;
    background-color: ${({ theme }) => theme.COLORS.primary10020};
    color: ${({ theme }) => theme.COLORS.primary100};
    min-width: auto;
    height: 35px;
    padding: 0px 5px;
    border-radius: 5px;
    border: 1px solid ${({ theme }) => theme.COLORS.primary10050};
    margin-right: -15px;
    &:focus {
      outline: none;
    }
  }

  .item-and-unit {
    display: flex;
    justify-content: space-between;
  }

  .quantity-and-clear-all {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25%;
  }

  .letter-number-wrapper {
    margin-top: 20px;
    margin-bottom: 10px;
    position: relative;
    max-width: 360px;
    label {
      color: ${({ theme }) => theme.COLORS.primary100};
      font-size: 14px;
    }
    input {
      margin-left: 10px;
    }
    .error {
      margin-top: 7px;
      font-size: 14px;
      color: #d53f8c;
      position: absolute;
      bottom: -20px;
      right: 60px;
    }
  }

  @media screen and (max-width: 600px) {
    .add-items{
      .add-items-button {
        font-weight: 600;
        border: none;
        box-sizing: border-box;
        padding: 15px 20px;
        width: auto;
        min-width: 200px;
        border-radius: 5px;
        cursor: pointer;
        transition: 0.3s all ease;
        outline: none;
        &:hover {
          background-image: ${({ theme }) => theme.COLORS.primary10040};
        }
        &:focus {
          box-shadow: none;
        }
      }
    }
    .title-list-item {
      justify-content: center;
      align-items: center;
      h3 {
        display: none;
      }
      
      .clear-all-button {
        font-weight: 600;
        border: none;
        box-sizing: border-box;
        padding: 15px 20px;
        width: auto;
        min-width: 200px;
        border-radius: 5px;
        cursor: pointer;
        transition: 0.3s all ease;
        outline: none;
        font-size: 16px;
        height: auto;
        margin: 0px;
        &:hover {
          background-image: ${({ theme }) => theme.COLORS.primary10040};
        }
        &:focus {
          box-shadow: none;
        }
      }
    }
  }

  @media screen and (max-width: 550px) {
    ul {
      li {
        .item-name {
          width: 100%;
        }
        .unit {
          width: 10%;
          margin-right: 15px;
          padding: 5px 2px;
        }
        .input-and-delete-wrapper{
          width: 50%;
          margin-right: 15px;
          padding: 5px 2px;
        }
        input {
          width: 60%;
          padding: 0px;
          background-color: ${({ theme }) =>
            theme.addOrders.searchInput.backgroundColor} !important;
          color: ${({ theme }) => theme.COLORS.primary} !important;
        }
        .error {
          margin-top: 7px;
          font-size: 14px;
          color: #d53f8c;
          position: absolute;
          bottom: 3px;
          right: 30%;
        }
      }
    }
    .button-spacing {
      margin: 20px 0px 0px 0px;
    }
  }

  @media screen and (max-width: 379px) {
    .letter-number-wrapper {
      input {
        margin-left: 0px;
      }
    }
  }
`;

const styledList = styled.li`
  font-size: 14px;
  `;
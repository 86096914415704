import { Formik, Form } from "formik";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import styled from "styled-components";
import * as Yup from "yup";
import { signUpRequest } from "utils/requests";
import Input from "SharedComponents/Input";
import { useDispatch } from "react-redux";
import { appActions } from "app.state";
import { useTranslation } from "react-i18next";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input/input";
import Constants from "utils/constants";
import FacilitySearch from "./FacilitySearch";

export default function Signup({
  setSignInInfo,
  setActiveTab,
  setShowVerificationCode,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [sameAsPhoneNumber, setSameAsPhoneNumber] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [phoneNumberTouched, setPhoneNumberTouched] = useState(false);
  const [phoneNumberTelegram, setPhoneNumberTelegram] = useState(null);
  const [selectedFacility, setSelectedFacility] = useState(null);
  const [facilityRequired, setFacilityRequired] = useState(false);
  const [phoneNumberTelegramTouched, setPhoneNumberTelegramTouched] = useState(
    false
  );
  const [signupMutation, signupRequestStatus] = useMutation(signUpRequest, {
    onSuccess: () => {
      dispatch(
        appActions.showNotification({
          type: "SUCCESS",
          message: "Registration success",
        })
      );
      setShowVerificationCode(true);
      setActiveTab("login");
    },
  });

  useEffect(() => {
    if (signupRequestStatus.isError) {
      const errorMessage =
        signupRequestStatus?.error?.response?.data?.errors[0] ||
        "Registration Error";
      dispatch(
        appActions.showNotification({
          type: "ERROR",
          message: errorMessage,
        })
      );
    }
  }, [signupRequestStatus.isError]);

  const onFormSubmit = async ({ firstName, lastName, username }) => {
    if (!selectedFacility) {
      setFacilityRequired(true);
    }
    if (
      selectedFacility &&
      phoneNumber &&
      (sameAsPhoneNumber || phoneNumberTelegram) &&
      (selectedFacility?.institutionType !== "Private" || selectedFacility?.tinNumber)
    ) {
      const requestObject = {
        facilityId: selectedFacility.id,
        firstName,
        lastName,
        username,
        phoneNumber,
        telegramPhoneNumber: sameAsPhoneNumber
          ? phoneNumber
          : phoneNumberTelegram,
      };
      const requestData = {
        username,
        phoneNumber,
      };
      setSignInInfo(requestData);
      await signupMutation(requestObject);
    }
  };
  return (
    <Wrapper>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          username: "",
          sameAsPhone: true,
        }}
        validationSchema={Yup.object({
          firstName: Yup.string().required("Required"),
          lastName: Yup.string().required("Required"),
          username: Yup.string().required("Required"),
        })}
        onSubmit={(values) => {
          onFormSubmit(values);
        }}
      >
        {(props) => (
          <Form>
            <div className="spacing">
              <FacilitySearch
                selectedFacility={selectedFacility}
                setSelectedFacility={setSelectedFacility}
                facilityRequired={facilityRequired}
                setFacilityRequired={setFacilityRequired}
                mode={Constants.signUpWithFacility}
              />
            </div>

            <div className="spacing">
              <Input label={t("First Name")} name="firstName" type="text" />
            </div>

            <div className="spacing">
              <Input label={t("Last Name")} name="lastName" type="text" />
            </div>

            <div className="spacing">
              <Input label={t("Username")} name="username" type="text" />
            </div>

            <div className="form-element-wrapper">
              <PhoneInput
                country="ET"
                international
                withCountryCallingCode
                value={phoneNumber}
                onChange={setPhoneNumber}
                onBlur={() => {
                  setPhoneNumberTouched(true);
                }}
              />
              {phoneNumberTouched &&
                phoneNumber &&
                !isValidPhoneNumber(phoneNumber) && (
                  <div className="error">Invalid phone number</div>
                )}
              {props.submitCount > 0 && !phoneNumber && (
                <div className="error">Required</div>
              )}
            </div>

            <div className="spacing switch-container">
              <div className="title">{t("Telegram Phone Number")}</div>
              <div className="switch-and-text">
                {t("Same As Phone Number")}
                <label className="switch switch-margin">
                  <input
                    type="checkbox"
                    checked={sameAsPhoneNumber}
                    onChange={() => {
                      setSameAsPhoneNumber(!sameAsPhoneNumber);
                    }}
                  />
                  <span className="slider round" />
                </label>
              </div>
              {!sameAsPhoneNumber && (
                <div className="form-element-wrapper">
                  <PhoneInput
                    country="ET"
                    international
                    withCountryCallingCode
                    value={phoneNumberTelegram}
                    onChange={setPhoneNumberTelegram}
                    onBlur={() => {
                      setPhoneNumberTelegramTouched(true);
                    }}
                  />
                  {phoneNumberTelegramTouched &&
                    phoneNumber &&
                    !isValidPhoneNumber(phoneNumberTelegram) && (
                      <div className="error">Invalid phone number</div>
                    )}
                  {props.submitCount > 0 && !phoneNumber && (
                    <div className="error">Required</div>
                  )}
                </div>
              )}
            </div>

            <div className="spacing full-width">
              <div>
                <button type="submit" className="blue-button">
                  {signupRequestStatus.isLoading ? "Registering..." : "Sign Up"}
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  height: auto;
  background-image: ${({ theme }) => theme.LANDING.card.backgroundImage};
  box-shadow: ${({ theme }) => theme.LANDING.boxShadow};
  border: 2px solid ${({ theme }) => theme.LANDING.card.border};
  border-radius: 30px;
  padding: 5%;
  box-sizing: border-box;

  .spacing {
    margin: 50px 0px !important;
  }

  .full-width {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p {
      text-align: right;
      color: ${({ theme }) => theme.COLORS.primary100};
      margin-bottom: 0;
    }
  }

  .switch-container {
    font-size: 14px;
    .title {
      padding: 0px 20px;
      width: 100%;
      font-size: 18px;
      color: ${({ theme }) => theme.COLORS.primary100};
    }
    .switch-and-text {
      padding: 0px 20px;
      display: flex;
      align-items: center;
      margin-top: 8px;
      color: ${({ theme }) => theme.COLORS.primary10080};
    }
  }

  .switch-margin {
    margin: 0 8px 0 12px !important;
  }

  .form-element-wrapper {
    display: flex;
    flex-direction: column;
    color: ${({ theme }) => theme.COLORS.primary100};
    padding: 0px 20px;
    font-size: 16px;
    font-weight: 400;
    margin: 0px 0px;
    position: relative;
    label {
      font-size: 18px;
      position: absolute;
    }
    input {
      border: none;
      border-bottom: 1px solid ${({ theme }) => theme.COLORS.primary10029};
      outline: none;
      color: ${({ theme }) => theme.COLORS.black};
      transition: 0.5s all ease;
      background-color: transparent;
      padding-top: 0px;
      &:focus {
        border-bottom: 1px solid #d53f8c;
      }
      &:-webkit-autofill {
        -webkit-box-shadow: ${({ theme }) =>
          theme.LANDING.input.webkitBoxShadow};
        color: ${({ theme }) => theme.COLORS.black};
      }
    }
    .error {
      margin-top: 7px;
      font-size: 14px;
      color: #d53f8c;
      position: absolute;
      bottom: -20px;
    }
  }

  @media screen and (max-width: 540px) {
    padding: 0px;
    border-radius: 8px;
    .spacing {
      margin: 35px 0px !important;
    }
    .title {
      font-size: 14px;
    }
  }
`;

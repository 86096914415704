import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import generatePdf from "utils/generatePdf";

export default function PrintItem({ order, items, message, pdfTitle, type }) {
  const { t } = useTranslation();
  return (
    <motion.button
      whileHover={{ scale: 1.03 }}
      whileTap={{ scale: 0.9 }}
      type="button"
      className={message ? "print-button print-stockout" : "print-button"}
      onClick={() => {
        generatePdf(order, items, pdfTitle, type);
      }}
    >
      {message || t("Print")}
    </motion.button>
  );
}

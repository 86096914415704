import Spinner from "components/Spinner";
import { useRef, useState } from "react";
import { useQuery } from "react-query";
import styled from "styled-components";
import requests from "utils/requests";
import useOnClickOutside from "hooks/useOnClickOutside";
import EmptyState from "components/EmptyState";
import { useTranslation } from "react-i18next";
import useDebounce from "hooks/useDebounce";
import Constants from "utils/constants";

export default function FacilitySearch({
  selectedFacility,
  setSelectedFacility,
  facilityRequired,
  setFacilityRequired,
  mode,
}) {
  const [searchTerm, setSearchTerm] = useState("");
  const [showDropDown, setShowDropDown] = useState(false);
  const { t } = useTranslation();
  const menuDropdown = useRef(null);
  useOnClickOutside(menuDropdown, () => {
    setShowDropDown(false);
  });
  const searchInstitutionReq = useQuery(
    ["Search Institutions", useDebounce(searchTerm, 1000)],
    requests.searchInstitutions,
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (data?.length === 1) {
          setSelectedFacility(data[0]);
          setFacilityRequired(false);
        }
      },
    }
  );

  const onSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <Wrapper ref={menuDropdown} mode={mode}>
      <label htmlFor="faclity-id">{t("Select Facility")}</label>
      <input
        className="text-input"
        id="facility-id"
        placeholder={`${t("Facility Name")}, ${t("TIN Number")}`}
        autoComplete="off"
        value={searchTerm}
        onChange={onSearchTermChange}
        onFocus={() => {
          setShowDropDown(true);
        }}
      />
      {facilityRequired && mode === Constants.signUpWithFacility && <div className="error">Required</div>}
      {selectedFacility?.institutionType === "Private" && !selectedFacility?.tinNumber && mode === Constants.signUpWithFacility && <div className="error">Private facilities with no tin number are not allowed</div>}
      {searchInstitutionReq.isLoading && (
        <button type="submit" className="search-button">
          <Spinner />
        </button>
      )}
      {showDropDown && (
        <div className="search-result-wrapper">
          {searchInstitutionReq?.data?.length === 0 && (
            <div className="search-result empty-state-wrapper">
              <EmptyState
                message={t("No facilities found")}
                mode="small"
                flexDirection="column"
              />
            </div>
          )}
          {searchInstitutionReq?.data?.length > 0 && (
            <ul className="search-result">
              {searchInstitutionReq?.data?.map((el, index) => (
                <li
                  key={el.id}
                  className={
                    index === searchInstitutionReq.data.length - 1
                      ? "last-item"
                      : ""
                  }
                  onClick={() => {
                    setSearchTerm(el.name);
                    setShowDropDown(false);
                    setSelectedFacility(el);
                    setFacilityRequired(false);
                  }}
                >
                  <h4>{el.name}</h4>
                  <p>{`TIN No: ${el.tinNumber || ""}`}</p>
                  <p>{`${el.region || '--'}, ${el.zone || '--'}, ${el.woreda}`}</p>
                </li>
              ))}
            </ul>
          )}
        </div>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.COLORS.primary100};
  padding: ${props => (props.mode === Constants.signUpWithFacility ? '0px 20px;' : '0px 0px;')};
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 400;
  margin: 10px 0px;
  position: relative;
  label {
    font-size: ${props => (props.mode === Constants.signUpWithFacility ? '18px;' : '14px;')};
  }
  input {
    border: none;
    border-bottom: 1px solid ${({ theme }) => theme.COLORS.primary10029};
    outline: none;
    color: ${(p) => p.theme.COLORS.black};
    transition: 0.5s all ease;
    background-color: transparent;
    &:focus {
      border-bottom: 1px solid #d53f8c;
    }
  }
  .error {
    margin-top: 7px;
    font-size: 14px;
    color: #d53f8c;
    position: absolute;
    bottom: -20px;
  }

  .search-button {
    height: 38px;
    width: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    position: absolute;
    right: ${props => (props.mode === Constants.signUpWithFacility ? '10px;' : '0px;')};
    top: ${props => (props.mode === Constants.signUpWithFacility ? '0px;' : '25px;')};
    outline: none;
    background: transparent;
    svg {
      height: 25px;
      fill: ${(p) => p.theme.searchInput.searchIconColor};
    }
  }

  .search-result {
    width: 90%;
    position: absolute;
    background-color: ${(p) => p.theme.searchDropDown.backgroundColor};
    max-width: 420px;
    border-radius: 8px;
    box-shadow: ${(p) => p.theme.searchDropDown.boxShadow};
    box-sizing: border-box;
    z-index: 9999;
    list-style-type: none;
    top: ${props => (props.mode === Constants.signUpWithFacility ? '45px;' : '68px;')};
    border: 1px solid ${(p) => p.theme.COLORS.primary10020};
    padding: 0;
    margin: 0 50px 0 0;
    height: auto;
    max-height: 400px;
    overflow-y: auto;
    .last-item {
      border-bottom: none;
    }
    li {
      width: 100%;
      border-bottom: 1px dashed ${({ theme }) => theme.COLORS.primary10020};
      padding: 12px 2px 12px 10px;
      box-sizing: border-box;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      cursor: pointer;
      transition: 0.5s all ease;
      h4,
      p {
        margin: 0px;
      }

      h4 {
        font-weight: 500;
        font-size: 16px;
        color: ${(p) => p.theme.COLORS.primary};
      }

      p {
        color: ${({ theme }) => theme.COLORS.primary10096};
        margin-bottom: 3px;
        font-weight: 400 !important;
        font-size: 14px;
      }

      &:hover {
        background-color: ${(p) => p.theme.COLORS.primary10010};
      }
    }
  }

  .empty-state-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0px;
    svg {
      height: 150px !important;
    }
  }
  @media screen and (max-width: 540px) {
    label {
      font-size: 14px;
    }
  }
`;

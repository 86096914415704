import Icons from "components/Icons";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import styled from "styled-components";
import searchOrders from "utils/searchOrders";
import { useQuery } from "react-query";
import { fetchAllRequests } from "utils/requests";
import { useTranslation } from "react-i18next";
import getRecentlyAddedAndUpdated from "utils/getRecentlyAddedAndUpdated";
import SearchFormMobile from "./SearchFormMobile";
import SearchConfigurationDropdown from "../SearchConfigurationDropdown";
import SearchResultList from "../SearchResultList";

export default function SearchDrawer({
  setShowNotificationDrawer,
  showNotificationDrawer,
}) {
  const { i18n } = useTranslation();
  const [searchType, setSearchType] = useState("OrderNumber");
  const [showConfig, setShowConfig] = useState(false);
  const [searchQuery, setSeachQuery] = useState(null);
  const [recentlyAddedUpdated, setRecentlyAddedUpdated] = useState([]);
  const [searchResult, setSearchResult] = useState([]);
  const allRequestsReq = useQuery("All requests", fetchAllRequests);

  const filterSeachResult = (query) => {
    setSearchResult([
      ...searchOrders[searchType === "Date" ? "byDate" : "byOrderNumber"](
        allRequestsReq.data,
        query,
        i18n.language
      ),
    ]);
  };

  useEffect(() => {
    if (allRequestsReq.data) {
      setRecentlyAddedUpdated(getRecentlyAddedAndUpdated(allRequestsReq.data));
    }
  }, [allRequestsReq.data]);

  return (
    <>
      <AnimatePresence>
        {showNotificationDrawer && (
          <Wrapper
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={{
              hidden: { x: 350 },
              visible: { x: 0, opacity: 1 },
              exit: { x: 350, opacity: 0 },
            }}
            transition={{ ease: "easeOut", duration: 0.5 }}
          >
            <div className="back-and-search-wrapper">
              <button
                type="button"
                className="notification-button margin-none"
                onClick={() => {
                  setShowNotificationDrawer(false);
                }}
              >
                <Icons.Back />
              </button>
              <SearchFormMobile
                searchType={searchType}
                setSeachQuery={setSeachQuery}
                filterSeachResult={filterSeachResult}
              />
              <button
                type="button"
                className="notification-button margin-none chevron-down"
                onClick={() => {
                  setShowConfig(true);
                }}
              >
                <Icons.ChevronDown />
              </button>
            </div>

            <AnimatePresence>
              {showConfig && (
                <SearchConfigurationDropdown
                  searchType={searchType}
                  setSearchType={setSearchType}
                  setShowConfig={setShowConfig}
                />
              )}
            </AnimatePresence>

            <SearchResultList
              searchResult={searchResult}
              searchQuery={searchQuery}
              recentlyAddedUpdated={recentlyAddedUpdated}
              setShowSearchResult={setShowNotificationDrawer}
            />
          </Wrapper>
        )}
      </AnimatePresence>
    </>
  );
}

const Wrapper = styled(motion.div)`
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 9999;
  background-color: ${({ theme }) => theme.drawer.backgroundColor};
  backdrop-filter: blur(15px);
  .back-and-search-wrapper {
    width: 100%;
    padding: 0px;
    margin-top: 5px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .margin-none {
    margin: 5px !important;
  }
`;

import styled from "styled-components";

export default function Footer() {
  return (
    <Wrapper>© EPSS - Ethiopian Pharmaceutical Supply Service 2022</Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #5a1c47;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  color: #ffffff;
`;

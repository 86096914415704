/* eslint-disable import/no-duplicates */
import { motion } from "framer-motion";
import styled from "styled-components";
import devicesPNG from "resources/landingPage/Devices.png";
// eslint-disable-next-line import/no-duplicates
import devicesAVIF from "resources/landingPage/Devices.png";
import { useEffect, useRef, useState } from "react";

export default function DevicesSection() {
  const elementRef = useRef(null);
  const [showElement, setShowElement] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setShowElement(true);
        }
      },
      {
        root: null,
        rootMargin: "20%",
        threshold: 0.4,
      }
    );
    if (elementRef.current) {
      observer.observe(elementRef.current);
    }
    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <Wrapper ref={elementRef}>
      {showElement && (
        <>
          <motion.h1
            initial={{ opacity: 0, scale: 0.7, y: -70 }}
            animate={{ opacity: 1, scale: 1, y: 0 }}
            transition={{ duration: 0.7 }}
          >
            Everything you need to order Anywhere you are.
          </motion.h1>
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.7, delay: 0.7 }}
          >
            Order, Follow up, Get report, Check on previous orders
          </motion.p>
          <motion.picture
            initial={{ opacity: 0, y: 100 }}
            animate={{ opacity: 1, y: 0, delay: 0.7 }}
            transition={{
              duration: 1.2,
              type: "tween",
              delay: 0.5,
            }}
          >
            <source
              type="image/avif"
              srcSet={devicesAVIF}
              className="device-image"
            />
            <img
              loading="lazy"
              alt="Devices"
              src={devicesPNG}
              className="device-image"
            />
          </motion.picture>
        </>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #702459;
  width: 100%;
  margin-top: 100px;
  padding: 50px 30px;
  box-sizing: border-box;
  min-height: 800px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow-y: hidden;
  border-top: 1px solid #ffffff50;
  color: #ffffff;
  h1 {
    font-size: 60px;
    font-family: "Fredoka One", cursive;
    max-width: 900px;
    letter-spacing: 1px;
    text-align: center;
    -webkit-text-fill-color: transparent;
    -webkit-box-decoration-break: clone;
    background: linear-gradient(-70deg, #d096be, #fdfdff);
    -webkit-background-clip: text;
  }

  p {
    font-size: 24px;
    opacity: 50%;
    letter-spacing: 1px;
  }

  .device-image {
    margin-top: 20px;
    width: 80%;
    max-width: 1000px;
    margin-left: 5%;
  }
`;

import { AnimatePresence } from "framer-motion";
import { useState } from "react";
import styled from "styled-components";
import SearchConfigurationDropdown from "../SearchConfigurationDropdown";
import SearchFormDesktop from "./SearchFormDesktop";

export default function SearchDesktop({ allRequestsReq }) {
  const [showConfig, setShowConfig] = useState(false);
  const [searchType, setSearchType] = useState("OrderNumber");

  return (
    <Wrapper>
      <SearchFormDesktop
        setShowConfig={setShowConfig}
        searchType={searchType}
        allRequestsReq={allRequestsReq}
      />
      <AnimatePresence>
        {showConfig && (
          <SearchConfigurationDropdown
            searchType={searchType}
            setSearchType={setSearchType}
            setShowConfig={setShowConfig}
          />
        )}
      </AnimatePresence>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

import Icons from "components/Icons";
import { useState } from "react";
import styled from "styled-components";
import notificationButton from "styles/notificationButton";
import SearchDrawer from "./SearchDrawer";

export default function SearchMobile() {
  const [showNotificationDrawer, setShowNotificationDrawer] = useState(false);
  return (
    <Wrapper>
      <button
        type="button"
        className="notification-button"
        onClick={() => {
          setShowNotificationDrawer(true);
        }}
        onBlur={() => {}}
      >
        <Icons.Search />
      </button>
      <SearchDrawer
        setShowNotificationDrawer={setShowNotificationDrawer}
        showNotificationDrawer={showNotificationDrawer}
      />
    </Wrapper>
  );
}
const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  .notification-button {
    ${notificationButton}
    margin-right: 10px;
  }
`;

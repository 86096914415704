import { appActions } from "app.state";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useQuery, useMutation } from "react-query";
import {
  fetchRequestStatusChanges,
  markNotificationAsSeen,
  fetchAllRequests,
} from "utils/requests";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import Icons from "components/Icons";
import notificationButton from "styles/notificationButton";

export default function Notification() {
  const [showNotificationDropdown, setShowNotificationDropdown] = useState(
    false
  );
  const notificationDropdown = useRef(null);
  const [notificationCount, setNotificationCount] = useState(null);
  const [notificationList, setNotificationList] = useState(null);
  // const [clickedNotification, setClickedNotification] = useState(null);
  const dispatch = useDispatch();
  // eslint-disable-next-line no-unused-vars
  const [markAsSeenMutation] = useMutation(markNotificationAsSeen);
  const notificationReq = useQuery("Notification", fetchRequestStatusChanges, {
    onSuccess: (data) => {
      setNotificationCount(data.length);
      setNotificationList(data.map((el) => ({ ...el, viewed: false })));
    },
  });
  const allRequests = useQuery("All requests", fetchAllRequests, {
  });

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        notificationDropdown.current &&
        !notificationDropdown.current.contains(event.target)
      ) {
        setShowNotificationDropdown(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [notificationDropdown]);

  const onNotificationListItemClick = async (itemIntex) => {
    setShowNotificationDropdown(false);
    const notifications = [...notificationList];
    if (notifications[itemIntex].viewed === false) {
      notifications[itemIntex].viewed = true;
      setNotificationList([...notifications]);
      setNotificationCount(notificationCount - 1);
      await markAsSeenMutation({
        id: notifications[itemIntex].id,
        requestId: notifications[itemIntex].requestId,
      });
    }
    if (allRequests.data) {
      allRequests.data.map((rq) => {
      if (rq.id === notifications[itemIntex].requestId){
        dispatch(
          appActions.showRequestDetailModal({
            selectedRequest: rq.id,
            selectedRequestData: rq,
          })
        );
      }
        return rq});
    }
  };

  return (
    <Wrapper ref={notificationDropdown}>
      <button
        type="button"
        className="settingnsButton notification-button"
        onClick={() => {
          setShowNotificationDropdown(true);
          setTimeout(() => {
            notificationDropdown?.current?.focus();
          }, 200);
        }}
        onBlur={() => {}}
      >
        {notificationReq.data && (
          <span className="count">{notificationCount}</span>
        )}
        <Icons.Notification />
      </button>
      <AnimatePresence>
        {showNotificationDropdown && (
          <motion.div
            initial={{ opacity: 0, y: 5 }}
            animate={{ opacity: 1, y: 35 }}
            exit={{ opacity: 0, y: 5 }}
            transition={{ duration: 0.5 }}
            className="drop-down"
          >
            {notificationReq.isLoading && <div>Loadinng</div>}
            {notificationReq.isSuccess && (
              <ul>
                {notificationList.map((notificationData, index) => (
                  <NavLink
                    to={`/${notificationData.requestNo}`}
                    key={notificationData.id}
                    onClick={() => {
                      onNotificationListItemClick(index);
                    }}
                  >
                    <NotificationListItem viewed={notificationData.viewed}>
                      Order <b>{notificationData.requestNo}</b> status is
                      changed from <b>{notificationData.fromStatus}</b> to{" "}
                      <b>{notificationData.toStatus}</b>
                    </NotificationListItem>
                  </NavLink>
                ))}
              </ul>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  .settingnsButton {
    ${notificationButton}
  }

  .notification-button {
    margin-right: 15px !important;
    position: relative;
    .count {
      position: absolute;
      top: -7px;
      right: -7px;
      background: #ef4444;
      font-size: 13px;
      color: #ffffff;
      min-width: 20px;
      min-height: 20px;
      padding: 1px 1px;
      border-radius: 9999%;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #ffffff;
    }
  }

  .drop-down {
    position: absolute;
    top: 20px;
    background-color: ${({ theme }) => theme.drawer.backgroundColor};
    backdrop-filter: blur(15px);
    width: 280px;
    min-height: 210px;
    height: auto;
    max-height: 50vh;
    overflow-y: auto;
    border-radius: 8px;
    box-shadow: ${({ theme }) => theme.TopNav.dropdown.notificationBoxShadow};
    border-top: 1px solid #70245910;
    z-index: 999999 !important;
    h4 {
      text-align: left;
      padding: 10px 10px 0px 10px;
      margin: 0px;
      font-family: "Fredoka One", cursive;
      letter-spacing: 1px;
      color: ${({ theme }) => theme.COLORS.grey100};
    }
    ul {
      padding: 0;
      margin: 0;
      padding-top: 5px;
      list-style-type: none;
      a {
        text-decoration: none;
      }
    }
  }

  @media screen and (max-width: 480px) {
    .drop-down {
      position: fixed !important;
      left: 5%;
      bottom: 0;
      width: 90%;
      box-shadow: ${({ theme }) => theme.TopNav.dropdown.boxShadowMobile};
    }
  }
`;

const NotificationListItem = styled.li`
  color: ${({ theme, viewed }) =>
    viewed ? theme.COLORS.grey10060 : theme.COLORS.grey100};
  padding: 10px 2px 10px 10px;
  border-top: 1px dashed ${({ theme }) => theme.COLORS.primary10020};
  cursor: pointer;
  font-size: 14px;
`;

import { useQuery } from "react-query";
import { ShimmerEffect } from "SharedComponents/ShimmerEffect";
import styled from "styled-components";
import { fetchRequestStatusLogs } from "utils/requests";
// import { ReactComponent as IconQuestionmark } from "resources/questionmark.svg";
import dateFormatter from "utils/dateFormatter";
import Icons from "components/Icons";

export default function OrderStatusLogs({ id }) {
  const orderStatusLogsReq = useQuery(
    ["Order Status Logs", id],
    fetchRequestStatusLogs
  );

  return (
    <Wrapper>
      {orderStatusLogsReq.isLoading && <LoadingStatusLogs />}
      {orderStatusLogsReq.data && (
        <div className="status-data-wrapper">
          {orderStatusLogsReq.data.map((status, index) => (
            <ArrowAndButtonWrapper key={index.toString()} index={index}>
              {index === 0 && <span className="start-word">Start</span>}
              <div className="arrow-and-date">
                {/* {index !== orderStatusLogsReq.data.length - 1 && <IconLongArrow />} */}
                <Icons.LongArrow />
                <span className="date">
                  {dateFormatter.yearMonthDate(status.date)}
                </span>
              </div>
              <RenderStatusButton status={status.toStatus} />
            </ArrowAndButtonWrapper>
          ))}
        </div>
      )}
    </Wrapper>
  );
}

const RenderStatusButton = ({ status }) => {
  switch (status) {
    case "Pending":
      return (
        <div className="status-and-button">
          <span>{status}</span>
          <button type="button">
            <Icons.Pending />
          </button>
        </div>
      );
    case "Submitted":
      return (
        <div className="status-and-button">
          <span>{status}</span>
          <button type="button">
            <Icons.Submitted />
          </button>
        </div>
      );
    case "Draft":
      return (
        <div className="status-and-button">
          <span>{status}</span>
          <button type="button">
            <Icons.Drafted />
          </button>
        </div>
      );
    case "Rejected":
      return (
        <div className="status-and-button">
          <span>{status}</span>
          <button type="button">
            <Icons.Rejected />
          </button>
        </div>
      );
    case "Approved":
      return (
        <div className="status-and-button">
          <span>{status}</span>
          <button type="button">
            <Icons.Approved />
          </button>
        </div>
      );
    case "PickListed":
      return (
        <div className="status-and-button">
          <span>{status}</span>
          <button type="button">
            <Icons.Picklist />
          </button>
        </div>
      );
    default:
      return null;
  }
};

const LoadingStatusLogs = () => {
  return (
    <div className="status-wrapper">
      <ShimmerEffect width="54px" height="54px" borderRadius="9999%" />
      <ShimmerEffect width="54px" height="54px" borderRadius="9999%" />
      <ShimmerEffect width="54px" height="54px" borderRadius="9999%" />
      <div className="line" />
    </div>
  );
};

const Wrapper = styled.div`
  margin: 10px 0px 10px 0px;
  padding-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.COLORS.primary10010};

  .status-data-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: nowrap;
    align-items: center;
    overflow-x: auto;
    overflow-y: none;
    padding: 20px 0px;
    box-sizing: border-box;
    height: 100px;
    padding-right: 80px;
    ::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }

    ::-webkit-scrollbar-track {
      background: ${({ theme }) => theme.COLORS.primary10020};
      width: 4px;
    }

    ::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.COLORS.primary100};
      width: 4px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: ${({ theme }) => theme.COLORS.primary100};
    }
  }
  .status-wrapper {
    width: 100%;
    max-width: 350px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    .line {
      position: absolute;
      width: 330px;
      height: 14px;
      box-sizing: border-box;
      z-index: -1;
      margin-left: 10px;
      background: ${({ theme }) => theme.COLORS.primary10010};
    }
  }

  svg {
    height: 24px;
    fill: ${({ theme }) => theme.COLORS.primary100};
  }

  .long-arrow {
    fill: ${({ theme }) => theme.COLORS.primary10020};
  }

  button,
  .start-word {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    width: 54px;
    height: 54px;
    margin: 0px 4px;
    border-radius: 9999%;
    border: none;
    background-color: ${({ theme }) => theme.COLORS.primary10020};
    cursor: pointer;
    transition: 0.5s all ease;
    outline: none;
    box-shadow: 0px 0px 0px 2px ${({ theme }) => theme.COLORS.primary10020};
    &:hover {
      background-color: ${({ theme }) => theme.COLORS.primary10040};
      box-shadow: 0px 0px 0px 2px ${({ theme }) => theme.COLORS.primary10040};
    }
    &:focus {
      background-color: ${({ theme }) => theme.COLORS.primary10020};
      box-shadow: 0px 0px 0px 2px ${({ theme }) => theme.COLORS.primary100};
    }
  }

  .status-and-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    span {
      margin-bottom: 5px;
      position: absolute;
      top: -22px;
    }
  }
`;

const ArrowAndButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  float: left;
  .arrow-and-date {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .date {
      font-size: 14px;
      font-family: "Montserrat", sans-serif;
      color: ${({ theme }) => theme.COLORS.primary};
      margin-left: 10px;
      margin-top: -8px;
    }
  }
`;

export default function addRemoveItems(itemState, selectedItems) {
  let newSelectedItems = [];
  if (selectedItems.length === 0 || itemState.selected) {
    newSelectedItems = [...selectedItems];
    newSelectedItems.push({ ...itemState, quantity: "" });
  } else if (!itemState.selected) {
    newSelectedItems = selectedItems.filter((el) => el.id !== itemState.id);
  }
  return newSelectedItems;
}

export const toggleItemOff = (itemtoToggle, itemsList) => {
  return itemsList.map((item) => {
    if (item.id === itemtoToggle.id) {
      return { ...item, selected: false };
    }
    return item;
  });
};

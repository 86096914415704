import { motion } from "framer-motion";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import styled from "styled-components";
import { fetchRequestCountByStatus } from "utils/requests";
import {
  calculateMax,
  calculateTotal,
  getPercentageFromMax,
} from "utils/totalAndMaxOfOrdersCount";

export default function OrdersCountGraph() {
  const [total, setTotal] = useState(null);
  const [max, setMax] = useState(null);
  const { t } = useTranslation();

  const countReq = useQuery("Count", fetchRequestCountByStatus, {
    onSuccess: (data) => {
      setTotal(calculateTotal(data));
      setMax(calculateMax(data));
    },
  });
  return (
    <Wrapper className="orders-graph">
      <h3 className="header-text">
        {t("Total")} {total}
      </h3>
      <>
        <StatusBar
          status="Draft"
          index={0}
          value={countReq?.data?.draft}
          percentage={getPercentageFromMax(countReq?.data?.draft, max)}
        />
        <StatusBar
          status="Pending"
          index={1}
          value={countReq?.data?.pending}
          percentage={getPercentageFromMax(countReq?.data?.pending, max)}
        />
        <StatusBar
          status="Submitted"
          index={2}
          value={countReq?.data?.submitted}
          percentage={getPercentageFromMax(countReq?.data?.submitted, max)}
        />
        <StatusBar
          status="Approved"
          index={3}
          value={countReq?.data?.approved}
          percentage={getPercentageFromMax(countReq?.data?.approved, max)}
        />
        <StatusBar
          status="Picklisted"
          index={4}
          value={countReq?.data?.pickListed}
          percentage={getPercentageFromMax(countReq?.data?.pickListed, max)}
        />
        <StatusBar
          status="Rejected"
          index={5}
          value={countReq?.data?.rejected}
          percentage={getPercentageFromMax(countReq?.data?.rejected, max)}
        />
      </>
    </Wrapper>
  );
}

const StatusBar = ({ status, percentage, value, index }) => {
  const { t } = useTranslation();

  return (
    <div className="bar-and-label">
      <div className="status-text">
        {t(status)} <span>{value}</span>
      </div>
      <div className="status-bar-container">
        <div className="bar-background" />
        {value ? (
          <BarValue
            percentage={percentage}
            initial={{ width: 0 }}
            animate={{ width: `${percentage}%` }}
            transition={{ duration: 1, type: "spring", delay: index / 4 }}
          />
        ) : null}
      </div>
      <span className="value-text">{value}</span>
    </div>
  );
};

const BarValue = styled(motion.div)`
  width: ${({ percentage }) => percentage}%;
  height: 23px;
  border-radius: 20px;
  border-top-right-radius: ${({ percentage }) =>
    percentage > 98 ? "20px" : "0px"};
  border-bottom-right-radius: ${({ percentage }) =>
    percentage > 98 ? "20px" : "0px"};
  background-color: ${({ theme }) => theme.COLORS.primary100};
  position: absolute;
  top: 0px;
  box-shadow: ${({ theme }) => theme.bar.boxShadow};
`;

const Wrapper = styled.div`
  .bar-and-label {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 20px;
    padding-right: 10px;
    box-sizing: border-box;
    .status-text {
      width: 100px;
      text-align: right;
      display: flex;
      white-space: nowrap;
      span {
        display: none;
        margin-left: 10px;
      }
    }
    .status-bar-container {
      width: 90%;
      margin-left: 20px;
      position: relative;
      .bar-background {
        width: 100%;
        height: 23px;
        border-radius: 20px;
        background-color: ${({ theme }) => theme.COLORS.primary10010};
      }
    }
  }

  @media screen and (max-width: 600px) {
    .bar-and-label {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding-right: 0px;
      .status-text {
        width: 100px;
        text-align: left;
        margin-bottom: 5px;
        span {
          display: block;
        }
      }
      .status-bar-container {
        width: 100%;
        margin-left: 0px;
      }
      .value-text {
        display: none;
      }
    }
  }
`;

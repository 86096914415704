// useDetectColorScheme.js
import { useState, useEffect } from "react";

// Define available themes
export const colorSchemes = {
  DARK: "(prefers-color-scheme: dark)",
  LIGHT: "(prefers-color-scheme: light)",
};

export default function useDetectColorScheme(defaultScheme = "light") {
  // Hook state
  const [scheme, setScheme] = useState(defaultScheme);

  useEffect(() => {
    // No support for detection
    if (!window.matchMedia) {
      return;
    }

    // The listener
    const listener = (e) => {
      // No match = not interesting
      if (!e || !e.matches) {
        return;
      }

      // Look for the matching color scheme
      // and update the hook state
      const schemeNames = Object.keys(colorSchemes);
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < schemeNames.length; i++) {
        const schemeName = schemeNames[i];
        if (e.media === colorSchemes[schemeName]) {
          setScheme(schemeName.toLowerCase());
          break;
        }
      }
    };

    // Loop through and setup listeners for the
    // media queries we want to monitor
    let activeMatches = [];
    Object.keys(colorSchemes).forEach((schemeName) => {
      const mq = window.matchMedia(colorSchemes[schemeName]);
      mq.addListener(listener);
      activeMatches.push(mq);
      listener(mq);
    });

    // Remove listeners, no memory leaks
    // eslint-disable-next-line consistent-return
    return () => {
      activeMatches.forEach((mq) => mq.removeListener(listener));
      activeMatches = [];
    };
    // Run on first load of hook only
  }, []);

  // Return the current scheme from state
  return scheme;
}

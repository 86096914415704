const { css } = require("styled-components");

const notificationButton = css`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  margin-left: 10px;
  min-width: 38px;
  min-height: 38px;
  border-radius: 9999%;
  border: none;
  background-color: ${({ theme }) => theme.COLORS.primary10020};
  cursor: pointer;
  transition: 0.5s all ease;
  outline: none;
  box-shadow: 0px 0px 0px 2px ${({ theme }) => theme.COLORS.primary10020};
  &:hover {
    background-color: ${({ theme }) => theme.COLORS.primary10040};
    box-shadow: 0px 0px 0px 2px ${({ theme }) => theme.COLORS.primary10040};
  }
  &:focus {
    background-color: ${({ theme }) => theme.COLORS.primary10020};
    box-shadow: 0px 0px 0px 2px ${({ theme }) => theme.COLORS.primary100};
  }
  svg {
    fill: ${({ theme }) => theme.COLORS.primary100};
    width: 20px;
  }
`;

export default notificationButton;

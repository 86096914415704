import { motion } from "framer-motion";
import styled from "styled-components";

export default function EmptyState({ message, mode, flexDirection }) {
  const emptyVariants = {
    hidden: { opacity: 0, y: 0, scale: 0.5 },
    visible: { opacity: 1, y: 0, scale: 1 },
  };
  return (
    <Wrapper
      initial="hidden"
      animate="visible"
      exit="exit"
      variants={emptyVariants}
      transition={{ duration: 0.7 }}
      mode={mode}
      flexDirection={flexDirection}
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 174.81 170.636">
        <g transform="translate(-.001 -.004)">
          <path
            data-name="Path 722"
            d="M110.978 38.378h-47.1a4.054 4.054 0 00-4.049 4.049v104.689l-.54.165-11.555 3.539a2.161 2.161 0 01-2.7-1.433L10.662 37.112a2.16 2.16 0 011.433-2.7l17.807-5.452 51.622-15.8 17.807-5.452a2.156 2.156 0 012.7 1.431l8.786 28.7z"
            className="pageFill"
          />
          <path
            data-name="Path 723"
            d="M121.256 37.839L110.667 3.251a4.587 4.587 0 00-5.73-3.045L79.902 7.869l-51.62 15.8-25.035 7.666a4.593 4.593 0 00-3.045 5.73l36.191 118.206a4.6 4.6 0 004.389 3.247 4.532 4.532 0 001.341-.2l17.162-5.253.54-.167v-.564l-.54.165-17.321 5.3a4.053 4.053 0 01-5.056-2.686L.718 36.91a4.043 4.043 0 012.686-5.056l25.035-7.666 51.62-15.8L105.094.722a4.045 4.045 0 015.056 2.686l10.54 34.431.167.54h.561z"
            className="svgFill"
          />
          <path
            data-name="Path 724"
            d="M33.115 34.504a2.434 2.434 0 01-2.324-1.719l-3.477-11.356a2.429 2.429 0 011.612-3.034l47.49-14.539a2.432 2.432 0 013.034 1.612l3.477 11.356a2.432 2.432 0 01-1.612 3.034l-47.49 14.539a2.423 2.423 0 01-.71.107z"
            className="svgFill"
          />
          <circle
            data-name="Ellipse 42"
            cx="5.398"
            cy="5.398"
            r="5.398"
            transform="translate(45.929 1.34)"
            className="svgFill"
          />
          <circle
            data-name="Ellipse 43"
            cx="3.418"
            cy="3.418"
            r="3.418"
            transform="translate(47.909 3.32)"
            fill="#fff"
          />
          <path
            data-name="Path 725"
            d="M162.665 157.144H71.432a2.3 2.3 0 01-2.294-2.294V45.531a2.3 2.3 0 012.294-2.294h91.233a2.3 2.3 0 012.294 2.294V154.85a2.3 2.3 0 01-2.294 2.294z"
            className="pageFill"
          />
          <path
            data-name="Path 726"
            d="M120.692 37.839H63.874a4.6 4.6 0 00-4.589 4.589v110.073l.54-.165V42.428a4.054 4.054 0 014.049-4.049h56.986zm49.531 0H63.874a4.6 4.6 0 00-4.589 4.589v123.624a4.6 4.6 0 004.589 4.589h106.349a4.6 4.6 0 004.589-4.589V42.428a4.6 4.6 0 00-4.589-4.589zm4.044 128.213a4.054 4.054 0 01-4.049 4.049H63.874a4.054 4.054 0 01-4.049-4.049V42.428a4.054 4.054 0 014.049-4.049h106.349a4.054 4.054 0 014.049 4.049z"
            className="svgFill"
          />
          <path
            data-name="Path 727"
            d="M141.881 49.715H92.216a2.432 2.432 0 01-2.429-2.429V35.409a2.432 2.432 0 012.429-2.429h49.665a2.432 2.432 0 012.429 2.429v11.877a2.432 2.432 0 01-2.429 2.429z"
            className="svgFill"
          />
          <circle
            data-name="Ellipse 44"
            cx="5.398"
            cy="5.398"
            r="5.398"
            transform="translate(111.65 22.993)"
            className="svgFill"
          />
          <circle
            data-name="Ellipse 45"
            cx="3.288"
            cy="3.288"
            r="3.288"
            transform="translate(113.76 25.103)"
            fill="#fff"
          />
        </g>
      </svg>
      <p>{message || "No Data"}</p>
    </Wrapper>
  );
}

const Wrapper = styled(motion.div)`
  display: flex;
  flex-direction: ${(p) => p.flexDirection || "column"};
  align-items: center;
  width: 100%;
  margin-top: ${({ mode }) => (mode === "small" ? "0" : "20%")};
  svg {
    width: ${({ mode }) => (mode === "small" ? "100px" : "175px")};
    margin-bottom: 15px;
  }
  .svgFill {
    fill: ${({ theme }) => theme.emptyState.fill};
  }
  .pageFill {
    fill: ${({ theme }) => theme.emptyState.pageFill};
  }
  p {
    text-align: center;
    font-family: ${({ mode }) => mode !== "small" && "Fredoka One"}, cursive;
    font-size: ${({ mode }) => (mode === "small" ? "16px" : "20px")};
    color: ${({ theme }) => theme.COLORS.primary100};
    margin: ${({ mode }) => (mode === "small" ? "0px 0px 0px 10px" : "0px")};
    letter-spacing: 1px;
  }
`;

export const caregorizedOrdersList = [
  { type: "Draft", orders: [], viewAll: false },
  { type: "Pending", orders: [], viewAll: false },
  { type: "Submitted", orders: [], viewAll: false },
  { type: "Approved", orders: [], viewAll: false },
  { type: "PickListed", orders: [], viewAll: false },
  { type: "Rejected", orders: [], viewAll: false },
];
export default function categorizeOrdersByType(allOrders) {
  const newCategorizedOrders = [
    { type: "Draft", orders: [], viewAll: false },
    { type: "Pending", orders: [], viewAll: false },
    { type: "Submitted", orders: [], viewAll: false },
    { type: "Approved", orders: [], viewAll: false },
    { type: "PickListed", orders: [], viewAll: false },
    { type: "Rejected", orders: [], viewAll: false },
  ];

  const insertOrderToOrder = (index, order) => {
    if (newCategorizedOrders[index].orders.length < 5) {
      newCategorizedOrders[index].orders.push(order);
    } else {
      newCategorizedOrders[index].viewAll = true;
    }
  };
  allOrders.forEach((order) => {
    switch (order.requestStatus) {
      case "Draft":
        insertOrderToOrder(0, order);
        break;
      case "Pending":
        insertOrderToOrder(1, order);
        break;
      case "Submitted":
        insertOrderToOrder(2, order);
        break;
      case "Approved":
        insertOrderToOrder(3, order);
        break;
      case "PickListed":
        insertOrderToOrder(4, order);
        break;
      case "Rejected":
        insertOrderToOrder(5, order);
        break;
      default:
        break;
    }
  });

  return newCategorizedOrders;
}

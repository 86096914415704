import { ReactComponent as Pending } from "resources/pending.svg";
import { ReactComponent as Submitted } from "resources/submitted.svg";
import { ReactComponent as Approved } from "resources/approved.svg";
import { ReactComponent as Drafted } from "resources/drafted.svg";
import { ReactComponent as Rejected } from "resources/rejected.svg";
import { ReactComponent as Plus } from "resources/plus.svg";
import { ReactComponent as Picklist } from "resources/picklist.svg";
import { ReactComponent as Back } from "resources/back.svg";
import { ReactComponent as Download } from "resources/download.svg";
import { ReactComponent as Notification } from "resources/notification.svg";
import { ReactComponent as SignOut } from "resources/signOut.svg";
import { ReactComponent as SunAndMoon } from "resources/sunAndMoon.svg";
import { ReactComponent as Language } from "resources/language.svg";
import { ReactComponent as Menu } from "resources/menu.svg";
import { ReactComponent as Close } from "resources/close.svg";
import { ReactComponent as About } from "resources/about.svg";
import { ReactComponent as Delete } from "resources/delete.svg";
import { ReactComponent as LongArrow } from "resources/longArrow.svg";
import { ReactComponent as Edit } from "resources/edit.svg";
import { ReactComponent as Pharmacy } from "resources/pharmacy.svg";
import { ReactComponent as Stockout } from "resources/stockout.svg";
import { ReactComponent as Search } from "resources/search.svg";
import { ReactComponent as ChevronDown } from "resources/chevron-down.svg";
import { ReactComponent as PasswordHidden } from "resources/passwordHidden.svg";
import { ReactComponent as PasswordShown } from "resources/passwordShown.svg";
import { ReactComponent as ResetPassword } from "resources/resetPassword.svg";
import { ReactComponent as ReduceDollar } from "resources/reduce-dollar.svg";
import { ReactComponent as FacilityReport } from "resources/facilityReport.svg";


export default {
  Pending,
  Submitted,
  Approved,
  Drafted,
  Rejected,
  Plus,
  Picklist,
  Back,
  Download,
  Notification,
  SignOut,
  SunAndMoon,
  Language,
  Menu,
  Close,
  About,
  Delete,
  LongArrow,
  Edit,
  Pharmacy,
  Stockout,
  Search,
  ChevronDown,
  PasswordHidden,
  PasswordShown,
  ResetPassword,
  ReduceDollar,
  FacilityReport
};

import styled from "styled-components";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { formElementWrapper } from "Landing/LoginAndSignUp/LoginAndSignup.styles";

export default function VerificationCode({
  signInInfo,
  setVerificationCodeData,
}) {
  const { t } = useTranslation();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const formikRef = useRef();

  useEffect(() => {
    if ("OTPCredential" in window) {
      const abrtOTPCredential = new AbortController();
      if (formSubmitted) {
        // Cancel the Web OTP API if form is submitted.
        abrtOTPCredential.abort();
      }

      // Invoke the Web OTP API
      navigator.credentials
        .get({
          otp: { transport: ["sms"] },
          signal: abrtOTPCredential.signal,
        })
        .then((otp) => {
          // Automatically submit the form when an OTP is obtained.

          setTimeout(() => {
            formikRef.current.setFieldValue("verficationCode", otp.code);
            setVerificationCodeData(otp.code);
          }, 1000);
        });
    }
  }, []);

  return (
    <Wrapper>
      <h1>Verification Code Sent</h1>
      <p>
        Enter the verification code sent to your number (
        {signInInfo?.phoneNumber})
      </p>
      <Formik
        initialValues={{
          verficationCode: "",
        }}
        validationSchema={Yup.object({
          verficationCode: Yup.string().required("Required"),
        })}
        onSubmit={(formData) => {
          setFormSubmitted(true);
          setVerificationCodeData(formData.verficationCode);
        }}
        innerRef={formikRef}
      >
        {(props) => (
          <Form onSubmit={props.handleSubmit}>
            <div className="form-element-wrapper spacing">
              <label htmlFor="verficationCode">{t("Verification Code")}</label>
              <input
                type="text"
                inputMode="numeric"
                autoComplete="one-time-code"
                onChange={props.handleChange}
                onBlur={(e) => {
                  props.handleBlur(e);
                }}
                name="verficationCode"
                value={props.values.verficationCode}
              />
              {props.errors.verficationCode &&
                props.touched.verficationCode && (
                  <div className="error">{props.errors.verficationCode}</div>
                )}
            </div>

            <div className="spacing full-width">
              <div>
                <button type="submit" className="blue-button">
                  Next
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Wrapper>
  );
}
const Wrapper = styled.div`
  width: 100%;
  height: auto;
  background-image: ${({ theme }) => theme.LANDING.card.backgroundImage};
  box-shadow: ${({ theme }) => theme.LANDING.boxShadow};
  border: 2px solid ${({ theme }) => theme.LANDING.card.border};
  border-radius: 30px;
  padding: 5%;
  box-sizing: border-box;
  min-height: 400px;
  h1 {
    color: ${({ theme }) => theme.COLORS.primary100};
    font-family: "Fredoka One", cursive;
    font-size: ${({ language }) => (language === "am" ? 20 / 1.2 : 20)}px;
    width: 100%;
    text-align: center;
    margin: 0;
    padding-bottom: 10px;
    border-bottom: 1px dashed ${({ theme }) => theme.COLORS.primary10020};
  }
  p {
    width: 100%;
    text-align: center;
    color: ${({ theme }) => theme.COLORS.primary};
  }

  .form-element-wrapper {
    ${formElementWrapper}
    justify-content: center;
    align-items: center;
    input {
      width: 100px;
      font-size: 20px;
      margin-top: 30px;
      letter-spacing: 8px;
      text-align: center;
    }
    label {
      width: 170px;
      margin-bottom: 20px;
    }
  }

  form {
    width: 100%;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .full-width {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
`;

import { selectors } from "configureStore";
import { motion } from "framer-motion";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styled from "styled-components";
import Constants from "utils/constants";
import Login from "./Login";
import Signup from "./Signup";
import VerificationCode from "../../ForgotPassword/VerificationCode";

export default function LoginAndSignUp({userRole}) {
  const [activeTab, setActiveTab] = useState("login");
  const { t } = useTranslation();
  const app = useSelector(selectors.app);
  const [showVerificationCode, setShowVerificationCode] = useState(false);
  const [signInInfo, setSignInInfo] = useState(null);

  return (
    <Wrapper>
      <div className="tabs-wrapper">
        <section className="tabs">
          <TabItem
            className="tab-item"
            onClick={() => setActiveTab("login")}
            isActive={activeTab === "login"}
            language={app.language}
          >
            <p>{t("Log in")}</p>
          </TabItem>
          {(userRole===Constants.buyer) && (<TabItem
            className="tab-item"
            onClick={() => setActiveTab("signup")}
            isActive={activeTab === "signup"}
            language={app.language}
          >
            <p>{t("Sign Up")}</p>
          </TabItem>)}
        </section>
        <motion.div
          className="tab-line"
          animate={{ x: activeTab === "signup" ? 94 : 0 }}
          transition={{ duration: 0.5, ease: "easeOut" }}
        />
      </div>
      {activeTab === "login" && <Login signInInfo={signInInfo} userRole={userRole}/>}
      {activeTab === "signup" && showVerificationCode && (
        <VerificationCode signInInfo={signInInfo} />
      )}
      {activeTab === "signup" && !showVerificationCode && (
        <Signup
          setSignInInfo={setSignInInfo}
          setActiveTab={setActiveTab}
          setShowVerificationCode={setShowVerificationCode}
        />
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .tabs {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .tabs-wrapper {
    width: fit-content;
    position: relative;
  }
  .tab-line {
    width: 40px;
    height: 6px;
    border-radius: 10px;
    background-color: ${({ theme }) => theme.COLORS.primary100};
    position: absolute;
    bottom: 10px;
    left: 22px;
  }

  @media screen and (max-width: 1025px) {
    width: 70%;
  }

  @media screen and (max-width: 790px) {
    width: 90%;
  }

  @media screen and (max-width: 540px) {
    width: 96%;
  }
`;

const TabItem = styled.div`
  padding: 15px;
  color: ${({ isActive, theme }) =>
    isActive ? theme.COLORS.primary100 : theme.COLORS.primary10029};
  font-family: "Fredoka One", cursive;
  font-size: ${({ language }) => (language === "am" ? 20 / 1.2 : 20)}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: 0.5s all ease;
  p {
    margin-bottom: 5px;
  }
  cursor: pointer;
`;

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { ShimmerEffect } from "SharedComponents/ShimmerEffect";
import styled from "styled-components";
import dateFormatter from "utils/dateFormatter";
import { commaSeparated } from "utils/formatNumber";
import PrintItem from "./PrintItem";

export default function ItemHeader({ order, items, stockedOutItems }) {
  const [totalQuantity, setTotalQuantity] = useState(null);
  const [totalPrice, setTotalPrice] = useState(null);
  const { t } = useTranslation();
  const { pathname } = useLocation();

  useEffect(() => {
    if (items?.length > 0) {
      let quantity = 0;
      let price = 0;
      items.forEach((el) => {
        if (el.quantity || el.approvedQuantity) {
          quantity += el.quantity || el.approvedQuantity;
        }
        if (el.price) {
          price += el.price;
        }
      });
      setTotalQuantity(quantity);
      if (price) {
        setTotalPrice(price);
      }
    } else if (items?.length === 0) {
      setTotalQuantity(0);
    }
  }, [items]);

  return (
    <Wrapper>
      <section className="date">
        {items?.length > 0 &&
          (order.requestStatus === "Picklisted" ||
            order.requestStatus === "Pending") && (
            <PrintItem order={order} items={items} type="order" />
          )}
        {stockedOutItems?.length > 0 &&
          (order.requestStatus === "Approved" ||
            pathname === "/stockedouts") && (
            <PrintItem
              order={order}
              items={stockedOutItems}
              message={t("Print Stockout")}
              pdfTitle="Stockout Report"
              type="stockout"
            />
          )}
        {dateFormatter.yearMonthDate(order.modifiedDate)}
      </section>
      <section>
        <p className="list-label">{t("Order Number")}</p>{" "}
        <p className="list-content">{order.requestNo}</p>
      </section>
      <section>
        <p className="list-label">{t("Items")}</p>{" "}
        <p className="list-content">{order.numberOfRequestedItems}</p>
      </section>
      <section>
        <p className="list-label">{t("Mode")}</p>{" "}
        <p className="list-content">{order.mode}</p>
      </section>
      <section>
        <p className="list-label">{t("Payment Type")}</p>{" "}
        <p className="list-content">{order.paymentType}</p>
      </section>
      <section>
        <p className="list-label">{t("Total Quantity")}</p>{" "}
        {totalQuantity && <p className="list-content">{totalQuantity}</p>}
        {!totalQuantity && !items && (
          <ShimmerEffect
            borderRadius="5px"
            width="70px"
            height="18px"
            marginTop="5px"
          />
        )}
      </section>
      {typeof totalPrice === "number" && (
        <section>
          <p className="list-label">{t("ETB Total Price")}</p>{" "}
          <p className="list-content">
            {commaSeparated(+totalPrice.toFixed(2))}
          </p>
        </section>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-bottom: 1px dashed #70245930;
  margin: 0px 20px 5px 20px;
`;

const FILTER_TYPES_MAP = {
  pending: "Pending",
  submitted: "Submitted",
  draft: "Draft",
  rejected: "Rejected",
  approved: "Approved",
  picklisted: "PickListed",
};

export default function filterRequestsByType(filterType, allRequests) {
  if (!filterType) {
    return allRequests;
  }
  return allRequests.filter((el) => {
    return el.requestStatus === FILTER_TYPES_MAP[filterType];
  });
}

import { compareDesc } from "date-fns";
import parseISO from "date-fns/parseISO";

export default function getRecentlyAddedAndUpdated(allOrders) {
  const ordersToBeSorted = [...allOrders];
  const sorted = ordersToBeSorted.sort((a, b) =>
    compareDesc(parseISO(a.modifiedDate), parseISO(b.modifiedDate))
  );
  sorted.length = 5;
  return sorted;
}

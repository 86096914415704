import { format, parseISO } from "date-fns";

export default {
  yearMonthDate: (dateToBeFormatted) => {
    if (!dateToBeFormatted) return "";
    return format(parseISO(dateToBeFormatted), "MMM dd, yyyy");
  },
};

export const getProperDate = (date) => {
  if (!date) return new Date().toDateString();
  return new Date(date).toDateString();
};

export const getQrDate = (date) => {
  return date ? date.slice(0, 10) : "";
};

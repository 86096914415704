export default {
  "Order Online.": "በኢንተርኔት ማዘዣ!",
  "Ethiopian Pharmaceutical Supply Service": "የኢትዮጵያ መድኃኒት አቅራቢ አገልግሎት",
  "Its Easy, Simple and Time saving": "ቀላልና ጊዜ ቆጣቢ",
  "Process your product request forms online":
    "በኢንተርኔት በቀጥታ የሞሉትን ቅጽ ሂደት ይከታተሉ",
  "Submit your requests to EPSS and follow up on the status":
    "የጥያቄ ቅጽ መሙላትና መከታተል",
  "Preview previous requests": "የተሞሉ እና ያለፉ ቅፆችን ለማግኘት",
  "Show": "አሳይ",
  "View available products": "የሚገኙ ምርቶችን ይዩ",
  "View new arrived items": "አዲስ የገቡ ምርቶችን ይዩ",
  "Log in": "መግቢያ",
  "Sign Up": "መመዝገቢያ",
  "Select Hub": "ቅርንጫፍ ምረጥ",
  "TIN Number": "ቲን ቁጥር",
  Password: "የይለፍ ቃል",
  "Loading Institution ...": "ተቋም በመፈለግ ላይ ...",
  "Forgot Password?": "የይለፍ ቃልዎን ረስተዋል?",
  "Forgot your password? We can help! A four digit verification code will be sent to your phone number.":"የይለፍ ቃልዎን ረስተዋል? ልንረዳዎት እንችላለን::ባለ 4 አሃዝ የሚስጥር ቁጥር ይላክልዎታል::",
  "First Name": "ስም",
  "Last Name": "የአባት ስም",
  "Phone Number": "ስልክ ቁጥር",
  "Telegram Phone Number": "የቴሌግራም ስልክ ቁጥር",
  "Same As Phone Number": "ከስልክ ቁጥር ጋር አንድ አይነት ነው",
  "Just a moment...": "ትንሽ ይጠብቁን...",
  EPSA: "EPSS",
  Settings: "ምርጫ",
  "Display Settings": "እይታ መምረጫ",
  "Sign Out": "መውጭያ",
  "New Order": "አዲስ ትዕዛዝ",
  Draft: "ረቂቅ",
  Pending: "በመጠባበቅ ላይ",
  Submitted: "የገቡ",
  Approved: "የፀደቁ",
  Picklisted: "የተመረጡ",
  PickListed: "የተመረጡ",
  Rejected: "ውድቅ የተደረጉ",
  About: "ስለ",
  "Order Number": "የትዕዛዝ ቁጥር",
  Items: "ዕቃዎች",
  Mode: "ሞድ",
  "Payment Type": "የክፍያ መንገድ",
  "Total Quantity": "ብዛት",
  "ETB Total Price": "ዋጋ በብር",
  "Items Requested": "የተጠየቁ ዕቃዎች",
  "Stockedout Items": "ከገበያ ዉጪ ዕቃዎች",
  Item: "ዕቃ ስም",
  Unit: "አሃድ",
  Quantity: "ብዛት",
  "Unit Price ETB": "የአንዱ ዋጋ",
  "Total Price ETB": "ድምር ዋጋ",
  Print: "እትም",
  "Print Stockout": "ያልተገኙ እቃዎችን አትም",
  "Loading Items...": "እቃዎችን በመፈለግ ላይ...",
  "Select items for order": "ዕቃዎችን ይምረጡ",
  "Get items from last request": "ካለፈው ትእዛዝ አስገባ",
  "Select Items": "ዕቃዎች መምረጫ",
  "Select Payment Type": "የክፍያ መንገድ መምረጫ",
  Invalid: "ያልተፈቀደ",
  "Filter items by type": "እቃዎችን በአይነት መለያ",
  "Finish Selection": "ጨርስ",
  "Add Items": "ዕቃዎች ጨምር",
  "Save As Draft": "በረቂቅ ይቀመጥ",
  "Submit Order": "ትእዛዙ ይላክ",
  Edit: "አዘምን",
  Delete: "አስወግድ",
  Total: "ድምር",
  "Recently added/updated": "ሰሞኑን የተጨምሩ/የተስተካከሉ",
  "View All": "ሁሉንም ልመልከት",
  "Facility Name": "የተቋም ስም",
  Id: "መታወቂያ",
  Hub: "ቅርንጫፍ",
  "Region, Zone, Wereda": "ክልል, ዞን, ወረዳ",
  "Facility Info": "ስለ ተቋሙ መረጃ",
  "Search by Date": "በቀን ለመፈለግ",
  "Search by Order Number": "በትዕዛዝ ቁጥር ለመፈለግ",
  "Search By": "መፈለጊያ ምርጫ",
  Date: "ቀን",
  Recents: "በቅርቡ የተመረጡ",
  "New/Updated": "አዲስ/የተስተካከሉ",
  "No match found": "ተመሳሳይ አልተገኘም",
  Username: "የተጠቃሚ ስም",
  "Select Facility": "ተቋም መምረጫ",
  "Reset Password":"የይለፍ ቃል ለመቀየር",
  "Old Password":"የአሁኑ ይለፍ ቃል",
  "New Password":"አዲስ ይለፍ ቃል",
  "Confirm Password":"የይለፍ ቃል ድገም",
  "Reset":"ቀይር",
  "Status Report":"የሁኔታ መረጃ",
  "Facility Report":"የጣቢያ መረጃ",
  "User Management":"የተጠቃሚ ቁጥጥር",
  "Enter Order NO":"የትእዛዝ ቁጥር ያስገቡ",
  "Enter Facility ID":"የጣቢያ መለያ ያስገቡ",
  "Filter By Hub":"በ ቅርንጫፍ ለማጣራት",
  "Filter By Date":"በቀን ለማጣራት",
  "Apply Filter":"ያጣሩ"
};

export default function populateWithDataFromStorage(
  itemsList,
  itemsListFromStorage,
  itemsOnUpdateMode
) {
  const storageItemIds = itemsListFromStorage.map((el) =>
    itemsOnUpdateMode ? el.itemUnitId : el.id
  );
  return itemsList.map((item) => ({
    ...item,
    selected: !!storageItemIds.includes(item.id),
  }));
}

import { appActions } from "app.state";
import EmptyState from "components/EmptyState";
import OrderDate from "components/OrderDate";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import styled, { css } from "styled-components";

export default function SearchResultList({
  searchResult,
  setShowSearchResult,
  searchQuery,
  recentlyAddedUpdated,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [recent, setRecent] = useState([]);
  const [showRecents, setShowRecents] = useState(false);
  const [recentType, setRecentType] = useState("recents");

  useEffect(() => {
    const recentFromStorage = JSON.parse(localStorage.getItem("recent")) || [];
    setRecent([...recentFromStorage]);
    recentFromStorage.length > 0
      ? setRecentType("recents")
      : setRecentType("new/updated");
  }, []);

  useEffect(() => {
    if (searchResult.length === 0) {
      setShowRecents(true);
    } else {
      setShowRecents(false);
    }
  }, [searchResult]);

  const setRecentToData = (selectedArticle) => {
    let matchFound = false;
    let matchIndex;
    const recentToStorage = [...recent];
    recent.forEach((el, index) => {
      if (el.id === selectedArticle.id) {
        matchFound = true;
        matchIndex = index;
      }
    });
    if (matchFound) {
      recentToStorage.splice(matchIndex, 1);
    } else {
      recent.length >= 5 && recentToStorage.splice(-1, 1);
    }

    recentToStorage.unshift(selectedArticle);
    localStorage.setItem("recent", JSON.stringify(recentToStorage));
    setRecent([...recentToStorage]);
  };

  const onOrderSelect = (order, isOrderRecentList = false) => {
    !isOrderRecentList ? setRecentToData(order) : null;
    dispatch(
      appActions.showRequestDetailModal({
        selectedRequest: order.id,
        selectedRequestData: order,
      })
    );
    setShowSearchResult(false);
  };

  return (
    <Wrapper
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.7 }}
    >
      {searchResult.length === 0 && searchQuery && (
        <div className="empty-state-wrapper">
          {" "}
          <EmptyState
            message={t("No match found")}
            mode="small"
            flexDirection="column"
          />
        </div>
      )}
      {showRecents && !searchQuery ? (
        <div>
          <section className="recents-title">
            <button
              type="button"
              className={
                recentType === "recents" ? "active-menu" : "inactive-menu"
              }
              onClick={() => {
                setRecentType("recents");
              }}
            >
              {t("Recents")}
            </button>
            <button
              type="button"
              className={
                recentType === "new/updated" ? "active-menu" : "inactive-menu"
              }
              onClick={() => {
                setRecentType("new/updated");
              }}
            >
              {t("New/Updated")}
            </button>
          </section>
          <ul>
            {recentType === "recents" &&
              recent.length > 0 &&
              recent.map((el, index) => (
                <SearchListItem
                  key={el.id}
                  el={el}
                  index={index}
                  searchResult={recent}
                  onOrderSelect={onOrderSelect}
                  t={t}
                />
              ))}
          </ul>
          <ul>
            {recentType === "new/updated" &&
              recentlyAddedUpdated.length > 0 &&
              recentlyAddedUpdated.map((el, index) => (
                <SearchListItem
                  key={el.id}
                  el={el}
                  index={index}
                  searchResult={recent}
                  onOrderSelect={onOrderSelect}
                  t={t}
                />
              ))}
          </ul>
        </div>
      ) : (
        <ul>
          {searchResult.length > 0 &&
            searchResult.map((el, index) => (
              <SearchListItem
                key={el.id}
                el={el}
                index={index}
                searchResult={searchResult}
                onOrderSelect={onOrderSelect}
                t={t}
              />
            ))}
        </ul>
      )}
    </Wrapper>
  );
}

const SearchListItem = ({ el, index, searchResult, onOrderSelect, t }) => (
  <li
    key={el.id}
    className={index === searchResult.length - 1 ? "last-item" : ""}
    onClick={() => {
      onOrderSelect(el);
    }}
  >
    <section className="title-and-value">
      <h4>{t("Order Number")}</h4>
      <p>{el.requestNo}</p>
    </section>
    <OrderDate
      unformattedDate={el.createdDate}
      customStyles={css`
        font-size: 16px;
      `}
    />
  </li>
);

const Wrapper = styled(motion.div)`
  width: 100%;
  position: absolute;
  background-color: ${(p) => p.theme.searchDropDown.backgroundColor};
  max-width: 565px;
  width: 100%;
  border-radius: 8px;
  box-shadow: ${(p) => p.theme.searchDropDown.boxShadow};
  box-sizing: border-box;
  z-index: 9999;
  list-style-type: none;
  top: 50px;
  border: 1px solid ${(p) => p.theme.COLORS.primary10020};
  ul {
    padding: 0;
    margin: 0;
  }
  .empty-state-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 0px;
    svg {
      height: 150px !important;
    }
  }

  .last-item {
    border-bottom: none;
  }

  .title-and-value {
    margin-right: 30px;
  }

  li {
    width: 100%;
    border-bottom: 1px dashed ${({ theme }) => theme.COLORS.primary10020};
    padding: 7px 10px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: 0.5s all ease;
    h4,
    p {
      margin: 0px;
    }

    h4 {
      color: ${({ theme }) => theme.COLORS.primary10096};
      margin-bottom: 3px;
      font-weight: 400 !important;
      font-size: 14px;
    }

    p {
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 1px;
    }

    &:hover {
      background-color: ${(p) => p.theme.COLORS.primary10010};
    }
  }

  .recents-title {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    .active-menu {
      opacity: 1;
    }
    .inactive-menu {
      opacity: 0.5;
    }
    button {
      margin: 10px 10px;
      font-family: "Fredoka One", cursive;
      color: ${(p) => p.theme.COLORS.primary100};
      letter-spacing: 1px;
      background: transparent;
      border: none;
      outline: none;
      font-size: 18px;
    }
  }

  @media screen and (max-width: 950px) {
    background: transparent;
    position: relative;
    top: 15px;
    border: none;
    box-shadow: none;
    width: 100%;
    max-width: 100%;
    z-index: 1;
    .empty-state-wrapper {
      svg {
        height: 150px !important;
      }
    }
  }
`;

import { appActions } from "app.state";
import { selectors } from "configureStore";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Icons from "./Icons";

export default function DisplaySettings({ setShowNestedSettings }) {
  const app = useSelector(selectors.app);
  const dispatch = useDispatch();

  return (
    <Wrapper>
      {setShowNestedSettings && (
        <button
          type="button"
          onClick={() => {
            setShowNestedSettings(null);
          }}
          className="back-to-dropdown"
        >
          <Icons.Back /> Display Settings
        </button>
      )}
      <label className="icon-and-text radio-item">
        <input
          type="radio"
          value="auto"
          name="auto"
          checked={app.theme === "auto"}
          onChange={() => {
            dispatch(appActions.setTheme("auto"));
            localStorage.setItem("theme", "auto");
          }}
        />{" "}
        Auto
      </label>
      <label className="icon-and-text radio-item">
        <input
          type="radio"
          value="light"
          name="light"
          checked={app.theme === "light"}
          onChange={() => {
            dispatch(appActions.setTheme("light"));
            localStorage.setItem("theme", "light");
          }}
        />{" "}
        Light
      </label>
      <label className="icon-and-text radio-item">
        <input
          type="radio"
          value="dark"
          name="dark"
          checked={app.theme === "dark"}
          onChange={() => {
            dispatch(appActions.setTheme("dark"));
            localStorage.setItem("theme", "dark");
          }}
        />{" "}
        Dark
      </label>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  .back-to-dropdown {
    background: transparent;
    width: 100%;
    border: none;
    cursor: pointer;
    margin-top: 10px;
    outline: none;
    color: ${({ theme }) => theme.COLORS.primary};
    font-size: 14px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    margin-bottom: 10px;
    svg {
      fill: ${({ theme }) => theme.COLORS.primary100};
      margin-right: 10px;
    }
    transition: 0.5s all ease;
    &:hover {
      fill: #70245970;
    }
  }

  .icon-and-text {
    display: flex;
    align-items: center;
  }

  .radio-item {
    height: 48px;
    width: 100%;
    color: ${({ theme }) => theme.COLORS.grey100};
    text-decoration: none;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0px 12px 0px 12px;
    border-radius: 0px;
    transition: 0.3s all ease;
    z-index: 10;
    box-sizing: border-box;
    border-radius: 5px;
    &:hover {
      background-color: ${({ theme }) => theme.COLORS.primary10020};
    }
    input {
      margin-right: 10px;
    }
  }

  [type="radio"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 24px;
    height: 24px;
    border: solid 2px ${({ theme }) => theme.COLORS.primary100};
    margin-right: 8px;
    position: relative;
    outline: none;
    border-radius: 100%;
  }

  [type="radio"]:checked::before {
    content: "";
    width: 14px;
    height: 14px;
    background-color: ${({ theme }) => theme.COLORS.primary100};
    position: absolute;
    top: 3px;
    left: 3px;
  }

  [type="radio"]:checked::before {
    border-radius: 100%;
  }
`;

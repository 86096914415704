import { css } from "styled-components";

export default css`
  display: flex !important;
  align-items: center;
  display: block;
  cursor: pointer;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  user-select: none;
  min-height: 50px;
  font-weight: 400;
  margin-top: 0px !important;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  .radio-circle {
    position: absolute;
    left: 0;
    height: 22px;
    width: 22px;
    border: 1px solid ${(p) => p.theme.COLORS.primary};
    border-radius: 50%;
    transition: 0.7s all ease;
    &:hover {
      /* background-color: #f5f5f5; */
    }
    &:after {
      content: "";
      position: absolute;
      display: none;
    }
  }
  &:checked {
    /* background-color: #2196f3; */
  }
  &:hover input ~ .radio-circle {
    /* background-color: #f5f5f5; */
  }

  .radio-circle:after {
    content: "";
    position: absolute;
    display: none;
  }

  input:checked ~ .radio-circle {
    background-color: ${(p) => p.theme.primary10020};
  }

  input:checked ~ .radio-circle:after {
    display: block;
  }

  .radio-circle:after {
    top: 3px;
    left: 3px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: ${(p) => p.theme.COLORS.primary};
  }
`;

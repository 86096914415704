/* eslint-disable jsx-a11y/control-has-associated-label */
import Icons from "components/Icons";
import { AnimatePresence, motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

export default function InstallPwaModal({ skipPwaInstallation, installPWA }) {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <div
        className="modal-background"
        role="button"
        tabIndex={0}
        onClick={() => {
          //   setSelectedRequest(null);
        }}
      />
      <AnimatePresence>
        <motion.div
          className="pop-up"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -50, transition: { duration: 0.15 } }}
          transition={{ duration: 0.7, delay: 1 }}
        >
          <h1 className="welcome-message">Welcome!</h1>
          <p>
            You can easily add this web application to your home screen and have
            instant access and browse faster with an app-like experience.
          </p>

          <p>
            Installing uses almost no storage and provides a quick way to return
            to this app.
          </p>

          <div className="edit-or-delete">
            <motion.button
              type="button"
              className="edit-button"
              whileHover={{ scale: 1.03 }}
              whileTap={{ scale: 0.95 }}
              onClick={installPWA}
            >
              <Icons.Download />
              <span>{t("Install")}</span>
            </motion.button>
            <motion.button
              type="button"
              className="delete-button"
              whileHover={{ scale: 1.03 }}
              whileTap={{ scale: 0.95 }}
              onClick={skipPwaInstallation}
            >
              <span>{t("Skip")}</span>
            </motion.button>
          </div>
        </motion.div>
      </AnimatePresence>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: fixed;
  background: transparent;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 9999;
  top: 0;
  left: 0;
  color: ${({ theme }) => theme.COLORS.primary};
  .modal-background {
    position: fixed;
    background: ${({ theme }) => theme.modal.background};
    width: 100%;
    height: 100%;
    display: flex;
    outline: none;
  }
  .pop-up {
    width: 50%;
    height: auto;
    overflow-y: auto;
    z-index: 9999;
    padding: 40px 20px;
    margin-top: 15% !important;
    background-color: ${({ theme }) => theme.drawer.backgroundColor} !important;
    backdrop-filter: blur(15px);
    border: ${({ theme }) => theme.modal.border};
    box-shadow: ${({ theme }) => theme.modal.boxShadow};
    border-radius: 4px;
    box-sizing: border-box;
  }

  .welcome-message {
    font-size: ${({ language }) => (language === "am" ? 45 / 1.5 : 45)}px;
    font-family: "Fredoka One", cursive;
    margin: 0px;
  }

  .edit-or-delete {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 10px;
    a {
      text-decoration: none;
    }
    button {
      margin: 10px;
      cursor: pointer;
      transition: 0.5s all ease;
      outline: none;
      border: none;
      padding: 15px 20px;
      font-weight: 600;
      border-radius: 5px;
      font-size: 16px;
      min-width: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      svg {
        height: 20px;
        margin-right: 5px;
      }
      &:hover {
        box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.04);
      }
    }
    .edit-button {
      background-color: ${({ theme }) => theme.COLORS.primary10020};
      color: ${({ theme }) => theme.COLORS.primary100};
      svg {
        fill: ${({ theme }) => theme.COLORS.primary100};
      }
    }
    .delete-button {
      background-color: ${({ theme }) => theme.COLORS.blue20};
      color: ${({ theme }) => theme.COLORS.blue};
      svg {
        fill: ${({ theme }) => theme.COLORS.blue};
      }
    }
  }

  @media screen and (max-width: 950px) {
    .pop-up {
      width: 100% !important;
      position: fixed;
      bottom: 0;
      box-shadow: ${({ theme }) => theme.modal.itemDetailMobileBoxShadow};
      border-radius: 0px;
      height: auto;
      border: 1px solid #ffffff00;
    }
  }
`;

import { useTranslation } from "react-i18next";
import styled from "styled-components";

export default function SearchFormMobile({
  searchType,
  setSeachQuery,
  filterSeachResult,
}) {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <input
        type="text"
        placeholder={
          searchType === "Date"
            ? t("Search by Date")
            : t("Search by Order Number")
        }
        onChange={(e) => {
          setSeachQuery(e.target.value);
          filterSeachResult(e.target.value);
        }}
      />
    </Wrapper>
  );
}

const Wrapper = styled.form`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  max-width: 565px;
  width: 100%;
  margin: 0px 5px;

  input {
    width: 100%;
    height: 40px;
    border-radius: 8px;
    border: 1px solid ${(p) => p.theme.COLORS.primary10050};
    background-image: ${(p) => p.theme.searchInput.backgroundColor};
    color: ${({ theme }) => theme.COLORS.primary};
    padding: 0px 10px;
    outline: none;
    transition: 0.5s all ease;
    ::placeholder {
      color: ${({ theme }) => theme.COLORS.primary10090};
    }
    &:focus {
      outline: none;
      border: 1px solid ${({ theme }) => theme.COLORS.primary100};
      box-shadow: 0px 0px 0px 4px ${({ theme }) => theme.COLORS.primary10050};
    }
  }
`;

import { appActions } from "app.state";
import Icons from "components/Icons";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useDispatch } from "react-redux";
import dateFormatter from "utils/dateFormatter";
import { commaSeparated } from "utils/formatNumber";
import requests from "utils/requests";

export default function ItemsList({ items, status, selectedRequest }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [editMode, setEditMode] = useState(false);
  const [itemsListData, setItemsListData] = useState(
    items.map((el) => ({ ...el, invalid: false }))
  );
  const [picklistMutation, picklistMutationStatus] = useMutation(
    requests.picklist,
    {
      onSuccess: () => {
        setEditMode(false);
        dispatch(
          appActions.showNotification({
            type: "SUCCESS",
            message: "Order Quantity Updated",
          })
        );
      },
    }
  );

  useEffect(() => {
    if (picklistMutationStatus.isError) {
      const errorMessage =
        picklistMutationStatus?.error?.response?.data?.errors[0] ||
        "Couldn't update order";
      dispatch(
        appActions.showNotification({
          type: "ERROR",
          message: errorMessage,
        })
      );
    }
  }, [picklistMutationStatus.isError]);

  const onQuantityChange = (value, index) => {
    const newValue = [...itemsListData];
    if (items[index].approvedQuantity < value) {
      newValue[index].invalid = true;
    } else {
      newValue[index].invalid = false;
    }
    newValue[index].approvedQuantity = value;
    newValue[index].price = newValue[index].unitPrice * value;

    setItemsListData([...newValue]);
  };

  const onSubmitOrder = async () => {
    let formValid = true;
    itemsListData.forEach((el) => {
      if (el.invalid) {
        formValid = false;
      }
    });

    if (formValid) {
      const orderDetails = itemsListData.map((el) => ({
        id: el.id,
        itemUnitId: el.itemUnitId,
        updatedQuantity: parseInt(el.approvedQuantity, 10),
      }));
      await picklistMutation({
        id: selectedRequest,
        order: {
          isLastUpdate: true,
          orderDetails,
        },
      });
    }
  };

  return (
    <ul className="items-list">
      {status === "PickListed" && !editMode && (
        <motion.button
          type="button"
          className="edit-button"
          whileHover={{ scale: 1.03 }}
          whileTap={{ scale: 0.95 }}
          onClick={() => {
            setEditMode(true);
          }}
        >
          <Icons.ReduceDollar />
          <span>{t("Reduce Quantity")}</span>
        </motion.button>
      )}
      {status === "PickListed" && editMode && (
        <motion.button
          type="button"
          className="edit-button"
          whileHover={{ scale: 1.03 }}
          whileTap={{ scale: 0.95 }}
          onClick={() => {
            setEditMode(false);
          }}
        >
          <Icons.Close />
          <span>{t("Cancel Edit")}</span>
        </motion.button>
      )}
      <li className="title-list-item">
        <h3>{t("Item")}</h3>
        <h3 className="quantity-Text">{t("Quantity")}</h3>
      </li>
      {itemsListData.map((item, index) => (
        <li key={item.id}>
          <div>
            <p className="item-name">{item.fullItemName}</p>
            {status === "PickListed" && (
              <div className="price-section">
                {!Number.isNaN(item?.unitPrice) && (
                  <section>
                    {t("Unit Price ETB")}
                    <h3>{commaSeparated(+item?.unitPrice?.toFixed(2))}</h3>
                  </section>
                )}
                {!Number.isNaN(item?.price) && (
                  <section>
                    {t("Total Price ETB")}
                    <h3>{commaSeparated(+item?.price?.toFixed(2))}</h3>
                  </section>
                )}
              </div>
            )}
            {status === "PickListed" && (
              <div className="price-section">
                {!Number.isNaN(item?.unitPrice) && (
                  <section>
                    {t("Expire Date")}
                    <h3>
                      {dateFormatter.yearMonthDate(item.expireDate) || "-- --"}
                    </h3>
                  </section>
                )}
                {!Number.isNaN(item?.price) && (
                  <section>
                    {t("Manufacturer")}
                    <h3>{item.manufacturer || "-- --"}</h3>
                  </section>
                )}
              </div>
            )}
            {item.invalid && (
              <div className="error">
                Quantity should be less than the pervious quantity(
                {items[index].approvedQuantity})
              </div>
            )}
          </div>
          {editMode ? (
            <div>
              <input
                type="number"
                value={item.approvedQuantity}
                max={item.approvedQuantity}
                onChange={(e) => {
                  onQuantityChange(e.target.value, index);
                }}
              />
            </div>
          ) : (
            <p className="quantity">
              {item.quantity ||
                item.updatedQuantity ||
                item.approvedQuantity ||
                item.requestedQuantity}
            </p>
          )}
        </li>
      ))}
      {editMode && (
        <motion.button
          type="button"
          className="blue-button"
          whileHover={{ scale: 1.03 }}
          whileTap={{ scale: 0.95 }}
          onClick={onSubmitOrder}
        >
          <span>
            {picklistMutationStatus.isLoading
              ? t("Updating Order")
              : t("Submit Order Update")}
          </span>
        </motion.button>
      )}
    </ul>
  );
}

export default function getItemTypes(itemsList) {
  const itemTypes = [];

  itemsList.forEach((item) => {
    if (itemTypes.length === 0) {
      itemTypes.push(item.commodityType);
    } else if (!itemTypes.includes(item.commodityType)) {
      itemTypes.push(item.commodityType);
    }
  });

  return itemTypes;
}

export const filterItemsByType = (itemsList, itemType) => {
  if (itemType === "All") return itemsList;
  return itemsList.filter((el) => el.commodityType === itemType);
};

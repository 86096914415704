/* eslint-disable no-nested-ternary */
import { appActions } from "app.state";
import EmptyState from "components/EmptyState";
import OrderDate from "components/OrderDate";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { NavLink, useParams } from "react-router-dom";
import { ShimmerEffect } from "SharedComponents/ShimmerEffect";
import styled from "styled-components";
import categorizeOrdersByType, {
  caregorizedOrdersList,
} from "utils/categorizeOrdersByType";
import { fetchAllRequests } from "utils/requests";

export default function OrderListByType() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [showDataOnRedirect, setShowDataOnRedirect] = useState(true);

  const [categorizedOrders, setCategorizedOrders] = useState(
    caregorizedOrdersList
  );
  const allRequestsReq = useQuery("All requests", fetchAllRequests, {
    onSuccess: (data) => {
      setCategorizedOrders([...categorizeOrdersByType(data)]);
    },
  });

  useEffect(() => {
    if (allRequestsReq.data && id) {
      const selectedRequestRedirect = allRequestsReq.data.filter(
        // eslint-disable-next-line eqeqeq
        (el) => el.requestNo == id
      )[0];
      if (selectedRequestRedirect && showDataOnRedirect) {
        setShowDataOnRedirect(false);
        dispatch(
          appActions.showRequestDetailModal({
            selectedRequest: selectedRequestRedirect.id,
            selectedRequestData: selectedRequestRedirect,
          })
        );
      }
    }
  }, [allRequestsReq.data, id]);

  return (
    <Wrapper>
      {categorizedOrders.length > 0 &&
        categorizedOrders.map((order, index) => (
          <OrdesListOfByType
            key={index.toString()}
            ordersOfType={order}
            loading={allRequestsReq.isLoading}
          />
        ))}
    </Wrapper>
  );
}

const OrdesListOfByType = ({ ordersOfType, loading }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <div className="orders-and-title-wrapper">
      <h3 className="header-text order-type-text">{t(ordersOfType.type)}</h3>

      <div className="orders-container">
        {loading && <LoadingOrdersShimmer />}
        {!loading && ordersOfType?.orders?.length === 0 && (
          <EmptyState
            message={`No ${ordersOfType.type} requests`}
            mode="small"
            flexDirection="row"
          />
        )}
        {!loading &&
          ordersOfType?.orders?.length > 0 &&
          ordersOfType.orders.map((order) => (
            <motion.div
              key={order.id}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.97 }}
              className="card-list-item"
              onClick={() => {
                dispatch(
                  appActions.showRequestDetailModal({
                    selectedRequest: order.id,
                    selectedRequestData: order,
                  })
                );
              }}
            >
              <OrderDate unformattedDate={order.createdDate} />
              <section>
                <p className="list-label">{t("Order Number")}</p>{" "}
                <p className="list-content">{order.requestNo}</p>
              </section>
            </motion.div>
          ))}
        {ordersOfType.viewAll && (
          <NavLink exact to={`/orders/${ordersOfType.type.toLowerCase()}`}>
            <motion.button
              type="button"
              className="view-all-button"
              whileHover={{ scale: 1.03 }}
              whileTap={{ scale: 0.95 }}
            >
              {t("View All")}
            </motion.button>
          </NavLink>
        )}
      </div>
    </div>
  );
};

const LoadingOrdersShimmer = () => {
  const CardShimmer = () => (
    <div className="card-list-item">
      <ShimmerEffect
        width="200px"
        height="24px"
        marginTop="10px"
        borderRadius="5px"
      />
      <ShimmerEffect
        width="100px"
        height="12px"
        marginTop="15px"
        borderRadius="5px"
      />
      <ShimmerEffect
        width="40px"
        height="16px"
        marginTop="9px"
        borderRadius="5px"
      />
    </div>
  );
  return (
    <>
      <CardShimmer />
      <CardShimmer />
      <CardShimmer />
      <CardShimmer />
    </>
  );
};

const Wrapper = styled.div`
  padding: 0;
  margin: 0;
  list-style-type: none;
  margin: 0 40px;
  box-sizing: border-box;
  color: ${({ theme }) => theme.COLORS.primary};
  .orders-and-title-wrapper {
    width: 100%;
    margin-bottom: 20px;
  }
  .order-type-text {
    border: none !important;
  }
  .orders-container {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    height: 150px;
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    ::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }

    ::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.COLORS.primary10020};
      width: 8px;
      border-radius: 5px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: ${({ theme }) => theme.COLORS.primary10020};
      width: 8px;
    }
  }
  .card-list-item {
    float: left;
    margin: 10px 20px 10px 3px;
    background-image: ${({ theme }) => theme.RequestsList.card.backgroundImage};
    border: ${({ theme }) => theme.RequestsList.card.border};
    box-shadow: ${({ theme }) => theme.RequestsList.card.boxShadow};
    width: fit-content;
    border-radius: 4px;
    color: ${({ theme }) => theme.COLORS.primary};
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    section {
      width: 100%;
      margin: 7px 0px;
    }
    p {
      margin: 0;
    }
    .list-label {
      font-size: 14px;
      color: ${({ theme }) => theme.COLORS.primary10096};
    }
    .list-content {
      letter-spacing: 1px;
      font-size: 16px;
      font-weight: bold;
    }
    &:hover {
      /* box-shadow: ${({ theme }) => theme.RequestsList.card.hover.boxShadow};
      border: ${({ theme }) => theme.RequestsList.card.hover.border}; */
    }
  }

  .view-all-button {
    white-space: nowrap;
    background-color: ${({ theme }) => theme.COLORS.primary10020};
    color: ${({ theme }) => theme.COLORS.primary};
    border: 1px solid ${({ theme }) => theme.COLORS.primary10029};
    padding: 10px 15px;
    border-radius: 8px;
    margin: 0px 20px 0px 0px;
    cursor: pointer;
    outline: none;
    &:hover {
      background-color: ${({ theme }) => theme.COLORS.primary10029};
    }
  }
  @media screen and (max-width: 949px) {
    margin: 20px 0px 0px 0px;
    padding-bottom: 100px;
    .card-list-item {
      margin: 5px 20px 10px 2%;
    }
    .order-type-text {
      margin-left: 4% !important;
    }
    .order-type-text {
      margin-left: 2%;
    }
  }
`;

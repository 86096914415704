/* eslint-disable react/jsx-props-no-spreading */
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, Route, Redirect } from "react-router-dom";
import { appActions } from "app.state";
import { selectors } from "configureStore";
import { http } from "utils/http";

/**
 * protects each url navigation that needs authentication
 * it checks if the user is authenticated and redirects
 * to login if user is not authentication
 * its a route guard for each routes
 */
export default function ProtectedRoute({ children, ...rest }) {
  const dispatch = useDispatch();
  /* get authentication data from redux store */
  const app = useSelector(selectors.app);
  const history = useHistory();
  const locationPath = useLocation();
  useEffect(() => {
    /**
     * if the user info is not found in the redux store mostly
     * happens on page reload or redirect from a link
     */
    if (!app.token) {
      /**
       * get the user data from local storage which is saved on login
       *  */
      const authData = localStorage.getItem("userInfo");
      /**
       * if the user data is found on local storage which means user
       * was authenticated before
       *  */
      if (authData) {
        const userInfo = JSON.parse(authData);
        /**
         * 1 - set the redux store data with the data from storage
         * 2 - set the authorization token data from the storage data
         * 3 - pass the user to the url requested
         */
        dispatch(appActions.setToken(userInfo.token));
        http.setHeader("Authorization", `Bearer ${JSON.parse(authData).token}`);
        http.intercept.autorize(history);
        history.push(locationPath.pathname);
      }
    }
  }, []);
  return (
    <Route
      {...rest}
      render={({ location }) => {
        /** this render props represents
         * if user data is found redirect to the requested path
         * else redirect to login
         */
        return app.token ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/welcome",
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
}

/* eslint-disable import/prefer-default-export */
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectors } from "configureStore";
import { appActions } from "app.state";

export const useInstallPWAprompt = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    window.addEventListener("beforeinstallprompt", e => {
      e.preventDefault();
      const installPropmptShown = localStorage.getItem("PWA-install-prompt");
      if (!installPropmptShown) {
        dispatch(appActions.setPWAInstallPrompt(e));
      }
    });
  }, []);
};

export const useOnInstallPwa = () => {
  const dispatch = useDispatch();
  const app = useSelector(selectors.app);
  return () => {
    dispatch(appActions.hidePWAInstallPrompt());
    if (app.deferredPromptPWA) {
      app.deferredPromptPWA.prompt();
      app.deferredPromptPWA.userChoice.then(() => {
        dispatch(appActions.removePWAInstallPrompt());
      });
    }
  };
};

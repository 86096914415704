import { useTranslation } from "react-i18next";
import { ShimmerEffect } from "SharedComponents/ShimmerEffect";

export default function ItemsLoading() {
  const { t } = useTranslation();
  const loadingElement = (
    <li>
      <div className="shimmer-element">
        <ShimmerEffect width="100%" height="20px" borderRadius="5px" />
      </div>
      <ShimmerEffect width="50px" height="20px" borderRadius="5px" />
    </li>
  );
  return (
    <ul className="items-list">
      <li className="title-list-item">
        <h3>{t("Item")}</h3>
        <h3 className="quantity-Text">{t("Quantity")}</h3>
      </li>
      {loadingElement}
      {loadingElement}
      {loadingElement}
      {loadingElement}
    </ul>
  );
}

export default {
  "Order Online.": "Order Online.",
  "Ethiopian Pharmaceutical Supply Service":
    "Ethiopian Pharmaceutical Supply Service",
  "Its Easy, Simple and Time saving": "Its Easy, Simple and Time saving",
  "Process your product request forms online":
    "Process your product request forms online",
  "Submit your requests to EPSS and follow up on the status":
    "Submit your requests to EPSS and follow up on the status",
  "Preview previous requests": "Preview previous requests",
  "Show": "Show",
  "View available products": "View available products",
  "View new arrived items": "View new arrived items",
  "Log in": "Log in",
  "Sign Up": "Sign Up",
  "Sign Out": "Sign Out",
  "Select Hub": "Select Hub",
  "TIN Number": "TIN Number",
  Password: "Password",
  "Loading Institution ...": "Loading Institution ...",
  "Forgot Password?": "Forgot Password?",
  "Forgot your password? We can help! A four digit verification code will be sent to your phone number.":
    "Forgot your password? We can help! A four digit verification code will be sent to your phone number.",
  "First Name": "First Name",
  "Last Name": "Last Name",
  "Phone Number": "Phone Number",
  "Telegram Phone Number": "Telegram Phone Number",
  "Same As Phone Number": "Same As Phone Number",
  "Just a moment...": "Just a moment...",
  EPSA: "EPSS",
  Settings: "Settings",
  "Display Settings": "Display Settings",
  "New Order": "New Order",
  Draft: "Draft",
  Pending: "Pending",
  Submitted: "Submitted",
  Approved: "Approved",
  Picklisted: "Picklisted",
  PickListed: "Picklisted",
  Rejected: "Rejected",
  About: "About",
  "Order Number": "Order Number",
  Items: "Items",
  Mode: "Mode",
  "Payment Type": "Payment Type",
  "Total Quantity": "Total Quantity",
  "ETB Total Price": "ETB Total Price",
  "Items Requested": "Items Requested",
  "Stockedout Items": "Stockedout Items",
  Item: "Item Name",
  Unit: "Unit",
  Quantity: "Quantity",
  "Unit Price ETB": "Unit Price ETB",
  "Total Price ETB": "Total Price ETB",
  Print: "Print",
  "Print Stockout": "Print Stockout",
  "Loading Items...": "Loading Items...",
  "Select items for order": "Select items for order",
  "Get items from last request": "Get items from last request",
  "Select Items": "Select Items",
  "Select Payment Type": "Select Payment Type",
  Invalid: "Invalid",
  "Filter items by type": "Filter items by type",
  "Finish Selection": "Finish Selection",
  "Add Items": "Add Items",
  "Save As Draft": "Save As Draft",
  "Submit Order": "Submit Order",
  Edit: "Edit",
  Delete: "Delete",
  Total: "Total",
  "Recently added/updated": "Recently added/updated",
  "View All": "View All",
  "Facility Name": "Facility Name",
  Id: "Id",
  Hub: "Hub",
  "Region, Zone, Wereda": "Region, Wereda, Kebele",
  "Facility Info": "Facility Info",
  "Search by Date": "Search by Date",
  "Search by Order Number": "Search by Order Number",
  "Search By": "Search By",
  Date: "Date",
  Recents: "Recents",
  "New/Updated": "New/Updated",
  "No match found": "No match found",
  Username: "Username",
  "Select Facility": "Select Facility",
  "Reset Password": "Reset Password",
  "Old Password": "Old Password",
  "New Password": "New Password",
  "Confirm Password": "Confirm Password",
  Reset: "Reset",
  "Status Report":"Status Report",
  "Facility Report":"Facility Report",
  "User Management":"User Management",
  "Enter Order NO": "Enter Order NO",
  "Enter Facility ID":"Enter Facility ID",
  "Filter By Hub":"Filter By Hub",
  "Filter By Date":"Filter By Date",
  "Apply Filter":"Apply Filter"
};

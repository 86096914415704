/* eslint-disable eqeqeq */
import { useQuery } from "react-query";
import { AnimatePresence, motion } from "framer-motion";
import LoadingState from "components/LoadingState";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { fetchHubsList, fetchStatusReport } from "utils/requests";
import { useEffect, useState } from "react";
import DataTable from 'react-data-table-component';
import Constants from "utils/constants";
import FacilitySearch from "../../Landing/LoginAndSignUp/FacilitySearch";

export default function StatusReport({ setSelectedNavType }) {
  const { t } = useTranslation();
  const [reportData, setReportData] = useState([]);
  const [showFilterSelection, setShowFilterSelection] = useState(false);
  const [hubsList, setHubsList] = useState([]);
  const [facilityRequired, setFacilityRequired] = useState(false);
  const [selectedOrderNumber, setSelectedOrderNumber] = useState("");
  const [selectedFacility, setSelectedFacility] = useState("");
  const [selectedHubId, setSelectedHubId] = useState("");
  const [selectedStartDate, setSelectedStartDate] = useState("");
  const [selectedEndDate, setSelectedEndDate] = useState("");
  const [selectedDateOption, setSelectedDateOption] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
	const [totalRows, setTotalRows] = useState(0);
  const [requestParams, setRequestParams] = useState(["Get status report", selectedOrderNumber, selectedStartDate, selectedEndDate, selectedHubId, selectedFacility?.id, pageNumber, pageSize])
  const statusReportReq = useQuery(requestParams, fetchStatusReport, {
    onSuccess: (data) => {
        setReportData(data.data);
        setTotalRows(data.totalCount);
    },
  });

  

  const handlePageChange = page => {
    setPageNumber(page);
    setRequestParams(["Get status report", selectedOrderNumber, selectedStartDate, selectedEndDate, selectedHubId, selectedFacility?.id, page, pageSize]);
	};

  const handlePerRowsChange = perPage => {
		setPageSize(perPage);
    setRequestParams(["Get status report", selectedOrderNumber, selectedStartDate, selectedEndDate, selectedHubId, selectedFacility?.id, pageNumber, perPage]);
	};

  const [isLargeScreen, setIsLargeScreen] = useState(
    !(document.documentElement.clientWidth < 950)
  );

  const resizeHandler = () => {
    const width = document.documentElement.clientWidth;
    if (width < 950) {
      setIsLargeScreen(false);
    } else {
      setIsLargeScreen(true);
    }
  };

  useQuery("Hub List", fetchHubsList,
  {
    onSuccess: (data) => {
      setHubsList(data.data);
    }
  });  

  const curr = new Date; // get current date
  const firstDayOfTheWeek = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
  const timeFilters = [
    {
        label:"All", 
        startDate: null, 
        endDate: null
    },
    {
        label:"Today", 
        startDate: new Date(curr.setHours(0)).toUTCString(), 
        endDate: new Date().toUTCString()
    },
    {
        label:"This Week", 
        startDate: new Date(curr.setDate(firstDayOfTheWeek)).toUTCString(), 
        endDate: new Date().toUTCString()
    },
    {
        label:"This Month", 
        startDate: new Date(curr.setDate(0)).toUTCString(), 
        endDate: new Date().toUTCString()
    },
    {
        label:"This Year", 
        startDate: new Date(new Date(curr.setMonth(0)).setDate(0)).toUTCString(), 
        endDate: new Date().toUTCString()
    }
  ];

  const cardsVariants = {
    hidden: { opacity: 0, scale: 0.97 },
    visible: { opacity: 1, scale: 1 },
  };

  const itemFilterCardVariants = {
    hidden: { opacity: 0.5, y: 1500 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0.5, y: 1500 },
  };

  const formElementsVariants = {
    hidden: { opacity: 0, scale: 0.97 },
    visible: { opacity: 1, scale: 1 },
  };

  const columns = [
    {
        name: 'orderNo',
        selector: row => row.orderNo,
        maxWidth: "150px",
    },
    {
        name: 'status',
        selector: row => row.status,
        maxWidth: "150px",
    },
    {
        name: 'hubName',
        selector: row => row.hubName,
        maxWidth: "150px",
    },
    {
        name: 'facilityName',
        selector: row => row.facilityName,
        maxWidth: "150px",
    },
    {
        name: 'requestedBy',
        selector: row => row.requestedBy,
        maxWidth: "150px",
    },
    {
        name: 'totalItemsRequested',
        selector: row => row.totalItemsRequested,
        maxWidth: "150px",
    },
];

const onFormSubmit = () => {
  if(!isLargeScreen){
    setShowFilterSelection(false);
  }
  setRequestParams(["Get status report", selectedOrderNumber, selectedStartDate, selectedEndDate, selectedHubId, selectedFacility?.id, pageNumber, pageSize])
}

  useEffect(() => {
    setSelectedNavType("status-report");
    window.addEventListener("resize", resizeHandler, true);
    return () => {
      window.removeEventListener("resize", resizeHandler, true);
    };
  }, []);

  return (
    <Wrapper>
      <div className="left-and-right-container">
        <motion.section
          className="left-side"
          initial="hidden"
          animate="visible"
          exit="exit"
          variants={cardsVariants}
          transition={{ duration: 0.7 }}
        > 
          {statusReportReq.isLoading && (
            <LoadingState message={t("Loading Items...")} />
          )}
          {statusReportReq.isSuccess && !isLargeScreen && (
            <div className="spacing full-width button-spacing">
              <div className=" show-filter">
                <motion.button
                  whileHover={{ scale: 1.01 }}
                  whileTap={{ scale: 0.97 }}
                  type="button"
                  onClick={() => {
                    setShowFilterSelection(true);
                  }}
                  className="blue-button red-button"
                >
                  {t("Show Filter")}
                </motion.button>
              </div>
            </div>
          )}
          {statusReportReq.isSuccess && (
            <DataTable
              class="status-report-table"
              columns={columns}
              data={reportData}
              pagination
              paginationServer
              paginationDefaultPage={pageNumber}
              paginationPerPage={pageSize}
              paginationTotalRows={totalRows}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              fixedHeader
              fixedHeaderScrollHeight="calc(94% - 62px)"
            />
          )}
        </motion.section>
        {!isLargeScreen && showFilterSelection &&(
          <motion.div
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={{
              hidden: { opacity: 0 },
              visible: { opacity: 1 },
              exit: { opacity: 0 },
            }}
            transition={{ duration: 1 }}
            className="drawer-background"
            onClick={() => {
              setShowFilterSelection(false);
            }}
          />
        )}

        <AnimatePresence>
          {(isLargeScreen || showFilterSelection) && (
            <motion.section
              className="right-side"
              initial="hidden"
              exit="exit"
              animate="visible"
              variants={
                      isLargeScreen ? cardsVariants : itemFilterCardVariants
                    }
              transition={{ duration: 0.7, delay: 0.5 }}
            >
              <motion.div
                className="form-element-wrapper spacing"
                initial="hidden"
                animate="visible"
                variants={formElementsVariants}
                transition={{ duration: 0.7 }}
              >
                <label>{t("Enter Order NO")}</label>
                <input
                  placeholder="Order Number"
                  className="order-number"
                  type="text"
                  value={selectedOrderNumber}
                  onChange ={(e) => {
                          setSelectedOrderNumber(e.target.value);
                        }}
                />
              </motion.div>
              <motion.div
                className="form-element-wrapper spacing"
                initial="hidden"
                animate="visible"
                variants={formElementsVariants}
                transition={{ duration: 0.7 }}
              >
                <FacilitySearch
                  selectedFacility={selectedFacility}
                  setSelectedFacility={setSelectedFacility}
                  facilityRequired={facilityRequired}
                  setFacilityRequired={setFacilityRequired}
                  mode={Constants.filterByFacility}
                />
              </motion.div>
              <motion.div
                className="form-element-wrapper spacing"
                initial="hidden"
                animate="visible"
                variants={formElementsVariants}
                transition={{ duration: 0.7 }}
              >
                <label>{t("Filter By Hub")}</label>
                <select
                  value={selectedHubId}
                  onChange ={(e) => {
                          setSelectedHubId(e.target.value);
                        }}
                >
                  <option value={null} key="all" type="button">
                      All
                  </option>
                  {hubsList.map((el) => (
                      <option value={parseInt(el.id, 10)} key={el.id} type="button">
                      {el.name}
                      </option>
                  ))}
                </select>
              </motion.div>
              <motion.div
                className="form-element-wrapper spacing"
                initial="hidden"
                animate="visible"
                variants={formElementsVariants}
                transition={{ duration: 0.7 }}
              >
                <label>{t("Filter By Date")}</label>
                <select
                  value={selectedDateOption}
                  onChange ={(e) => {
                          setSelectedDateOption(e.target.value);
                          setSelectedStartDate(timeFilters[e.target.value].startDate);
                          setSelectedEndDate(timeFilters[e.target.value].endDate);
                        }}
                >
                  {timeFilters.map((el,i) => (
                    <option value={i} key={el.label} type="button">
                      {el.label}
                    </option>
                  ))}
                </select>
              </motion.div>
              <div className="spacing full-width button-spacing">
                <div className="apply-filter">
                  <motion.button
                    whileHover={{ scale: 1.01 }}
                    whileTap={{ scale: 0.97 }}
                    type="button"
                    onClick={() => {
                      onFormSubmit();
                    }}
                    className="blue-button red-button button-spacing"
                  >
                    {t("Apply Filter")}
                  </motion.button>
                </div>
              </div>
            </motion.section>
          )}
        </AnimatePresence>
      </div>
    
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 100px !important;
  .status-report-table {
    margin: 20px;
  }
  .form-element-wrapper {
      display: flex;
      flex-direction: column;
      color: ${({ theme }) => theme.COLORS.primary100};
      padding: 0px;
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 20px;
      position: relative;
      label {
        font-size: 14px;
        position: absolute;
      }
    }
  .left-and-right-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 93.5vh;
    .left-side,
    .right-side {
      background-image: ${({ theme }) => theme.addOrders.backgroundImage};
      box-shadow: ${({ theme }) => theme.addOrders.boxShadow};
      border: ${({ theme }) => theme.addOrders.border};
      border-radius: 8px;
      padding: 15px;
      box-sizing: border-box;
      margin: 0px 1%;
      overflow-y: auto;
    }
    .left-side {
      width: 77%;
      height: 94%;
      margin-right: 0.5%;
      overflow-y: auto;

      .error {
        margin-top: 7px;
        font-size: 14px;
        color: #d53f8c;
        position: absolute;
        bottom: -10px;
        right: 0px;
      }
    }
    .right-side {
      width: 20%;
      height: 94%;
      margin-left: 0.5%;
    }
    select {
      border: 1px solid ${({ theme }) => theme.COLORS.primary10029};
      margin: 24px 0px 7px 0px;
      padding: 10px 10px;
      text-align: center;
      border-radius: 5px;
      transition: 0.3s all ease;
      cursor: pointer;
      width: 100%;
      color: ${({ theme }) => theme.COLORS.primary100};
      background: transparent;
      &:hover {
        background: ${({ theme }) => theme.COLORS.primary10010};
      }
      &:focus {
        background: ${({ theme }) => theme.COLORS.primary10029};
        outline: none;
        border: 1px solid ${({ theme }) => theme.COLORS.primary100};
      }
      .empty-option {
        background-color: transparent;
        display: none;
      }
      option {
        color: #000000;
      }
    }

    .show-filter {
      justify-content: center;
      border-buttom: 0px;
      text-align: center;
      margin-bottom: 15px;
    }

    .apply-filter {
      justify-content: center;
      text-align: center;
    }

    input {
      border: 1px solid ${({ theme }) => theme.COLORS.primary10029};
      outline: none;
      color: #5a1c47;
      transition: 0.5s all ease;
      background-color: #fbf6f9;
      margin: 24px 0px 7px 0px;
      padding: 10px 10px;
      border-radius: 5px;
      box-sizing: border-box;
      &:focus {
        border: 1px solid #d53f8c;
      }
      &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 30px #f9f5f7 inset !important;
      }
      ::placeholder {
        color: ${({ theme }) => theme.COLORS.primary10060};
      }
    }

    .button-spacing {
      margin: 20px 20px 0px 0px;
    }

    .drawer-background {
      width: 100vw;
      height: 100vh;
      background-color: ${({ theme }) => theme.modal.background};
      position: fixed;
      top: 0;
    }

    .blue-button {
      min-width: 180px;
    }

    @media screen and (max-width: 950px) {
    .left-and-right-container {
      overflow-y: auto;
      height: auto;
      margin-top: 10px;
    }
    .left-side {
      width: 94%;
      margin: 0;
      height: 84vh;
      padding: 5px 0px 10vh 0px;
    }
    .right-side {
      position: fixed;
      width: 100%;
      bottom: 0;
      margin: 0;
      background: ${({ theme }) =>
        theme.addOrders.itemSelectionMobile.backgroundColor};
      height: 85vh;
      padding-bottom: 70px;
      box-shadow: ${({ theme }) =>
        theme.addOrders.itemSelectionMobile.boxShadow};
    }
  }
`;

import { appActions } from "app.state";
import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch } from "react-redux";
import { ShimmerEffect } from "SharedComponents/ShimmerEffect";
import styled from "styled-components";
import { toggleItemOff } from "utils/addRemoveItems";
import dateFormatter from "utils/dateFormatter";
import { filterItemsByType } from "utils/getItemTypes";
import searchItems from "utils/searchItems";

export default function ItemSelection({
  paymentTypesReqData,
  itemTypesList,
  itemsReq,
  evaluateSelectedItems,
  paymentTypeId,
  setPaymentTypeId,
  formSubmitted,
  itemsListForSelection,
  setItemsListForSelection,
  current,
  setCurrent,
  setShowItemSelection,
  isLargeScreen,
  selectedItems,
}) {
  const formElementsVariants = {
    hidden: { opacity: 0, scale: 0.97 },
    visible: { opacity: 1, scale: 1 },
  };
  const [filterItemsBy, setFilterItemsBy] = useState("");
  const inputEl = useRef(null);
  const dispatch = useDispatch();
  // eslint-disable-next-line no-unused-vars
  const [inputFocused, setInputFocused] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [inputHovered, setInputHovered] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [searchText, setSearchText] = useState("");
 
  const [count, setCount] = useState({
    prev: 0,
    next: 10
  })
  const [hasMore, setHasMore] = useState(true);
  const { t } = useTranslation();

  // if(itemsReq.isSuccess && current.length === 0 ){
  //   setCurrent(itemsListForSelection.slice(count.prev, count.next))
  // }
  
  const getMoreData = () => {
    if (current.length === itemsListForSelection.length) {
      setHasMore(false);
      return;
    }
    setTimeout(() => {
      setCurrent(current.concat(itemsListForSelection.slice(count.prev + 10, count.next + 10)))
    }, 2000)
    setCount((prevState) => ({ prev: prevState.prev + 10, next: prevState.next + 10 }))
  }

  const toggleSelectedItem = (index) => {
    const itemsList = [...current];
    /**
     * Dont allow items to be added if the items list length
     * is 90
     */
    if (selectedItems.length >= 90 && !itemsList[index].selected) {
      dispatch(
        appActions.showNotification({
          type: "ERROR",
          message: "You cant add more than 90 items",
        })
      );
    } else {
      itemsList[index].selected = !itemsList[index].selected;
      setCurrent([...itemsList]);
      evaluateSelectedItems(itemsList[index]);
    }
  };

  // eslint-disable-next-line no-unused-vars
  const turnOffItem = (selectedItem) => {
    setItemsListForSelection(
      toggleItemOff(selectedItem, itemsListForSelection)
    );
  };

  const onSearchChange = (event) => {
    setSearchText(event.target.value);
    setItemsListForSelection(searchItems(itemsReq.data, event.target.value));
    setCount({ prev: 0, next: 10 });
    setCurrent(searchItems(itemsReq.data, event.target.value).slice(0, 10));
  };

  /**
   * sets the input ref to focused on (⌘+K) click
   */
  const handleKeyboardShortcut = () => {
    const keysPressed = {};
    document.addEventListener("keydown", (event) => {
      keysPressed[event.key] = true;
      // eslint-disable-next-line dot-notation
      if (keysPressed["Meta"] && event.key === "k") {
        inputEl.current.focus();
      }
    });

    document.addEventListener("keyup", (event) => {
      delete keysPressed[event.key];
    });
  };

  useEffect(() => {
    if (itemsListForSelection.length === 0 && itemsReq?.data?.length > 0) {
      setItemsListForSelection(
        itemsReq.data.map((el) => ({ ...el, selected: false }))
      );
    }
    handleKeyboardShortcut();
  }, [itemsReq.data]);

  useEffect(() => {
    if (filterItemsBy) {
      setItemsListForSelection(filterItemsByType(itemsReq.data, filterItemsBy));
      setCurrent(filterItemsByType(itemsReq.data, filterItemsBy).slice(0, 10));
      setCount({ prev: 0, next: 10 });
    }
  }, [filterItemsBy]);

  return (
    <div
      id="scrollableDiv"
      style={{
        height: '100%',
        overflow: 'auto',
      }}
    >
              
      <InfiniteScroll
        dataLength={current.length}
        next={getMoreData}
        hasMore={hasMore}
        loader={current.length > 0 && (<motion.div
          initial="hidden"
          animate="visible"
          variants={{
            hidden: { opacity: 0 },
            visible: { opacity: 1 },
          }}
          transition={{ duration: 0.7 }}
        >
          <ShimmerEffect
            width="100%"
            height="42px"
            marginTop="10px"
            borderRadius="5px"
          />
          <ShimmerEffect
            width="100%"
            height="42px"
            marginTop="10px"
            borderRadius="5px"
          />
          <ShimmerEffect
            width="100%"
            height="42px"
            marginTop="10px"
            borderRadius="5px"
          />
          <ShimmerEffect
            width="100%"
            height="42px"
            marginTop="10px"
            borderRadius="5px"
          />
        </motion.div>)}
        scrollableTarget="scrollableDiv"
        scrollThreshold="0.8"
      >
        <div
        style={{
          margin: '15px',
        }}>
      <motion.div
        className="form-element-wrapper spacing"
        initial="hidden"
        animate="visible"
        variants={formElementsVariants}
        transition={{ duration: 0.7 }}
      >
        <label htmlFor="password">{t("Select Payment Type")}</label>
        <select
          value={paymentTypeId}
          onChange={(e) => {
            setPaymentTypeId(e.target.value);
          }}
        >
          {paymentTypesReqData.map((el) => (
            <option value={el.id} key={el.id} type="button">
              {el.name}
            </option>
          ))}
        </select>
        {formSubmitted && !paymentTypeId && (
          <div className="error">{t("Required")}</div>
        )}
      </motion.div>

      {itemTypesList.length > 0 && (
        <motion.div
          className="form-element-wrapper spacing"
          initial="hidden"
          animate="visible"
          variants={formElementsVariants}
          transition={{ duration: 0.7 }}
        >
          <label htmlFor="password">{t("Filter items by type")}</label>
          <select
            onChange={(e) => {
              setFilterItemsBy(e.target.value);
            }}
            value={filterItemsBy}
          >
            <option value="All" type="button">
              All
            </option>
            {itemTypesList.map((el) => (
              <option value={el} key={el} type="button">
                {el}
              </option>
            ))}
          </select>
        </motion.div>
      )}

      <motion.div
        className="form-element-wrapper spacing search-container"
        initial="hidden"
        animate="visible"
        variants={formElementsVariants}
        transition={{ duration: 0.7 }}
      >
        <input
          placeholder="Search Items (⌘+K)"
          className="search-input"
          type="text"
          value={searchText}
          ref={inputEl}
          onFocus={() => {
            setInputFocused(true);
          }}
          onBlur={() => {
            setTimeout(() => {
              setInputFocused(false);
            }, 500);
          }}
          onChange={onSearchChange}
        />
        {searchText && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            className="close-icon"
            onClick={() => {
              setSearchText("");
              inputEl.current.focus();
              setItemsListForSelection(searchItems(itemsReq.data, ""));
              setCount({ prev: 0, next: 10 });
              setCurrent(searchItems(itemsReq.data, "").slice(0, 10));
            }}
          >
            <path d="M256 0C114.844 0 0 114.844 0 256s114.844 256 256 256 256-114.844 256-256S397.156 0 256 0zm103.54 329.374c4.167 4.165 4.167 10.919 0 15.085l-15.08 15.081c-4.167 4.165-10.919 4.165-15.086 0L256 286.167l-73.374 73.374c-4.167 4.165-10.919 4.165-15.086 0l-15.081-15.082c-4.167-4.165-4.167-10.919 0-15.085l73.374-73.375-73.374-73.374c-4.167-4.165-4.167-10.919 0-15.085l15.081-15.082c4.167-4.165 10.919-4.165 15.086 0L256 225.832l73.374-73.374c4.167-4.165 10.919-4.165 15.086 0l15.081 15.082c4.167 4.165 4.167 10.919 0 15.085l-73.374 73.374 73.373 73.375z" />
          </svg>
        )}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 513.28 513.28"
          className="search-icon"
        >
          <path d="M495.04 404.48L410.56 320c15.36-30.72 25.6-66.56 25.6-102.4C436.16 97.28 338.88 0 218.56 0S.96 97.28.96 217.6s97.28 217.6 217.6 217.6c35.84 0 71.68-10.24 102.4-25.6l84.48 84.48c25.6 25.6 64 25.6 89.6 0 23.04-25.6 23.04-64 0-89.6zM218.56 384c-92.16 0-166.4-74.24-166.4-166.4S126.4 51.2 218.56 51.2s166.4 74.24 166.4 166.4S310.72 384 218.56 384z" />
        </svg>
      </motion.div>
      {itemsReq.isLoading && (
        <motion.div
          initial="hidden"
          animate="visible"
          variants={{
            hidden: { opacity: 0 },
            visible: { opacity: 1 },
          }}
          transition={{ duration: 0.7 }}
        >
          <ShimmerEffect
            width="100%"
            height="42px"
            marginTop="10px"
            borderRadius="5px"
          />
          <ShimmerEffect
            width="100%"
            height="42px"
            marginTop="10px"
            borderRadius="5px"
          />
          <ShimmerEffect
            width="100%"
            height="42px"
            marginTop="10px"
            borderRadius="5px"
          />
          <ShimmerEffect
            width="100%"
            height="42px"
            marginTop="10px"
            borderRadius="5px"
          />
        </motion.div>
      )}
      {itemsReq.isSuccess && (
        <motion.div
          className="form-element-wrapper spacing"
          initial="hidden"
          animate="visible"
          variants={{
            hidden: { opacity: 0 },
            visible: { opacity: 1 },
          }}
          transition={{ duration: 0.7, delay: 1.4 }}
        >
          <ul className="items-list">
            {current.map((item, index) => (
              <ListItem key={item.id} selected={item.selected}>
                {" "}
                {/* <label className="switch">
                <input
                  type="checkbox"
                  checked={item.selected}
                  onChange={() => {
                    toggleSelectedItem(index, item);
                  }}
                />
                <span className="slider round" />
              </label>{" "} */}
                <label className="checkbox">
                  <span className="checkbox__input">
                    <input
                      type="checkbox"
                      name="checkbox"
                      checked={item.selected}
                      onChange={() => {
                        toggleSelectedItem(index, item);
                      }}
                    />
                    <span className="checkbox__control">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                        focusable="false"
                      >
                        <path
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="3"
                          d="M1.73 12.91l6.37 6.37L22.79 4.59"
                        />
                      </svg>
                    </span>
                  </span>
                </label>
                <p
                  onClick={() => {
                    toggleSelectedItem(index, item);
                  }}
                >
                  {item.fullItemName}{" "}
                  {item.manufacturer && `- ${item.manufacturer}`}{" "}
                  {item.expiryDate &&
                    `- ${dateFormatter.yearMonthDate(item.expiryDate)}`}
                </p>
              </ListItem>
            ))}
          </ul>
        </motion.div>
      )}
      {!isLargeScreen && (
        <motion.button
          whileHover={{ scale: 1.01 }}
          whileTap={{ scale: 0.97 }}
          type="button"
          onClick={() => {
            setShowItemSelection(false);
          }}
          className="blue-button finish-selection-button button-spacing finish-selection-button"
        >
          {t("Finish Selection")}
        </motion.button>
      )} 
      </div>
    </InfiniteScroll>
    </div>
  );
}

const ListItem = styled.li`
  background: ${({ selected, theme }) =>
    selected ? theme.COLORS.primary10029 : "transparent"};
  box-shadow: ${({ selected, theme }) =>
    selected ? `0px 0px 5px inset ${theme.COLORS.primary10029}` : "none"};
  transition: 0.5s all ease;
  &:hover {
    background: ${({ theme }) => theme.COLORS.primary10020};
  }

  .checkbox {
    display: grid;
    grid-template-columns: min-content auto;
    grid-gap: 0.5em;
    font-size: 2rem;
    color: var(--color);

    &--disabled {
      color: var(--disabled);
    }
  }

  .checkbox__control {
    display: inline-grid;
    width: 20px;
    height: 20px;
    border-radius: 0.25em;
    border: 0.1em solid currentColor;
    padding: 3px;
    box-sizing: border-box;
    svg {
      transition: transform 0.1s ease-in 25ms;
      transform: scale(0);
      transform-origin: bottom left;
    }
  }

  .checkbox__input {
    display: grid;
    grid-template-areas: "checkbox";

    > * {
      grid-area: checkbox;
    }

    input {
      opacity: 0;
      width: 20px;
      height: 20px;

      &:focus + .checkbox__control {
        /* box-shadow: 0 0 0 0.05em #fff, 0 0 0.15em 0.1em currentColor; */
      }

      &:checked + .checkbox__control svg {
        transform: scale(1);
      }

      &:disabled + .checkbox__control {
        color: var(--disabled);
      }
    }
  }
`;


import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Icons from "components/Icons";

const SelectedOrderStatus = ({ requestStatus }) => {
  const { t } = useTranslation();
  let statusIcon;
  switch (requestStatus) {
    case "Submitted":
      statusIcon = <Icons.Submitted />;
      break;
    case "Draft":
      statusIcon = <Icons.Drafted />;
      break;
    case "Rejected":
      statusIcon = <Icons.Rejected />;
      break;
    case "Approved":
      statusIcon = <Icons.Approved />;
      break;
    case "PickListed":
      statusIcon = <Icons.Picklist />;
      break;
    default:
      break;
  }
  return (
    <Wrapper>
      {statusIcon}
      <p>{t(requestStatus)}</p>
    </Wrapper>
  );
};

export default SelectedOrderStatus;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  svg {
    height: 50px;
    fill: ${({ theme }) => theme.COLORS.primary200};
  }
  p {
    font-family: "Fredoka One", cursive;
    font-size: 20px;
    letter-spacing: 1px;
    color: ${({ theme }) => theme.COLORS.primary};
  }
`;

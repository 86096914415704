import styled from "styled-components";
import { useMutation } from "react-query";
import requests from "utils/requests";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { appActions } from "app.state";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import ForgotPasswordForm from "./ForgotPasswordForm";
import { ReactComponent as ForgotPasswordIllustration } from "./forgotPasswordIllustration.svg";
import VerificationCode from "./VerificationCode";
import NewPasswordForm from "./NewPasswordForm";

export default function ForgotPassword() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const [signInInfo, setSignInInfo] = useState(null);
  const [verificationCodeData, setVerificationCodeData] = useState(null);
  const [forgotPasswordMutation, forgotPasswordRequestStatus] = useMutation(
    requests.forgotPassword
  );

  const [resetPasswordMutation, resetPasswordRequestStatus] = useMutation(
    requests.resetPassword,
    {
      onSuccess: () => {
        dispatch(
          appActions.showNotification({
            type: "SUCCESS",
            message: "Password Reseted",
          })
        );
        history.push("/welcome");
      },
    }
  );

  useEffect(() => {
    if (resetPasswordRequestStatus.isError) {
      const errorMessage =
        resetPasswordRequestStatus?.error?.response?.data?.errors[0] ||
        "Reset Password Error";
      dispatch(
        appActions.showNotification({
          type: "ERROR",
          message: errorMessage,
        })
      );
    }
  }, [resetPasswordRequestStatus.isError]);

  return (
    <Wrapper>
      <div className="white-wrappper">
        <ForgotPasswordIllustration />
        <h1 className="title">{t("Forgot Password?")}</h1>
        <p className="message">
          {t("Forgot your password? We can help! A four digit verification code will be sent to your phone number.")}
        </p>

        {!forgotPasswordRequestStatus.isSuccess && (
          <ForgotPasswordForm
            forgotPasswordMutation={forgotPasswordMutation}
            forgotPasswordRequestStatus={forgotPasswordRequestStatus}
            setSignInInfo={setSignInInfo}
          />
        )}

        {forgotPasswordRequestStatus.isSuccess && !verificationCodeData && (
          <VerificationCode
            signInInfo={signInInfo}
            setVerificationCodeData={setVerificationCodeData}
          />
        )}

        {forgotPasswordRequestStatus.isSuccess && verificationCodeData && (
          <NewPasswordForm
            resetPasswordRequestStatus={resetPasswordRequestStatus}
            resetPasswordMutation={resetPasswordMutation}
            verificationCodeData={verificationCodeData}
            signInInfo={signInInfo}
          />
        )}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  background: #5a1c47;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(p) => p.theme.COLORS.primary100};
  .white-wrappper {
    width: 50%;
    height: 100vh;
    background-color: ${(p) => p.theme.forgotPassword.background};
    max-width: 700px;
    min-width: 500px;
    padding: 30px 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .illustration {
    /* height: 40px; */
  }

  .title {
    margin: 10px 0px;
    font-family: "Fredoka One", cursive;
    font-size: 24px;
    letter-spacing: 2px;
  }

  .message {
    width: 100%;
    max-width: 500px;
    text-align: center;
    letter-spacing: 1px;
  }

  .form-element-wrapper {
    display: flex;
    flex-direction: column;
    color: ${({ theme }) => theme.COLORS.primary100};
    padding: 0px;
    font-size: 16px;
    font-weight: 400;
    margin: 0px 0px;
    position: relative;
    label {
      font-size: 18px;
      position: absolute;
    }
    input {
      border: none;
      border-bottom: 1px solid ${({ theme }) => theme.COLORS.primary10029};
      outline: none;
      color: ${({ theme }) => theme.COLORS.black};
      transition: 0.5s all ease;
      background-color: transparent;
      padding-top: 0px;
      &:focus {
        border-bottom: 1px solid #d53f8c;
      }
      &:-webkit-autofill {
        -webkit-box-shadow: ${({ theme }) =>
          theme.LANDING.input.webkitBoxShadow};
        color: ${({ theme }) => theme.COLORS.black};
      }
    }
  }

  .error {
    margin-top: 7px;
    font-size: 14px;
    color: #d53f8c;
    position: absolute;
    bottom: -20px;
  }

  .spacing {
    margin: 30px 0px;
  }

  @media screen and (max-width: 528px) {
    .white-wrappper {
      width: 100%;
      min-width: 100%;
    }
  }

  @media screen and (max-width: 400px) {
    .illustration {
      min-height: 150px;
    }
    .title {
      font-family: Montserrat;
      font-weight: bold;
      font-size: 18px;
    }

    .message {
      font-size: 16px;
      letter-spacing: 0px;
    }
  }
`;

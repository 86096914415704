import styled from "styled-components";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FacilitySearch from "Landing/LoginAndSignUp/FacilitySearch";
import { useState } from "react";
import Input from "SharedComponents/Input";
import { useTranslation } from "react-i18next";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input/input";

export default function ForgotPasswordForm({
  forgotPasswordMutation,
  forgotPasswordRequestStatus,
  setSignInInfo
}) {
  const { t } = useTranslation();
  const [selectedFacility, setSelectedFacility] = useState(null);
  const [facilityRequired, setFacilityRequired] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [phoneNumberTouched, setPhoneNumberTouched] = useState(false);

  const onFormSubmit = async ({ userName }) => {
    setSignInInfo({phoneNumber, userName, facilityId: selectedFacility?.id});
    await forgotPasswordMutation({
      phoneNumber,
      userName,
      facilityId: selectedFacility?.id,
    });
  };

  return (
    <Wrapper>
      <Formik
        initialValues={{
          userName: "",
        }}
        validationSchema={Yup.object({
          userName: Yup.string().required("Required"),
        })}
        onSubmit={(values) => {
          onFormSubmit(values);
        }}
      >
        {(props) => (
          <Form>
            <div className="from-element-width">
              <FacilitySearch
                setSelectedFacility={setSelectedFacility}
                facilityRequired={facilityRequired}
                setFacilityRequired={setFacilityRequired}
              />
              {props.submitCount > 0 && !selectedFacility?.id && (
                <div className="error px search-error">Required</div>
              )}
            </div>

            <div className="from-element-width my">
              <Input label={t("Username")} name="userName" type="text" />
            </div>

            <label className="phone-label">{t("Phone Number")}</label>
            <div className="form-element-wrapper from-element-width px">
              <PhoneInput
                country="ET"
                international
                withCountryCallingCode
                value={phoneNumber}
                onChange={setPhoneNumber}
                onBlur={() => {
                  setPhoneNumberTouched(true);
                }}
              />
              {phoneNumberTouched &&
                phoneNumber &&
                !isValidPhoneNumber(phoneNumber) && (
                  <div className="error">Invalid phone number</div>
                )}
              {props.submitCount > 0 && !phoneNumber && (
                <div className="error">Required</div>
              )}
            </div>

            <div className="spacing full-width">
              <div>
                <button type="submit" className="blue-button">
                  {forgotPasswordRequestStatus.isLoading
                    ? "Just a moment..."
                    : "Send Code"}
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  .spacing {
    margin: 50px 0px !important;
  }

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
  }

  .from-element-width {
    width: 100%;
    max-width: 400px;
    position: relative;
    .search-error {
      bottom: -5px;
      left: -10px;
    }
  }

  .px {
    padding: 0px 20px !important;
    box-sizing: border-box;
  }

  .phone-label {
    font-size: 18px;
    width: 100%;
    max-width: 400px;
    padding: 30px 20px 0px 20px;
    box-sizing: border-box;
  }

  .my{
    margin-top: 20px;
  }
`;

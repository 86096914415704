import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { ReactComponent as FirstImage } from "resources/landingPage/01.svg";
import { ReactComponent as SecondImage } from "resources/landingPage/02.svg";
import { ReactComponent as ThirdImage } from "resources/landingPage/03.svg";
import { ReactComponent as FourthImage } from "resources/landingPage/04.svg";
import { ReactComponent as FifthImage } from "resources/landingPage/05.svg";
import { ReactComponent as SixthImage } from "resources/landingPage/06.svg";
import { useTranslation } from "react-i18next";

const variants = {
  hidden: { opacity: 0, scale: 0.5 },
  visible: { opacity: 1, scale: 1 },
};

const LevelOne = ({ message }) => (
  <motion.div
    initial="hidden"
    animate="visible"
    variants={variants}
    transition={{ duration: 0.7, type: "spring", stiffness: 100 }}
  >
    <FirstImage />
    <p>{message}</p>
  </motion.div>
);

const LevelTwo = ({ message }) => (
  <motion.div
    initial="hidden"
    animate="visible"
    variants={variants}
    transition={{ duration: 0.7 }}
  >
    <SecondImage />
    <p>{message}</p>
  </motion.div>
);

const LevelThree = ({ message }) => (
  <motion.div
    initial="hidden"
    animate="visible"
    variants={variants}
    transition={{ duration: 0.7 }}
  >
    <ThirdImage />
    <p>{message}</p>
  </motion.div>
);

const LevelFour = ({ message }) => (
  <motion.div
    initial="hidden"
    animate="visible"
    variants={variants}
    transition={{ duration: 0.7 }}
  >
    <FourthImage />
    <p>{message}</p>
  </motion.div>
);

const LevelFive = ({ message, label }) => (
  <motion.div
    initial="hidden"
    animate="visible"
    variants={variants}
    transition={{ duration: 0.7 }}
  >
    <FifthImage />
    <p>{message}</p>
    <button type="submit" className="blue-button button-wrapper"onClick={() => {window.open(`https://csm.fanosonline.org/product-availability`, "_blank")}}>{label}</button>
  </motion.div>
);

const LevelSix = ({ message, label }) => (
  <motion.div
    initial="hidden"
    animate="visible"
    variants={variants}
    transition={{ duration: 0.7 }}
  >
    <SixthImage />
    <p>{message}</p>
    <button type="submit" className="blue-button button-wrapper"onClick={() => {window.open(`http://arrivals.fanosonline.org/new-arrivals`, "_blank")}}>{label}</button>
  </motion.div>
);

export default function LandingCarousel() {
  const [currentCarouselIndex, setCurrentCarouselIndex] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    const carouselTimeOut = setTimeout(() => {
      if (currentCarouselIndex === 5) {
        setCurrentCarouselIndex(0);
      } else {
        setCurrentCarouselIndex(currentCarouselIndex + 1);
      }
    }, 5000);

    return () => {
      clearTimeout(carouselTimeOut);
    };
  }, [currentCarouselIndex]);

  return (
    <Wrapper>
      {currentCarouselIndex === 0 && (
        <LevelOne message={t("Its Easy, Simple and Time saving")} />
      )}
      {currentCarouselIndex === 1 && (
        <LevelTwo message={t("Process your product request forms online")} />
      )}
      {currentCarouselIndex === 2 && (
        <LevelThree
          message={t(
            "Submit your requests to EPSS and follow up on the status"
          )}
        />
      )}
      {currentCarouselIndex === 3 && (
        <LevelFour message={t("Preview previous requests")} />
      )}
      {currentCarouselIndex === 4 && (
        <LevelFive message={t("View available products")} label={t("Show")} />
      )}
      {currentCarouselIndex === 5 && (
        <LevelSix message={t("View new arrived items")} label={t("Show")} />
      )}
      <section className="dotes-wrapper">
        <CircleIndicator isActive={currentCarouselIndex === 0} />
        <CircleIndicator isActive={currentCarouselIndex === 1} />
        <CircleIndicator isActive={currentCarouselIndex === 2} />
        <CircleIndicator isActive={currentCarouselIndex === 3} />
        <CircleIndicator isActive={currentCarouselIndex === 4} />
        <CircleIndicator isActive={currentCarouselIndex === 5} />
      </section>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 530px;
    margin-top: 100px;
  }
  svg {
    height: 350px;
  }
  p {
    font-size: 24px;
    color: ${({ theme }) => theme.COLORS.primary100};
    text-align: center;
    height: 50px;
  }
  .button-wrapper {
    margin-bottom: 1em;
  }
  .dotes-wrapper {
    display: flex;
    width: 530px;
    justify-content: center;
    align-items: center;
    height: 14px;
    margin-top: -60px;
  }

  .illustration-background {
    fill: ${({ theme }) => theme.LANDING.illustration.background};
  }

  .illustration-primary {
    fill: ${({ theme }) => theme.LANDING.illustration.fill};
  }

  .dark-border {
    stroke: ${({ theme }) => theme.COLORS.primary};
  }

  .dark-fill {
    fill: ${({ theme }) => theme.LANDING.illustration.darkFill};
  }
  @media screen and (max-width: 1025px) {
    div {
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .dotes-wrapper {
      margin-top: -30px;
    }
  }
  @media screen and (max-width: 540px) {
    div,
    .dotes-wrapper {
      width: 96%;
    }
    svg {
      height: auto;
      width: 90%;
    }
    p {
      font-size: 16px;
    }
  }
  @media screen and (max-width: 430px) {
    .dotes-wrapper {
      /* margin-top: 0px; */
    }
  }
`;

const CircleIndicator = styled.div`
  width: 14px !important;
  height: 14px;
  border-radius: 9999%;
  background-color: ${({ isActive, theme }) =>
    isActive ? theme.COLORS.primary100 : theme.LANDING.crouselDotes.background};
  margin: 0px 10px;

  @media screen and (max-width: 625px) {
    background-color: ${({ isActive, theme }) =>
      isActive
        ? theme.COLORS.primary100
        : theme.LANDING.crouselDotes.background};
  }
`;

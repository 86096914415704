import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";

export default function RouteTitle() {
  const location = useLocation();
  const history = useHistory();
  const [title, setTitle] = useState("");

  const setTitleFromUrl = (url) => {
    switch (url) {
      case "/orders/draft":
        setTitle("Draft");
        break;
      case "/orders/pending":
        setTitle("Pending");
        break;
      case "/orders/submitted":
        setTitle("Submitted");
        break;
      case "/orders/approved":
        setTitle("Approved");
        break;
      case "/orders/picklisted":
        setTitle("Picklisted");
        break;
      case "/orders/rejected":
        setTitle("Rejected");
        break;
      case "/stockedouts":
        setTitle("Stockout");
        break;
      case "/add-order":
        setTitle("Add/Update");
        break;
      case "/":
        setTitle("Home");
        break;
      default:
        setTitle("");
        break;
    }
  };
  useEffect(() => {
    const unlisten = history.listen(({ pathname }) => {
      setTitleFromUrl(pathname);
    });

    return () => {
      unlisten();
    };
  }, []);

  useEffect(() => {
    setTitleFromUrl(location.pathname);
  });

  return (
    <Wrapper>
      <p>{title}</p>
    </Wrapper>
  );
}
const Wrapper = styled.div`
  p {
    padding: 0px 4px 0px 0px;
    font-family: "Fredoka One", cursive;
    font-size: 20px;
    margin: 0px 2px 0px -50px;
    letter-spacing: 1px;
    text-align: left;
  }
`;

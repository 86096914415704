import { useQuery } from "react-query";
import styled from "styled-components";
import { fetchAllRequests } from "utils/requests";
import SearchDesktop from "./SearchDesktop/SearchDesktop";

export default function Search() {
  const allRequestsReq = useQuery("All requests", fetchAllRequests);
  return (
    <Wrapper>
      <SearchDesktop allRequestsReq={allRequestsReq} />
    </Wrapper>
  );
}
const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

import { useEffect, useLayoutEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ErrorSuccessNotification from "components/ErrorSuccessNotification";
import ProtectedRoute from "ProtectedRoute";
import { ThemeProvider } from "styled-components";
import darkThemeStyle from "utils/dark.theme";
import lightThemeStyle from "utils/light.theme";
import { useDispatch, useSelector } from "react-redux";
import { selectors } from "configureStore";
import useDetectColorScheme from "components/useDetectColorScheme";
import { useTranslation } from "react-i18next";
import { appActions } from "app.state";
import OrderDetails from "Home/OrderDetails/OrderDetails";
import { useInstallPWAprompt } from "hooks/useInstallPWAprompt";
import useMedia from "use-media";
import ForgotPassword from "ForgotPassword/ForgotPassword";
import Landing from "./Landing/Landing";
import { GlobalStyle } from "./styles/normalize";
import { http } from "./utils/http";
import Home from "./Home/Home";
import Constants from "./utils/constants"

export default function App() {
  // useGApageViews();
  useInstallPWAprompt();
  const app = useSelector(selectors.app);
  const operatingSystemThemeChangeValue = useDetectColorScheme("light");
  const theme = localStorage.getItem("theme");
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const isLargeScreen = useMedia({ minWidth: "950px" });

  useLayoutEffect(() => {
    // calling base url setter from http service
    http.setBaseUrl();
    const language = localStorage.getItem("language");
    dispatch(appActions.setLanguage(language));
  }, []);

  useEffect(() => {
    if (!theme) {
      dispatch(appActions.setTheme("light"));
    } else if (theme === "auto") {
      const value = window
        .getComputedStyle(document.documentElement)
        .getPropertyValue("content")
        .replace(/"/g, "");
      dispatch(appActions.setTheme(value));
    } else {
      dispatch(appActions.setTheme(theme));
    }
  }, [operatingSystemThemeChangeValue]);

  useEffect(() => {
    i18n.changeLanguage(app.language);
  }, [app.language]);

  

  return (
    <Router>
      <ThemeProvider
        theme={app.theme === "dark" ? darkThemeStyle : lightThemeStyle}
      >
        <GlobalStyle />
        <ErrorSuccessNotification />
        {app.selectedRequest && (
          <OrderDetails
            selectedRequest={app.selectedRequest}
            selectedRequestData={app.selectedRequestData}
            isLargeScreen={isLargeScreen}
          />
        )}
        <Switch>
          <Route path="/welcome">
            <Landing userRole={Constants.buyer}/>
          </Route>
          <Route path="/forgot-password">
            <ForgotPassword/>
          </Route>
          <Route path="/admin">
            <Landing  userRole={Constants.admin}/>
          </Route>
          <ProtectedRoute path="/">
            <Home />
          </ProtectedRoute>
        </Switch>
      </ThemeProvider>
    </Router>
  );
}

import Axios from "axios";

export const loginRequest = async (data) => {
  const response = await Axios.post("/Account/Login", data);
  return response.data;
};

export const adminLoginRequest = async (data) => {
  const response = await Axios.post("/Admin/Login", data);
  return response.data;
};

export const generateOtpRequest = async (data) => {
  const response = await Axios.post("/Account/GenerateOTP", data);
  return response.data;
};

const forgotPassword = async (data) => {
  const response = await Axios.post("/Account/ForgotPassword", data);
  return response.data;
};

const resetPassword = async (data) => {
  const response = await Axios.post("/Account/ResetPassword", data);
  return response.data;
};

const changePassword = async (data) => {
  const response = await Axios.post("/Account/ChangePassword", data);
  return response.data;
};

export const signUpRequest = async (data) => {
  const response = await Axios.post("/Account/Register", data);
  return response;
};

export const fetchHubsList = async () => {
  const response = await Axios.get("/Lookups/Hubs");
  return response;
};

const fetchGroupTypes = async () => {
  const response = await Axios.get("/Lookups/GroupTypes");
  return response.data;
};

export const searchInstitutions = async (name, tinNumber) => {
  if (!tinNumber) return null;
  const response = await Axios.get(`/Lookups/Institutions?Search=${tinNumber}`);
  if (response.data.length > 50) {
    response.data.length = 50;
  }
  return response.data;
};

export const fetchRequestCountByStatus = async () => {
  const response = await Axios.get(`/Reports/GetRequestCountByStatus`);
  return response.data;
};

export const fetchItems = async (name, commodityType) => {
  if (!commodityType) return null;
  const response = await Axios.get(`/Lookups/Items?GroupType=${commodityType}`);
  return response.data;
};

export const fetchAllItems = async () => {
  const response = await Axios.get(`/Lookups/Items`);
  return response.data;
};

export const fetchPaymentTypes = async () => {
  const response = await Axios.get(`/Lookups/PaymentTypes`);
  return response.data;
};

export const addOrderRequest = async (data) => {
  const response = await Axios.post("/Requests", data);
  return response;
};

export const updateOrderRequest = async (data) => {
  const response = await Axios.put(`/Requests/${data.id}`, data);
  return response;
};

export const fetchAllRequests = async () => {
  const response = await Axios.get(`/Requests`);
  return response.data;
};

export const fetchFullRequestDetails = async (name, id) => {
  if (!id) return null;
  const response = await Axios.get(`/Requests/${id}`);
  return response.data;
};

export const fetchRequestDetails = async (name, id) => {
  if (!id) return null;
  const response = await Axios.get(`/Requests/${id}/RequestDetails`);
  return response.data;
};

export const fetchOrderDetails = async (name, id) => {
  if (!id) return null;
  const response = await Axios.get(`/Requests/${id}/OrderDetails`);
  return response.data;
};

export const fetchStrockedOutRequests = async (name, id) => {
  if (!id) return null;
  const response = await Axios.get(`/Requests/${id}/StockedOuts`);
  return response.data;
};

export const fetchRequestStatusChanges = async () => {
  const response = await Axios.get(`/Notifications/RequestStatusChanges`);
  return response.data;
};

export const markNotificationAsSeen = async (data) => {
  const { id, requestId } = data;
  const response = await Axios.post(
    `/Notifications/RequestStatusChanges/${id}/MarkAsSeen`,
    {
      requestId,
    }
  );
  return response.data;
};

export const fetchRequestStatusLogs = async (name, id) => {
  const response = await Axios.get(`/Requests/${id}/RequestStatusLogs`);
  return response.data;
};

export const deleteOrder = async (id) => {
  const response = await Axios.delete(`/Requests/${id}`);
  return response.data;
};

export const fetchStockoutOrders = async () => {
  const response = await Axios.get(`/Requests/WithStockedOuts`);
  return response.data;
};

const picklist = async (request) => {
  const { id, order } = request;
  const response = await Axios.put(`/Requests/${id}/PickList`, order);
  return response.data;
};

export const fetchStatusReport = async (name, OrderNo, StartDate, EndDate, HubId, FacilityId, Page, PageSize) => {
  const orderNoParam = OrderNo? `OrderNo=${OrderNo}&`: "";
  const startDateParam = StartDate? `StartDate=${StartDate}&`: "";
  const EndDateParam = EndDate? `EndDate=${EndDate}&`: "";
  const hubIdParam = HubId? `HubId=${HubId}&`: "";
  const FacilityIdParam = FacilityId? `FacilityId=${FacilityId}&`: "";
  const response = await Axios.get(`/Reports/GetOnlineRequests?${orderNoParam}${startDateParam}${EndDateParam}${hubIdParam}${FacilityIdParam}Page=${Page}&PageSize=${PageSize}`);
  return response.data;
};

export const fetchFacilityReport = async (name, FacilityId, UsernameSearch, Page, PageSize) => {
  const FacilityIdParam = FacilityId? `FacilityId=${FacilityId}&`: "";
  const usernameSearchParam = UsernameSearch? `Search=${UsernameSearch}&`: "";
  const response = await Axios.get(`/Reports/GetFacilityUsers?${FacilityIdParam}${usernameSearchParam}Page=${Page}&PageSize=${PageSize}`);
  return response.data;
};

export const fetchTopRequests = async () => {
  const response = await Axios.get(`/Reports/GetTopRequestedItems`);
  return response.data;
};

export const fetchAggregateFacilityReport = async (name, FacilityId, Page, PageSize) => {
  const FacilityIdParam = FacilityId? `FacilityId=${FacilityId}&`: "";
  const response = await Axios.get(`/Reports/GetFacilityUsersCounts?${FacilityIdParam}Page=${Page}&PageSize=${PageSize}`);
  return response.data;
};

const requests = {
  loginRequest,
  adminLoginRequest,
  generateOtpRequest,
  signUpRequest,
  fetchHubsList,
  searchInstitutions,
  fetchRequestCountByStatus,
  fetchItems,
  fetchAllItems,
  fetchPaymentTypes,
  addOrderRequest,
  updateOrderRequest,
  fetchAllRequests,
  fetchRequestDetails,
  fetchOrderDetails,
  fetchStrockedOutRequests,
  fetchRequestStatusChanges,
  markNotificationAsSeen,
  fetchRequestStatusLogs,
  deleteOrder,
  fetchStockoutOrders,
  forgotPassword,
  resetPassword,
  changePassword,
  fetchGroupTypes,
  picklist,
  fetchStatusReport
};

export default requests;

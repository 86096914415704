import { selectors } from "configureStore";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { appActions } from "app.state";

export default function ErrorSuccessNotification() {
  const app = useSelector(selectors.app);
  const dispatch = useDispatch();

  const nontificationVariants = {
    hidden: { opacity: 1, y: 80, scale: 0.5 },
    visible: { opacity: 1, y: 0, scale: 1 },
    exit: { opacity: 1, y: 80, scale: 0.3 },
  };

  useEffect(() => {
    if (app.showNotification) {
      setTimeout(() => {
        dispatch(appActions.hideNotification());
      }, 5000);
    }
  }, [app.showNotification]);
  const { type, title, message } = app.notificationContent;
  return (
    <>
      <AnimatePresence>
        {app.showNotification && (
          <Wrapper
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={nontificationVariants}
            transition={{ duration: 0.7 }}
            type={type}
          >
            <h3>{title || (type === "SUCCESS" ? "Success" : "Error")}</h3>
            <p>{message}</p>
          </Wrapper>
        )}
      </AnimatePresence>
    </>
  );
}

const Wrapper = styled(motion.div)`
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 300px;
  background-color: ${({ type }) => {
    return type === "SUCCESS" ? "#10B981" : "#d53f8c";
  }};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  box-shadow: ${({ type }) =>
    type === "SUCCESS"
      ? "0 5px 8px #10B98150"
      : "0 5px 8px rgba(213, 63, 140, 0.4)"};
  color: #ffffff;
  text-align: center;
  padding: 10px;
  box-sizing: border-box;
  z-index: 9999;
  h3 {
    margin: 0;
    font-family: "Fredoka One", cursive;
    letter-spacing: 0.5;
    letter-spacing: 1px;
  }
  p {
    margin: 0;
    margin-top: 10px;
  }

  @media screen and (max-width: 480px) {
    width: 100%;
    right: 0;
    bottom: 0;
    border-radius: 0px;
  }
`;

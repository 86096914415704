import styled from "styled-components";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Input from "SharedComponents/Input";
import { useTranslation } from "react-i18next";

export default function NewPasswordForm({
  resetPasswordRequestStatus,
  resetPasswordMutation,
  signInInfo,
  verificationCodeData,
}) {
  const { t } = useTranslation();

  const onNewPasswordSubmit = async ({ password }) => {
    const { phoneNumber, facilityId, userName } = signInInfo;
    await resetPasswordMutation({
      phoneNumber,
      facilityId,
      userName,
      code: verificationCodeData,
      newPassword: password,
    });
  };

  return (
    <Wrapper>
      <Formik
        initialValues={{
          password: "",
          confirmPassword: "confirmPassword",
        }}
        validationSchema={Yup.object({
          password: Yup.string()
            .required("Required")
            .min(8, "Password should be 8 chars minimum."),
          confirmPassword: Yup.string()
            .required("Required")
            .oneOf([Yup.ref("password"), null], "Passwords must match"),
        })}
        onSubmit={(values) => {
          onNewPasswordSubmit(values);
        }}
      >
        <Form>
          <div className="from-element-width">
            <Input label={t("New Password")} name="password" type="password" autoComplete="off" />
          </div>

          <div className="from-element-width spacing">
            <Input
              label={t("Confirm Password")}
              name="confirmPassword"
              type="password"
              autoComplete="off"
            />
          </div>

          <div className="spacing full-width">
            <div>
              <button type="submit" className="blue-button">
                {resetPasswordRequestStatus.isLoading
                  ? "Just a moment..."
                  : "Reset Password"}
              </button>
            </div>
          </div>
        </Form>
      </Formik>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .from-element-width {
    width: 100%;
    max-width: 400px;
    position: relative;
  }

  .blue-button {
    margin: 0px 20px;
  }
`;

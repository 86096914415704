import { motion } from "framer-motion";
import useOnClickOutside from "hooks/useOnClickOutside";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import radioButtons from "styles/radioButtons";

export default function SearchConfigurationDropdown({
  searchType,
  setSearchType,
  setShowConfig,
}) {
  const menuDropdown = useRef(null);
  const { t } = useTranslation();
  useOnClickOutside(menuDropdown, () => {
    setShowConfig(false);
  });

  return (
    <Wrapper
      initial={{ opacity: 0, height: 0, y: -20 }}
      animate={{ opacity: 1, height: 80, y: 0 }}
      exit={{ opacity: 0, height: 0, y: -20 }}
      transition={{ duration: 0.7 }}
      ref={menuDropdown}
    >
      <h4>{t("Search By")}</h4>
      <div className="radio-selection-wrapper">
        <label className="radio-input">
          <input
            type="radio"
            name="shipping"
            value="OrderNumber"
            checked={searchType === "OrderNumber"}
            onChange={(e) => {
              setSearchType(e.target.value);
            }}
          />
          {t("Order Number")}
          <span className="radio-circle" />
        </label>
        <label className="radio-input date-radio-selection">
          <input
            type="radio"
            name="shipping"
            value="Date"
            checked={searchType === "Date"}
            onChange={(e) => {
              setSearchType(e.target.value);
            }}
          />
          {t("Date")}
          <span className="radio-circle" />
        </label>
      </div>
    </Wrapper>
  );
}
const Wrapper = styled(motion.div)`
  position: absolute;
  top: 50px;
  background-color: ${(p) => p.theme.searchDropDown.backgroundColor};
  max-width: 565px;
  width: 100%;
  border-radius: 8px;
  box-shadow: ${(p) => p.theme.searchDropDown.boxShadow};
  padding: 10px 15px;
  box-sizing: border-box;
  z-index: 9999;
  h4 {
    font-size: 16px;
    margin: 0px;
    margin-bottom: 10px;
  }

  .radio-selection-wrapper {
    display: flex;
    margin-top: -10px;
  }

  .date-radio-selection {
    margin-left: 20px;
  }

  .radio-input {
    ${radioButtons}
  }
`;

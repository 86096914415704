/* eslint-disable import/prefer-default-export */
import { css } from "styled-components";

export const formElementWrapper = css`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.COLORS.primary100};
  padding: 0px 20px;
  font-size: 16px;
  font-weight: 400;
  margin: 10px 0px;
  position: relative;
  label {
    font-size: 18px;
    position: absolute;
  }
  input {
    border: none;
    border-bottom: 1px solid ${({ theme }) => theme.COLORS.primary10029};
    outline: none;
    color: ${({ theme }) => theme.COLORS.black};
    transition: 0.5s all ease;
    background-color: transparent;
    padding-top: 24px;
    &:focus {
      border-bottom: 1px solid #d53f8c;
    }
    &:-webkit-autofill {
      -webkit-box-shadow: ${({ theme }) => theme.LANDING.input.webkitBoxShadow};
      color: ${({ theme }) => theme.COLORS.black};
    }
  }
  select {
    border: 1px solid ${({ theme }) => theme.COLORS.primary10029};
    margin: 24px 0px 7px 0px;
    padding: 10px 10px;
    text-align: center;
    border-radius: 5px;
    transition: 0.3s all ease;
    cursor: pointer;
    width: 100%;
    color: ${({ theme }) => theme.COLORS.primary100};
    background: transparent;
    &:hover {
      background: #70245910;
    }
    &:focus {
      background: ${({ theme }) => theme.COLORS.primary10029};
      outline: none;
      border: 1px solid ${({ theme }) => theme.COLORS.primary100};
    }
    .empty-option {
      background: transparent;
      display: none;
    }
    option {
      color: #000000;
    }
  }
  .error {
    margin-top: 7px;
    font-size: 14px;
    color: #d53f8c;
    position: absolute;
    bottom: -20px;
  }
`;

/* eslint-disable no-nested-ternary */
import EmptyState from "components/EmptyState";
import RequestsList, { RequestListLoading } from "Home/OrdersList/RequestsList";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import styled from "styled-components";
import { fetchStockoutOrders } from "utils/requests";

export default function Stockouts({ setSelectedOrderType }) {
  const stockoutReq = useQuery("Stockouts", fetchStockoutOrders);
  const [selectedRequestData, setSelectedRequestData] = useState(null);
  const [selectedRequest, setSelectedRequest] = useState(null);
  useEffect(() => {
    setSelectedOrderType("stockedouts");
  }, []);
  return (
    <Wrapper>
      {stockoutReq.isLoading && <RequestListLoading />}
      {stockoutReq.isError && <div>Error</div>}
      {stockoutReq.isSuccess && stockoutReq?.data?.length === 0 ? (
        <EmptyState message="You have no stockedout orders" />
      ) : stockoutReq?.data?.length > 0 ? (
        <RequestsList
          requests={stockoutReq.data}
          selectedRequestData={selectedRequestData}
          setSelectedRequestData={setSelectedRequestData}
          selectedRequest={selectedRequest}
          setSelectedRequest={setSelectedRequest}
        />
      ) : null}
    </Wrapper>
  );
}
const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 100px !important;
`;

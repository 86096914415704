import { motion } from "framer-motion";
import styled from "styled-components";

export default function LoadingState({ message }) {
  const loadingVariants = {
    hidden: { opacity: 0, y: 0, scale: 0.5 },
    visible: { opacity: 1, y: 0, scale: 1 },
  };
  return (
    <Wrapper
      initial="hidden"
      animate="visible"
      exit="exit"
      variants={loadingVariants}
      transition={{ duration: 0.7 }}
    >
      <span className="loader" />
      <h4>{message}</h4>
    </Wrapper>
  );
}

const Wrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20%;
  h4 {
    text-align: center;
    font-family: "Fredoka One", cursive;
    font-size: 20px;
    color: ${({theme}) => theme.COLORS.primary100};
    margin: 0;
    margin-top: 7px;
    letter-spacing: 2px;
  }
  .loader {
    width: 70px;
    height: 70px;
    border: 5px solid ${({theme}) => theme.COLORS.primary10040};
    border-radius: 50%;
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
  .loader::after {
    content: "";
    box-sizing: border-box;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 62px;
    height: 62px;
    border-radius: 50%;
    border: 5px solid transparent;
    border-bottom-color: #d53f8c;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

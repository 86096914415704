import { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Icons from "components/Icons";

export default function AdminSideNav({ selectedNavType }) {
  const [tabLineVerticalPosition, setTabLineVerticalPosition] = useState(null);
  const { t } = useTranslation();
  // extract pathname from location
  const { pathname } = useLocation();

  useEffect(() => {
    const gap = 48;
    switch (selectedNavType) {
      case "status-report":
        setTabLineVerticalPosition(0);
        break;
      case "facility-report":
        setTabLineVerticalPosition(gap);
        break;
      // case "user-management":
      //   setTabLineVerticalPosition(gap * 2);
      //   break;
      default:
        setTabLineVerticalPosition(0);
        break;
    }
  }, [selectedNavType]);
  return (
    <Wrapper>
      <ul>
        <TabLine
          tabLineVerticalPosition={tabLineVerticalPosition}
          animate={{ y: tabLineVerticalPosition }}
          transition={{ duration: 0.5, ease: "easeOut" }}
        />
        
        <NavLink to="/status-report"
              isActive={() => ['/status-report', '/'].includes(pathname)}>
          <div className="icon-and-text">
            <Icons.Drafted />
            <p>{t("Status Report")}</p>
          </div>
        </NavLink>
        <NavLink to="/facility-report">
          <div className="icon-and-text">
            <Icons.Stockout />
            <p>{t("Facility Report")}</p>
          </div>
        </NavLink>
        {/* <NavLink to="/user-management">
          <div className="icon-and-text">
            <Icons.Drafted />
            <p>{t("User Management")}</p>
          </div>
        </NavLink> */}
      </ul>
    </Wrapper>
  );
}

const TabLine = styled(motion.div)`
  z-index: 1;
  height: 48px;
  width: 190px;
  border-radius: 8px;
  background-color: ${({ tabLineVerticalPosition, theme }) =>
    tabLineVerticalPosition !== null ? theme.COLORS.primary100 : null};
  position: absolute;
  top: 50px;
  left: 15px;
`;

const Wrapper = styled.div`
  z-index: 5;
  width: auto;
  height: 100vh;
  background-image: ${({ theme }) => theme.SideNav.backgroundImage};
  box-shadow: ${({ theme }) => theme.SideNav.boxShadow};
  border-right: ${({ theme }) => theme.SideNav.borderRight};
  ul {
    font-size: 14px;
    list-style-type: none;
    padding: 0;
    padding-top: 50px;
    margin: 0;
    display: flex;
    flex-direction: column;
    position: relative;
    a {
      height: 48px;
      width: 190px;
      color: ${({ theme }) => theme.COLORS.grey300};
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0px 12px 0px 12px;
      margin: 0px 15px 0px 15px;
      border-radius: 8px;
      transition: 0.3s all ease;
      z-index: 10;
      box-sizing: border-box;
      p {
        padding: none;
        margin: none;
        margin-left: 10px;
        font-size: 16px;
      }
      svg {
        width: 24px;
        fill: #9799a3;
      }
      &:hover {
        background-color: ${({ theme }) => theme.COLORS.primary10020};
      }
      .icon-and-text {
        display: flex;
        align-items: center;
      }
      .count {
        margin-left: 10px;
        color: ${({ theme }) => theme.COLORS.grey10060};
        text-align: right;
        float: right;
      }
      &.active {
        color: ${({ theme }) => theme.COLORS.white};
        .count {
          color: ${({ theme }) => theme.COLORS.white};
        }
        svg {
          fill: ${({ theme }) => theme.COLORS.white};
        }
      }
    }
    
    .activeClass {
        color: ${({ theme }) => theme.COLORS.white};
        .count {
          color: ${({ theme }) => theme.COLORS.white};
        }
        svg {
          fill: ${({ theme }) => theme.COLORS.white};
        }
      }
  }
`;

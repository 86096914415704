export default {
  COLORS: {
    primary: "#FFF5F7",
    primary100: "#FFF5F7",
    primary10010: "#FFF5F710",
    primary10020: "#FFF5F720",
    primary10029: "#FFF5F729",
    primary10033: "#FFF5F733",
    primary10040: "#FFF5F740",
    primary10050: "#FFF5F750",
    primary10060: "#FFF5F760",
    primary10080: "#FFF5F780",
    primary10090: "#FFF5F790",
    primary10096: "#FFF5F796",
    primary200: "#FFFFFF50",
    primary900: "#2D3748",
    black: "#fff",
    blue: "#4399E1",
    white: "#5A1C47",
    grey100: "#FFFFFF",
    grey10060: "#FFFFFF60",
    grey300: "#ffffff80",
    red: "#EF4444",
    red40: "#EF444440",
    red10: "#252f3b",
  },
  LANDING: {
    backgroundImage: "linear-gradient(to bottom, #2D3748, #2A3445)",
    mobileBackgroundImage: "linear-gradient(to bottom, #2D3748, #2D3748)",
    logoBackgroundImage: "linear-gradient(to bottom,#FFF5F7,#FFF5F7)",
    boxShadow: "2px 9px 18px #161B2428",
    crouselDotes: {
      background: "#A0AEC0",
    },
    illustration: {
      background: "#FFFFFF",
      fill: "#FFF5F7",
      darkFill: "#2B6CB0",
    },
    card: {
      backgroundImage: "linear-gradient(to bottom right, #34405347, #344053)",
      border: "#364357",
    },
    input: {
      webkitBoxShadow: "0 0 0 30px #303A4D inset !important",
    },
  },
  BUTTON: {
    boxShadow: "0 5px 8px rgb(22 75 119 / 40%)",
  },
  HOME: {
    backgroundImage: "linear-gradient(to bottom, #2D3748, #2D3748)",
  },
  TopNav: {
    backgroundImage: "linear-gradient(to right, #34405547, #344055)",
    border: "1px solid #344055",
    boxShadow: "0px 3px 6px #29313E",
    dropdown: {
      background: "#344055",
      boxShadow: "0px 3px 6px #29313E",
      boxShadowMobile: `0px 14px 12px #252d39`,
      notificationBoxShadow: `7px 9px 10px #29313E`,
    },
  },
  SideNav: {
    backgroundImage: "linear-gradient(to bottom, #34405547, #344055)",
    borderRight: "1px solid #344055",
    boxShadow: "3px 0px 6px #29313E50",
  },
  RequestsList: {
    card: {
      backgroundImage: "linear-gradient(to bottom right, #34405347, #344053)",
      border: "1px solid #344055",
      boxShadow: "0px 2px 3px #161B2428",
      hover: {
        boxShadow: "0 14px 28px #161B2430, 0 10px 10px #161B2422",
        border: "1px solid #ffffff30",
      },
    },
  },
  modal: {
    background: "#14192050",
    backgroundColor: "#344053",
    border: "1px solid #ffffff50",
    boxShadow: "2px 9px 18px #161B2428",
    itemDetailMobileBoxShadow: "0px -10px 14px #1b232f",
  },
  drawer: {
    backgroundColor: "#34405350",
    boxShadow: "0px -10px 14px #1b232f",
  },
  shimmer: {
    background: "#2B3443",
    backgroundImage: `linear-gradient(
    to right,
    #2B3443 0%,
    #2B344365 40%,
    #202731 80%,
    #232A36 100%
  )`,
  },
  bottomNav: {
    backgroundImage: "linear-gradient(to right, #344053, #344053)",
    borderTop: "1px solid #344055",
    boxShadow: "-3px 0px 6px #1d2430",
    active: {
      background: "#FFFFFF",
    },
  },
  addOrders: {
    backgroundImage: "linear-gradient(to bottom right, #34405347, #344053)",
    border: "2px solid #344055",
    boxShadow: "0px 3px 6px #161B2428",
    searchInput: {
      backgroundColor: "#344055",
    },
    itemSelectionMobile: {
      backgroundColor: "#344055",
      boxShadow: "-6px 0px 10px #1d2430",
    },
  },
  emptyState: {
    fill: "#ffffff",
    pageFill: "#ffffff70",
  },
  bar: {
    boxShadow: "3px 2px 6px #4399e187",
  },
  searchInput: {
    backgroundColor: "linear-gradient(to right,#334154,#2d3847)",
    border: "1px solid #1d2736",
    searchIconBackground: "linear-gradient(to right,#212b3b,#1d2736e8)",
    searchIconBoxShadow: "4px 0px 4px #28303b",
    searchIconColor: "#fff5f7de",
  },
  searchDropDown: {
    backgroundColor: "#2d3848",
    boxShadow: "0px 6px 6px #252b33",
  },

  forgotPassword: {
    background: "#2D3748",
  },
};

import styled from "styled-components";
import useMedia from "use-media";
import { useState } from "react";
import Constants from "utils/constants";
import MobileAppIntro from "./MobileAppIntro";
import HeroMessage from "./HeroMessage";
import LandingCarousel from "./LandingCarousel";
import LoginAndSignUp from "./LoginAndSignUp/LoginAndSignUp";
import DevicesSection from "./DevicesSection";
import MobileSupport from "./MobileSupport";
import DarkModeAndLanguage from "./DarkModeAndLanguage";
import GetNotified from "./GetNotified";
import Footer from "./Footer";

export default function Landing({userRole}) {
  const isLargeScreen = useMedia({ minWidth: "1030px" });
  const [getStasrtedClicked, setGetStartedClicked] = useState(false);

  return (
    <Wrapper>
      <div className="top-section-wrapper">
        <div className="content-wrapper">
          {isLargeScreen && (
            <>
              <section className="left-side">
                <HeroMessage />
                {(userRole===Constants.buyer) && (<LandingCarousel />)}
              </section>
              <LoginAndSignUp userRole={userRole}/>
            </>
          )}
          {!isLargeScreen && !getStasrtedClicked && (
            <MobileAppIntro setGetStartedClicked={setGetStartedClicked} />
          )}
          {!isLargeScreen && getStasrtedClicked && <LoginAndSignUp userRole={userRole}/>}
        </div>
      </div>
      {isLargeScreen &&  (userRole===Constants.buyer) && (
        <>
          <DevicesSection />
          <MobileSupport />
          <DarkModeAndLanguage />
          <GetNotified />
          <Footer />
        </>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-image: ${({ theme }) => theme.LANDING.backgroundImage};
  width: 100%;
  height: 100vh;
  margin: 0px;
  overflow-y: auto;
  padding: 81px 0px 0px 0px;
  display: block;
  box-sizing: border-box;
  .top-section-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
  }
  .content-wrapper {
    display: flex;
    width: 100%;
    height: fit-content;
    max-width: 1300px;
  }
  .left-side {
    display: flex;
    flex-direction: column;
    width: 60%;
  }
  @media screen and (max-width: 1025px) {
    padding-top: 30px;
    .content-wrapper {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .left-side {
      width: 100%;
      justify-content: center;
      align-items: center;
    }
  }
  @media screen and (max-width: 625px) {
    background-image: ${({ theme }) => theme.LANDING.mobileBackgroundImage};
  }

  @media screen and (max-width: 460px) {
    padding: 10px 0px 30px 0px;
  }
`;

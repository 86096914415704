import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { toEthiopian } from "utils/dateConvertor";
import dateFormatter from "utils/dateFormatter";

export default function OrderDate({ unformattedDate, customStyles }) {
  const { i18n } = useTranslation();

  return (
    <Wrapper customStyles={customStyles}>
      {i18n.language === "am"
        ? toEthiopian(unformattedDate)
        : dateFormatter.yearMonthDate(unformattedDate)}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  font-size: 20px;
  color: ${({ theme }) => theme.COLORS.primary100};
  width: 200px;
  font-family: Montserrat;
  font-weight: bold;
  ${(p) => p.customStyles};
`;

import Icons from "components/Icons";
import Spinner from "components/Spinner";
import useOnClickOutside from "hooks/useOnClickOutside";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import getRecentlyAddedAndUpdated from "utils/getRecentlyAddedAndUpdated";
import searchOrders from "utils/searchOrders";
import SearchResultList from "../SearchResultList";

export default function SearchFormDesktop({
  setShowConfig,
  searchType,
  allRequestsReq,
}) {
  const { i18n, t } = useTranslation();
  const [searchResult, setSearchResult] = useState([]);
  const [searchQuery, setSeachQuery] = useState(null);
  const [showSearchResult, setShowSearchResult] = useState(false);
  const [recentlyAddedUpdated, setRecentlyAddedUpdated] = useState([]);
  const menuDropdown = useRef(null);
  useOnClickOutside(menuDropdown, () => {
    setShowSearchResult(false);
  });

  const filterSeachResult = (query) => {
    setSearchResult(getRecentlyAddedAndUpdated([
        ...searchOrders[searchType === "Date" ? "byDate" : "byOrderNumber"](
          allRequestsReq.data,
          query,
          i18n.language
        ),
      ])
    );
  };

  useEffect(() => {
    if (allRequestsReq.data) {
      setRecentlyAddedUpdated(getRecentlyAddedAndUpdated(allRequestsReq.data));
    }
  }, [allRequestsReq.data]);

  return (
    <Wrapper
      onSubmit={(e) => {
        e.preventDefault();
        filterSeachResult(searchQuery);
      }}
      ref={menuDropdown}
    >
      {allRequestsReq.isLoading ? (
        <button type="submit" className="search-button">
          <Spinner />
        </button>
      ) : (
        <button type="submit" className="search-button">
          <Icons.Search />
        </button>
      )}
      <input
        type="text"
        placeholder={
          searchType === "Date"
            ? t("Search by Date")
            : t("Search by Order Number")
        }
        onChange={(e) => {
          setSeachQuery(e.target.value);
          filterSeachResult(e.target.value);
        }}
        onFocus={() => {
          setShowSearchResult(true);
        }}
      />
      <button
        type="button"
        className="dropdown"
        onClick={() => {
          setShowConfig((prev) => !prev);
        }}
      >
        <Icons.ChevronDown />
      </button>
      {showSearchResult && (
        <SearchResultList
          searchResult={searchResult}
          setShowSearchResult={setShowSearchResult}
          searchQuery={searchQuery}
          recentlyAddedUpdated={recentlyAddedUpdated}
        />
      )}
    </Wrapper>
  );
}

const Wrapper = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  max-width: 565px;
  width: 100%;
  margin: 0px 40px;
  input {
    max-width: 565px;
    width: 100%;
    height: 40px;
    border-radius: 8px;
    border: 1px solid ${(p) => p.theme.COLORS.primary10050};
    background-image: ${(p) => p.theme.searchInput.backgroundColor};
    color: ${({ theme }) => theme.COLORS.primary};
    padding: 0px 50px 0px 50px;
    outline: none;
    transition: 0.5s all ease;
    ::placeholder {
      color: ${({ theme }) => theme.COLORS.primary10090};
    }
    &:focus {
      outline: none;
      border: 1px solid ${({ theme }) => theme.COLORS.primary100};
      box-shadow: 0px 0px 0px 4px ${({ theme }) => theme.COLORS.primary10050};
    }
  }

  .search-button {
    height: 38px;
    width: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background-image: ${(p) => p.theme.searchInput.searchIconBackground};
    position: absolute;
    left: 1px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    outline: none;
    box-shadow: ${(p) => p.theme.searchInput.searchIconBoxShadow};
    svg {
      height: 25px;
      fill: ${(p) => p.theme.searchInput.searchIconColor};
    }
  }

  .dropdown {
    height: 32px;
    width: 32px;
    border-radius: 9999%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    position: absolute;
    right: 6px;
    background: transparent;
    transition: 0.3s all ease;
    cursor: pointer;
    outline: none;
    svg {
      width: 16px;
      fill: ${({ theme }) => theme.COLORS.primary10090};
    }
    &:hover,
    &:focus {
      background: ${({ theme }) => theme.COLORS.primary10029};
      cursor: pointer;
    }
  }
`;

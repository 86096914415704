import { useEffect } from "react";
import styled from "styled-components";
import FacilityInfo from "./FacilityInfo";
import OrderListByType from "./OrderListByType";
import OrdersCountGraph from "./OrdersCountGraph";
import TopRequestedItems from "./TopRequestedItems";

export default function AllOrders({ setSelectedOrderType }) {
  useEffect(() => {
    setSelectedOrderType(null);
  }, []);

  return (
    <Wrapper>
      <div className="graph-and-recents-wrapper">
        <OrdersCountGraph />
        <FacilityInfo />
      </div>
      <TopRequestedItems />
      <OrderListByType />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .graph-and-recents-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .orders-graph,
  .recents {
    background-image: ${({ theme }) => theme.RequestsList.card.backgroundImage};
    border: ${({ theme }) => theme.RequestsList.card.border};
    box-shadow: ${({ theme }) => theme.RequestsList.card.boxShadow};
    border-radius: 4px;
    color: ${({ theme }) => theme.COLORS.primary};
    padding: 10px 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 20px;
    min-height: 330px;
  }

  .orders-graph {
    width: 60%;
  }

  .recents {
    width: 30%;
  }

  .header-text {
    font-family: "Fredoka One", cursive;
    letter-spacing: 1px;
    margin: 0px;
    border-bottom: 1px dashed ${({ theme }) => theme.COLORS.primary10029};
    width: fit-content;
    padding: 5px 0px;
  }

  .value-text {
    margin-left: 10px;
    text-align: left;
    min-width: 30px;
  }

  @media screen and (max-width: 1180px) {
    .graph-and-recents-wrapper {
      flex-direction: column;
      .orders-graph,
      .recents {
        padding-bottom: 30px;
        padding-top: 20px;
        width: 90%;
        height: auto;
      }
    }
  }

  @media screen and (max-width: 600px) {
    .graph-and-recents-wrapper {
      flex-direction: column;
      margin-top: 10px;
      .orders-graph,
      .recents {
        width: 96%;
        margin: 10px 0px;
      }
    }
  }
`;

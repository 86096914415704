/* eslint-disable jsx-a11y/control-has-associated-label */
import { motion } from "framer-motion";
import styled from "styled-components";
import {
  fetchOrderDetails,
  fetchRequestDetails,
  fetchStrockedOutRequests,
} from "utils/requests";
import { useQuery } from "react-query";
import EmptyState from "components/EmptyState";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { appActions } from "app.state";
import Icons from "components/Icons";
import SelectedOrderStatus from "../OrdersList/SelectedOrderStatus";
import OrderStatusLogs from "../OrdersList/OrderStatusLogs";
import ItemHeader from "./ItemHeader";
import EditOrDelete from "./EditOrDelete";
import ItemsLoading from "./ItemsLoading";
import ItemsList from "./ItemsList";

export default function OrderDetails({
  selectedRequest,
  selectedRequestData,
  isLargeScreen,
}) {
  const { id } = useParams();
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const itemDetailReq = useQuery(
    ["Item Detail", selectedRequest],
    selectedRequestData.requestStatus === "PickListed"
      ? fetchOrderDetails
      : fetchRequestDetails
  );

  const stockedOutReq = useQuery(
    ["Stocked Out Requests", selectedRequest],
    fetchStrockedOutRequests
  );

  const modalAnimationVariants = isLargeScreen
    ? {
        hidden: { opacity: 0, y: -50 },
        visible: { opacity: 1, y: 0 },
        exit: { opacity: 0, y: -50 },
      }
    : {
        hidden: { opacity: 0, y: 200 },
        visible: { opacity: 1, y: 0 },
        exit: { opacity: 0 },
      };

  return (
    <Wrapper>
      <div
        className="modal-background"
        role="button"
        tabIndex={0}
        onClick={() => {
          dispatch(appActions.hideRequestDetailModal());
        }}
      />
      <motion.div
        className="pop-up"
        initial="hidden"
        animate="visible"
        exit="exit"
        variants={modalAnimationVariants}
        transition={{ duration: 0.5 }}
      >
        <Icons.Close
          className="close-icon"
          onClick={() => {
            dispatch(appActions.hideRequestDetailModal());
            if (id) {
              history.push("/");
            }
          }}
        />
        <SelectedOrderStatus
          requestStatus={selectedRequestData.requestStatus}
        />
        <OrderStatusLogs id={selectedRequest} />
        {(selectedRequestData.requestStatus === "Draft" || selectedRequestData.requestStatus === "Rejected") && (
          <EditOrDelete selectedRequestData={selectedRequestData} />
        )}
        <ItemHeader
          order={selectedRequestData}
          items={itemDetailReq.data}
          stockedOutItems={stockedOutReq.data}
        />
        <div className="body-wrapper">
          <div className="date items-requested-header">
            {t("Items Requested")}
          </div>
          {itemDetailReq.isLoading && <ItemsLoading />}
          {itemDetailReq?.data?.length > 0 &&  (
            <ItemsList
              items={itemDetailReq.data}
              status={selectedRequestData.requestStatus}
              selectedRequest={selectedRequest}
            />
          )}
          {stockedOutReq?.data?.length > 0 && selectedRequestData.requestStatus !== "PickListed" && (
            <>
              <div className="date items-requested-header">
                {t("Stockedout Items")}
              </div>
              <ItemsList
                items={stockedOutReq.data}
                status={selectedRequestData.requestStatus}
              />
            </>
          )}
          {itemDetailReq?.data?.length === 0 && (
            <EmptyState message="No items Selected" />
          )}
        </div>
      </motion.div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: fixed;
  background: transparent;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 900;
  top: 0;
  left: 0;
  color: ${({ theme }) => theme.COLORS.primary};

  .modal-background {
    position: fixed;
    background: ${({ theme }) => theme.modal.background};
    backdrop-filter: blur(4px);
    width: 100%;
    height: 100%;
    display: flex;
    outline: none;
  }

  .pop-up {
    width: 50%;
    height: 80vh !important;
    overflow-y: auto;
    z-index: 9999;
    margin-top: 10vh !important;
    background-color: ${({ theme }) => theme.modal.backgroundColor} !important;
    border: ${({ theme }) => theme.modal.border};
    box-shadow: ${({ theme }) => theme.modal.boxShadow};
    border-radius: 4px;
    box-sizing: border-box;
    padding: 20px 0px;
    position: relative;
  }

  .body-wrapper {
    padding: 0px 20px;
  }

  section {
    margin: 7px 0px;
    width: 150px;
  }

  .date {
    font-family: "Fredoka One", cursive;
    font-size: 20px;
    letter-spacing: 1px;
    color: ${({ theme }) => theme.COLORS.primary200};
    width: 100%;
    display: flex;
    align-items: center;
  }

  p {
    margin: 0;
  }

  .list-label {
    font-size: 14px;
  }

  .list-content {
    font-family: "Fredoka One", cursive;
    letter-spacing: 1px;
    font-size: 16px;
  }

  .close-icon {
    height: 28px;
    position: absolute;
    right: 10px;
    top: 10px;
    fill: ${({ theme }) => theme.COLORS.primary200};
    stroke: ${({ theme }) => theme.COLORS.primary200};
    cursor: pointer;
    transition: 0.5s all ease;
    &:hover {
      fill: ${({ theme }) => theme.COLORS.primary100};
    }
  }

  .items-requested-header {
    margin-top: 30px;
  }

  .items-list {
    padding: 0;
    margin: 0;
    list-style-type: none;
    font-size: 14px;
    width: fit-content;
    li {
      padding: 12px 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px dashed #70245930;
      position: relative;
      h3 {
        margin: 0;
      }
      .item-name,
      .shimmer-element {
        width: 350px;
        margin-right: 40px;
      }
      .quantity {
        font-family: "Fredoka One", cursive;
        font-size: 20px;
        letter-spacing: 1px;
        color: ${({ theme }) => theme.COLORS.primary200};
      }
    }
    .title-list-item {
    }
  }

  .print-button {
    font-weight: 600;
    background: ${({ theme }) => theme.COLORS.blue};
    color: #ffffff;
    border: none;
    box-sizing: border-box;
    padding: 10px 30px;
    border-radius: 5px;
    box-shadow: ${({ theme }) => theme.BUTTON.boxShadow};
    cursor: pointer;
    transition: 0.3s all ease;
    outline: none;
    letter-spacing: 1px;
    margin-right: 20px;
    font-size: 18px;
    &:hover {
      background: #2d71c1;
    }
  }

  .print-stockout {
    color: ${({ theme }) => theme.COLORS.primary100};
    border: 2px solid ${({ theme }) => theme.COLORS.primary100};
    box-shadow: 0px 0px 0px 3px ${({ theme }) => theme.COLORS.primary10033};
    background-color: transparent;
    &:hover {
      background: transparent;
    }
  }

  .price-section {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .warning-message {
    margin: 10px 10px -10px 10px;
  }

  .red-warning {
    background: ${({ theme }) => theme.COLORS.red10};
    border-radius: 5px;
  }

  .edit-and-delete {
    display: flex;
  }

  .edit-button {
    margin: 10px 0px 0px 0px;
    cursor: pointer;
    transition: 0.5s all ease;
    outline: none;
    border: none;
    padding: 12px 15px;
    font-weight: 600;
    border-radius: 5px;
    font-size: 16px;
    min-width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    svg {
      height: 20px;
      margin-right: 5px;
    }
    &:hover {
      box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.04);
    }
    background-color: ${({ theme }) => theme.COLORS.primary10020};
    color: ${({ theme }) => theme.COLORS.primary100};
    svg {
      fill: ${({ theme }) => theme.COLORS.primary100};
    }
  }

  input {
    border: 1px solid ${({ theme }) => theme.COLORS.primary10029};
    outline: none;
    transition: 0.5s all ease;
    padding: 12px;
    border-radius: 5px;
    box-sizing: border-box;
    margin-right: 1px;
    width: 100px;
    background-color: ${({ theme }) =>
      theme.addOrders.searchInput.backgroundColor} !important;
    color: ${({ theme }) => theme.COLORS.primary} !important;
    &:focus {
      border: 1px solid #d53f8c;
    }
    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 30px #f9f5f7 inset !important;
    }
    ::placeholder {
      color: ${({ theme }) => theme.COLORS.primary10060};
    }
  }

  .error {
    margin-top: 7px;
    font-size: 14px;
    color: #d53f8c;
    bottom: 3px;
    right: 60px;
  }

  @media screen and (max-width: 950px) {
    .pop-up {
      width: 100% !important;
      bottom: 0;
      box-shadow: ${({ theme }) => theme.modal.itemDetailMobileBoxShadow};
      border-radius: 0px;
      height: 80vh !important;
      background-color: ${({ theme }) =>
        theme.drawer.backgroundColor} !important;
      backdrop-filter: blur(15px);
      border: 1px solid #ffffff00;
      position: fixed;
    }
  }

  @media screen and (max-width: 550px) {
    .items-list {
      width: 100%;
      li {
        padding: 12px 0px;
        flex-wrap: nowrap;
        .item-name {
          width: auto;
        }
        .shimmer-element {
          width: 200px;
        }
      }
    }
    .date {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .print-button {
      margin: 5px 0px 15px 0px;
      min-width: 200px;
      padding: 12px 30px;
    }
  }
`;

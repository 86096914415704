/* eslint-disable react/jsx-props-no-spreading */
import { motion } from "framer-motion";
import styled from "styled-components";
import { ReactComponent as FirstImage } from "resources/landingPage/01.svg";
import { ReactComponent as SecondImage } from "resources/landingPage/02.svg";
import { ReactComponent as ThirdImage } from "resources/landingPage/03.svg";
import { ReactComponent as FourthImage } from "resources/landingPage/04.svg";
import { ReactComponent as FifthImage } from "resources/landingPage/05.svg";
import { ReactComponent as SixthImage } from "resources/landingPage/06.svg";
import { useState } from "react";
import { useSwipeable } from "react-swipeable";
import { useTranslation } from "react-i18next";
import HeroMessage from "./HeroMessage";

const LevelOne = ({ message, position }) => (
  <motion.div
    className="container"
    initial={{ scale: 0, rotate: -60 }}
    animate={{
      rotate: 0,
      left: `${(0 - position) * 80 - 40}vw`,
      scale: position === 0 ? 1 : 0.5,
    }}
    transition={{
      type: "spring",
      stiffness: 260,
      damping: 20,
    }}
  >
    <FirstImage />
    <p>{message}</p>
  </motion.div>
);

const LevelTwo = ({ message, position }) => (
  <motion.div
    className="container"
    initial={{ scale: 0, rotate: -60 }}
    animate={{
      rotate: 0,
      left: `${(1 - position) * 80 - 40}vw`,
      scale: position === 1 ? 1 : 0.5,
    }}
    transition={{
      type: "spring",
      stiffness: 260,
      damping: 20,
    }}
  >
    <SecondImage />
    <p>{message}</p>
  </motion.div>
);

const LevelThree = ({ message, position }) => (
  <motion.div
    className="container"
    initial={{ scale: 0, rotate: -60 }}
    animate={{
      rotate: 0,
      left: `${(2 - position) * 80 - 40}vw`,
      scale: position === 2 ? 1 : 0.5,
    }}
    transition={{
      type: "spring",
      stiffness: 260,
      damping: 20,
    }}
  >
    <ThirdImage />
    <p>{message}</p>
  </motion.div>
);

const LevelFour = ({ message, position }) => (
  <motion.div
    className="container"
    initial={{ scale: 0, rotate: -60 }}
    animate={{
      rotate: 0,
      left: `${(3 - position) * 80 - 40}vw`,
      scale: position === 3 ? 1 : 0.5,
    }}
    transition={{
      type: "spring",
      stiffness: 260,
      damping: 20,
    }}
  >
    <FourthImage />
    <p>{message}</p>
  </motion.div>
);

const LevelFive = ({ message, label, position }) => (
  <motion.div
    className="container"
    initial={{ scale: 0, rotate: -60 }}
    animate={{
      rotate: 0,
      left: `${(4 - position) * 80 - 40}vw`,
      scale: position === 4 ? 1 : 0.5,
    }}
    transition={{
      type: "spring",
      stiffness: 260,
      damping: 20,
    }}
  >
    <FifthImage />
    <p>{message}</p>
    <button type="submit" className="blue-button button-wrapper"onClick={() => {window.open(`https://csm.fanosonline.org/product-availability`, "_blank")}}>{label}</button>
  </motion.div>
);

const LevelSix = ({ message, label, position }) => (
  <motion.div
    className="container"
    initial={{ scale: 0, rotate: -60 }}
    animate={{
      rotate: 0,
      left: `${(5 - position) * 80 - 40}vw`,
      scale: position === 5 ? 1 : 0.5,
    }}
    transition={{
      type: "spring",
      stiffness: 260,
      damping: 20,
    }}
  >
    <SixthImage />
    <p>{message}</p>
    <button type="submit" className="blue-button button-wrapper"onClick={() => {window.open(`http://arrivals.fanosonline.org/new-arrivals`, "_blank")}}>{label}</button>
  </motion.div>
);

export default function MobileAppIntro({ setGetStartedClicked }) {
  const [position, setPosition] = useState(0);
  const { t } = useTranslation();
  const handlers = useSwipeable({
    onSwiped: (eventData) => {
      if (eventData.dir === "Left" && position < 5) {
        setPosition(position + 1);
      } else if (eventData.dir === "Right" && position > 0) {
        setPosition(position - 1);
      }
    },
  });
  return (
    <Wrapper {...handlers}>
      <HeroMessage />
      <div className="slider">
        <div className="row">
          <LevelOne
            position={position}
            message={t("Its Easy, Simple and Time saving")}
          />
          <LevelTwo
            position={position}
            message={t("Process your product request forms online")}
          />
          <LevelThree
            position={position}
            message={t(
              "Submit your requests to EPSS and follow up on the status"
            )}
          />
          <LevelFour
            position={position}
            message={t("Preview previous requests")}
          />
          <LevelFive
            position={position}
            message={t("View available products")} 
            label={t("Show")}
          />
          <LevelSix
            position={position}
            message={t("View new arrived items")} 
            label={t("Show")}
          />
        </div>
      </div>
      <section className="dotes-wrapper">
        <CircleIndicator isActive={position === 0} />
        <CircleIndicator isActive={position === 1} />
        <CircleIndicator isActive={position === 2} />
        <CircleIndicator isActive={position === 3} />
        <CircleIndicator isActive={position === 4} />
        <CircleIndicator isActive={position === 5} />
      </section>
      <motion.button
        whileHover={{ scale: 1.01 }}
        whileTap={{ scale: 0.97 }}
        type="button"
        onClick={() => {
          setGetStartedClicked(true);
        }}
        className="blue-button button-spacing"
      >
        {t("Get Started")}
      </motion.button>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .slider {
    width: 100vw;
    height: 60vh;
    overflow: hidden;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .row {
    position: relative;
  }

  .container {
    width: 80vw;
    height: 50vh;
    overflow: hidden;
    position: absolute;
    top: -25vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    svg {
      width: 100%;
    }
  }

  p {
    font-size: 24px;
    color: ${({ theme }) => theme.COLORS.primary100};
    text-align: center;
    height: 50px;
  }

  .dotes-wrapper {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 14px;
    margin-top: 0px;
  }

  .illustration-background {
    fill: ${({ theme }) => theme.LANDING.illustration.background};
  }

  .button-spacing {
    margin-top: 20px;
  }

  @media screen and (max-width: 625px) {
    p {
      font-size: 18px;
    }
  }
`;

const CircleIndicator = styled.div`
  width: 14px !important;
  height: 14px;
  border-radius: 9999%;
  background-color: ${({ isActive, theme }) =>
    isActive ? theme.COLORS.primary100 : theme.LANDING.crouselDotes.background};
  margin: 0px 10px;

  @media screen and (max-width: 625px) {
    background-color: ${({ isActive, theme }) =>
      isActive ? theme.COLORS.primary100 : theme.COLORS.primary10029};
  }
`;

import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { ReactComponent as NotificationBell } from "resources/landingPage/NotificationBell.svg";
import styled from "styled-components";

export default function GetNotified() {
  const elementRef = useRef(null);
  const [showElement, setShowElement] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setShowElement(true);
        }
      },
      {
        root: null,
        rootMargin: "20%",
        threshold: 0.4,
      }
    );
    if (elementRef.current) {
      observer.observe(elementRef.current);
    }
    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <Wrapper ref={elementRef}>
      {showElement && (
        <>
          <motion.div
            initial={{ opacity: 0, scale: 0.7 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.7 }}
          >
            <NotificationBell />
          </motion.div>
          <motion.h1
            initial={{ opacity: 0, scale: 0.7, y: -70 }}
            animate={{ opacity: 1, scale: 1, y: 0 }}
            transition={{ duration: 0.7 }}
          >
            Get Notified When Your Order Status Changes
          </motion.h1>
        </>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #702459;
  width: 100%;
  color: #ffffff;
  padding: 50px 30px;
  box-sizing: border-box;
  min-height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow-y: hidden;
  svg {
    height: 150px;
    fill: #ffffff;
    margin-bottom: 30px;
  }
  h1 {
    font-size: 60px;
    font-family: "Fredoka One", cursive;
    max-width: 900px;
    letter-spacing: 1px;
    text-align: center;

    -webkit-text-fill-color: transparent;
    -webkit-box-decoration-break: clone;
    background: linear-gradient(-70deg, #d096be, #fdfdff);
    -webkit-background-clip: text;
  }
`;

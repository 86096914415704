import { css } from "styled-components";

const Button = css`
  font-weight: 600;
  background: ${({theme}) => theme.COLORS.blue};
  color: #ffffff;
  border: none;
  box-sizing: border-box;
  padding: 15px 20px;
  width: auto;
  min-width: 280px;
  border-radius: 5px;
  box-shadow: ${({theme}) => theme.BUTTON.boxShadow};
  cursor: pointer;
  transition: 0.3s all ease;
  outline: none;
  &:hover {
    background: #2d71c1;
  }
  &:focus {
    box-shadow: none;
  }
`;

export default Button;

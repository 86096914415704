export default function searchItems(itemsList, searchText) {
  if (!itemsList || itemsList?.length === 0) return [];
  const filteredSuggestions = itemsList.filter((suggestion) => {
    return (
      suggestion.fullItemName.toLowerCase().indexOf(searchText.toLowerCase()) >
      -1
    );
  });

  const sortedFilteredSuggestions = filteredSuggestions.sort(function(a, b){return a.fullItemName.toLowerCase().indexOf(searchText.toLowerCase()) - b.fullItemName.toLowerCase().indexOf(searchText.toLowerCase())});
  return sortedFilteredSuggestions;
}

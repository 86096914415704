import { useEffect, useState } from "react";
import styled from "styled-components";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { useOnInstallPwa } from "hooks/useInstallPWAprompt";
import { selectors } from "configureStore";
import { useDispatch, useSelector } from "react-redux";
import { appActions } from "app.state";
import Constants from "utils/constants";
import OrdersList from "./OrdersList/OrdersList";
import TopNav from "./TopNav";
import SideNav from "./SideNav";
import AdminSideNav from "./AdminHome/AdminSideNav";
import AdminTopNav from "./AdminHome/AdminTopNav";
import AdminTopNavMobile from "./AdminHome/AdminTopNavMobile";
import BottomNav from "./BottomNav";
import AddOrders from "./AddOrders/AddOrders";
import TopNavMobile from "./TopNavMobile";
import InstallPwaModal from "./InstallPwaModal";
import AllOrders from "./AllOrders/AllOrders";
import Stockouts from "./Stockouts/Stockouts";
import StatusReport from "./Reports/StatusReport";
import FacilityReport from "./Reports/FacilityReport";

export default function Home() {
  const installPWA = useOnInstallPwa();
  const dispatch = useDispatch();
  const [selectedOrderType, setSelectedOrderType] = useState(null);
  const [selectedNavType, setSelectedNavType] = useState("status-report");
  const app = useSelector(selectors.app);
  const [largeScreen, setLargeScreen] = useState(
    !(document.documentElement.clientWidth < 950)
  );

  const resizeHandler = () => {
    const width = document.documentElement.clientWidth;
    if (width < 950) {
      setLargeScreen(false);
    } else {
      setLargeScreen(true);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", resizeHandler, true);
    return () => {
      window.removeEventListener("resize", resizeHandler, true);
    };
  }, []);

  const skipPwaInstallation = () => {
    localStorage.setItem("PWA-install-prompt", true);
    dispatch(appActions.hidePWAInstallPrompt());
  };

  return localStorage.getItem("user_role")===Constants.admin?
    (<Wrapper>
        {largeScreen ? <AdminTopNav /> : <AdminTopNavMobile />}
        {app.showInstallPWAPropmpt && (
          <InstallPwaModal
            skipPwaInstallation={skipPwaInstallation}
            installPWA={installPWA}
          />
        )}
        <section className="side-nav-and-main-content">
          {largeScreen &&
            <AdminSideNav selectedNavType={selectedNavType} />
          }
          <AdminMainContent setSelectedNavType={setSelectedNavType} />
        </section>
      </Wrapper>):
      (<Wrapper>
        {largeScreen ? <TopNav /> : <TopNavMobile />}
        {app.showInstallPWAPropmpt && (
          <InstallPwaModal
            skipPwaInstallation={skipPwaInstallation}
            installPWA={installPWA}
          />
        )}
        <section className="side-nav-and-main-content">
          {largeScreen ?
            <SideNav selectedOrderType={selectedOrderType} />
            : (
            <BottomNav selectedOrderType={selectedOrderType} />
          )}
          <MainContent setSelectedOrderType={setSelectedOrderType} />
        </section>
      </Wrapper>);
}

const MainContent = ({ setSelectedOrderType }) => {
  const { path } = useRouteMatch();

  return (
    <div className="main-content">
      <Switch>
        <Route path={`${path}add-order/:id?`}>
          <AddOrders setSelectedOrderType={setSelectedOrderType} />
        </Route>
        <Route path={`${path}orders/:type?`}>
          <OrdersList setSelectedOrderType={setSelectedOrderType} />
        </Route>
        <Route path={`${path}stockedouts`}>
          <Stockouts setSelectedOrderType={setSelectedOrderType} />
        </Route>
        <Route path={`${path}:id?`}>
          <AllOrders setSelectedOrderType={setSelectedOrderType} />
        </Route>
      </Switch>
    </div>
  );
};

const AdminMainContent = ({setSelectedNavType}) => {
  const { path } = useRouteMatch();

  return (
    <div className="admin-main-content">
      <Switch>
        <Route path={`${path}status-report`}>
          <StatusReport setSelectedNavType={setSelectedNavType} />
        </Route>
        <Route path={`${path}facility-report`}>
          <FacilityReport setSelectedNavType={setSelectedNavType} />
        </Route>
        <Route path={`${path}`}>
          <StatusReport setSelectedNavType={setSelectedNavType} />
        </Route>
      </Switch>
    </div>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-image: ${({ theme }) => theme.HOME.backgroundImage};

  .side-nav-and-main-content {
    display: flex;
  }

  .main-content {
    width: 100%;
    height: calc(100vh - 62px);
    overflow-y: auto;
  }
  .admin-main-content {
    width: 100%;
    height: calc(100% - 62px);
    overflow-y: auto;
  }
`;

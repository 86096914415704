/* eslint-disable import/prefer-default-export */
import styled from "styled-components";

const Shimmer = styled.div`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  margin-top: ${({ marginTop }) => marginTop};
  border-radius: ${({ borderRadius }) => borderRadius};
  background: ${({ theme }) => theme.shimmer.background};
  background-image: ${({ theme }) => theme.shimmer.backgroundImage};
  background-repeat: no-repeat;
  background-size: 800px 104px;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholderShimmer;
  animation-timing-function: linear;
  @keyframes placeholderShimmer {
    0% {
      background-position: -468px 0;
    }

    100% {
      background-position: 468px 0;
    }
  }
`;

export function ShimmerEffect({ width, height, borderRadius, marginTop }) {
  return (
    <>
      <Shimmer
        width={width}
        height={height}
        borderRadius={borderRadius}
        marginTop={marginTop}
      />
    </>
  );
}

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import getDecodedToken from "utils/getDecodedToken";

export default function FacilityInfo() {
  const { t } = useTranslation();
  const [facility, setFacility] = useState();

  useEffect(() => {
    setFacility(getDecodedToken());
  }, []);

  return (
    <Wrapper className="recents">
      <h3 className="header-text">{t("Facility Info")}</h3>
      {facility && (
        <>
          <section className="title-and-value-wrapper">
            <h4>{t("Facility Name")}</h4>
            <p>{facility.FacilityName}</p>
          </section>
          <section className="title-and-value-wrapper">
            <h4>{t("Id")}</h4>
            <p>{facility.FacilityId}</p>
          </section>
          <section className="title-and-value-wrapper">
            <h4>{t("Hub")}</h4>
            <p>{facility.SupplierHubName}</p>
          </section>
          <section className="title-and-value-wrapper">
            <h4>{t("TIN Number")}</h4>
            <p>{facility.TinNumber}</p>
          </section>
          <section className="title-and-value-wrapper no-borders">
            <h4>{t("Region, Zone, Wereda")}</h4>
            <p>{`${facility.FacilityRegion || "N/A"}, ${
              facility.FacilityZone || "N/A"
            }, ${facility.FacilityWoreda || "N/A"}`}</p>
          </section>
        </>
      )}
    </Wrapper>
  );
}
const Wrapper = styled.div`
  .header-text {
    margin-bottom: 10px;
  }
  .title-and-value-wrapper {
    width: 100%;
    border-bottom: 1px dashed ${({ theme }) => theme.COLORS.primary10020};
    padding: 7px 0px;

    h4,
    p {
      margin: 0px;
    }

    h4 {
      color: ${({ theme }) => theme.COLORS.primary10096};
      margin-bottom: 3px;
      font-weight: 400 !important;
      font-size: 14px;
    }

    p {
      font-size: 16px;
    }
  }

  .no-borders {
    border: none;
  }
`;

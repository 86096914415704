export default {
  COLORS: {
    primary: "#5A1C47",
    primary100: "#702459",
    primary10010: "#70245910",
    primary10020: "#70245920",
    primary10029: "#70245929",
    primary10033: "#70245933",
    primary10040: "#70245940",
    primary10050: "#70245950",
    primary10060: "#70245960",
    primary10080: "#70245980",
    primary10090: "#70245990",
    primary10096: "#70245996",
    primary200: "#5a1d4e8f",
    primary900: "#f8f2f6",
    black: "#000",
    white: "#ffffff",
    blue: "#2e6eb9",
    blue20: "#2e6eb920",
    grey100: "#363f4f",
    grey10060: "#363f4f60",
    grey300: "#4a5568",
    red: "#EF4444",
    red40: "#EF444440",
    red10: "#FEF2F2",
  },
  LANDING: {
    backgroundImage: "linear-gradient(to bottom, #a8318420, #ffffff)",
    mobileBackgroundImage: "linear-gradient(to bottom, #a8318420, #a8318420)",
    logoBackgroundImage: "linear-gradient(to bottom, #ffffff15, #ffffff)",
    boxShadow: "2px 9px 18px #7a276111",
    crouselDotes: {
      background: "#EDE3EA",
    },
    illustration: {
      background: "#702459",
      fill: "#702459",
      darkFill: "#5A1C47",
    },
    card: {
      backgroundImage: "linear-gradient(to bottom right, #ffffff20, #ffffff)",
      border: "#ffffff9c",
    },
    input: {
      webkitBoxShadow: "0 0 0 30px #f9f5f7 inset !important",
    },
  },
  BUTTON: {
    boxShadow: "0 5px 8px rgba(67, 153, 225, 0.4)",
  },
  HOME: {
    backgroundImage: "linear-gradient(to bottom, #f8f2f6, #f8f2f6);",
  },
  TopNav: {
    backgroundImage: "linear-gradient(to right, #ffffff00, #ffffff)",
    border: "1px solid #ffffff",
    boxShadow: "0px 3px 6px #70245916",
    dropdown: {
      background: "#ffffff",
      boxShadow: `0px 2px 4px rgb(42 67 108 / 7%),
      0 2px 4px rgb(31 54 94 / 5%)`,
      boxShadowMobile: `0px 15px 11px rgb(42 67 108 / 17%), 0px 12px 9px rgb(31 54 94 / 21%)`,
      notificationBoxShadow: `0px 13px 9px rgb(42 67 108 / 7%), 0px 8px 8px rgb(31 54 94 / 5%)`,
    },
  },
  SideNav: {
    backgroundImage: "linear-gradient(to bottom, #ffffff00, #ffffff)",
    borderRight: "1px solid #ffffff",
    boxShadow: "4px 4px 6px #7a276110",
  },
  RequestsList: {
    card: {
      backgroundImage: "linear-gradient(to bottom, #ffffff50, #ffffff)",
      border: "1px solid #ffffff",
      boxShadow: "0px 2px 3px #70245930",
      hover: {
        boxShadow: "0 14px 28px #70245925, 0 10px 10px #70245922",
        border: "1px solid #b8318050",
      },
    },
    popup: {
      backgroundImage: "linear-gradient(to bottom, #ffffff50, #ffffff)",
      border: "1px solid #ffffff",
    },
  },
  modal: {
    background: "#5a1c4730",
    backgroundImage: "linear-gradient(to bottom, #ffffff50, #ffffff)",
    backgroundColor: "#ffffff",
    border: "1px solid #ffffff",
    boxShadow: "0px 4px 8px #70245930",
    itemDetailMobileBoxShadow: "0px -10px 14px #7024594a",
  },
  drawer: {
    backgroundColor: "#FFFFFF80",
    boxShadow: "0px -10px 14px #70245996",
  },
  shimmer: {
    background: "#eadee7",
    backgroundImage: `linear-gradient(
      to right,
      #eadee7 0%,
      #eadee765 40%,
      #f7f3f6 80%,
      #f8f4f7 100%
    )`,
  },
  bottomNav: {
    backgroundImage: "linear-gradient(to right, #ffffff, #ffffff)",
    borderTop: "1px solid #ffffff",
    boxShadow: "-3px 0px 6px #70245916",
    active: {
      background: "#702459",
    },
  },
  addOrders: {
    backgroundImage: "linear-gradient(to bottom right, #FFFFFF50, #ffffff)",
    border: "2px solid #ffffff9c",
    boxShadow: "0px 3px 6px #7a276111",
    searchInput: {
      backgroundColor: "#fbf6f9",
    },
    itemSelectionMobile: {
      backgroundColor: "#ffffff",
      boxShadow: "0px -10px 14px #7024594a",
    },
  },
  emptyState: {
    fill: "#702459",
    pageFill: "#d9c5d3",
  },
  bar: {
    boxShadow: "6px 2px 6px #70245940",
  },
  searchInput: {
    backgroundColor: "linear-gradient(to right, #f7f3f6, #e9dfe6)",
    border: "1px solid #70245990",
    searchIconBackground: "linear-gradient(to right, #ffffff10, #ffffff)",
    searchIconBoxShadow: "4px 0px 4px #70245929",
    searchIconColor: "#70245990",
  },
  searchDropDown: {
    backgroundColor: "#ffffff",
    boxShadow: "0px 2px 3px #70245930",
  },
  forgotPassword:{
    background: "#FFFFFF"
  }
};
